import React from 'react';
import BaseModal from '../../base_modal';
import { UserAgreement } from './userAgreement';

export const UserAgreementModal = ({ toggleModalVisibility, isVisible }) => {
  return (
    <BaseModal
      isVisible={isVisible}
      toggleVisibility={toggleModalVisibility}
      modalContent={<UserAgreement toggleModalVisibility={toggleModalVisibility} />}
      headerCopy="User Agreement"
      wide={true}
      max={true}
    />
  );
};
