export const RightArrow = ({ color = '#003732', height = '16', width = '16', className = '', ...props }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={color}
    className={`bi bi-arrow-right-circle-fill ${className}`}
    viewBox="0 0 16 16"
  >
    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
  </svg>
);
