import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { fireSuccessToast, fireErrorToast } from '../../../../utils/alert';
import { Button } from '../../../button';
import { submitRemovePartyRep } from '../../../../utils/api';

const RemovePartyRep = ({ caseDetails, toggleModalVisibility, userProfile, getCaseDetails }) => {
  const [partyReps, setPartyReps] = useState([]);
  const [selectedPartyRep, setSelectedPartyRep] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const partyRepList = caseDetails.caseParties
      .filter((party) => party.id === caseDetails.myParty.partyId)
      .map((party) => party.partyReps)[0]
      .map((party) => ({
        name: `${party.userLogin.userProfile.firstName} ${party.userLogin.userProfile.lastName}`,
        id: party.id,
        userId: party.userLoginId,
      }));

    const filteredPartyRepList = partyRepList.filter((rep) => rep.userId !== userProfile.id);
    setPartyReps(filteredPartyRepList);
  }, [caseDetails]);

  const removeRep = async () => {
    setLoading(true);
    try {
      await submitRemovePartyRep({ partyRepId: selectedPartyRep });
      await getCaseDetails();
      fireSuccessToast('Party rep removed');
      toggleModalVisibility('');
    } catch (error) {
      fireErrorToast('Could not remove party rep');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <div className="invite-to-case-form standard-modal">
          <Form>
            <Row className="mb-3">
              <Form.Group as={Col} xs={12} md={6} className="mb-3" controlId="partySelect">
                <Form.Label className="required-input">{caseDetails.caseName}</Form.Label>
                <Form.Select
                  className="mb-5"
                  name="party"
                  required
                  onChange={(event) => setSelectedPartyRep(event.target.value)}
                >
                  <option>Please Select</option>
                  {partyReps.map((party) => (
                    <option value={party.id}> {party.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>

            <Row className="modal-buttons__container">
              <Button color="light" label="Cancel" />
              <Button color="primary" onClick={() => removeRep()} label="Remove Party Rep" loading={loading} />
            </Row>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default RemovePartyRep;
