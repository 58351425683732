import SelectSearch from 'react-select-search';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

export const CustomSelect = ({ label, options, onChange, placeholder, value, controlId, hyperLinks }) => (
  <Form.Group as={Col} xs={12} md={6} className="" controlId={controlId}>
    <Form.Label className="required-input">{label}</Form.Label>
    <SelectSearch options={options} value={value} required placeholder={placeholder} onChange={onChange} />
    {hyperLinks}
  </Form.Group>
);

export const Select = ({
  id,
  className,
  label = '',
  options = [],
  value = undefined,
  placeholder = '',
  onChange,
  restOfProps,
}) => {
  const renderPlaceholder = () => {
    if (!placeholder) return;

    return (
      <option value={placeholder} disabled>
        {placeholder}
      </option>
    );
  };

  const renderSelectableOptions = () => {
    return options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.name}
      </option>
    ));
  };

  return (
    <Form.Group as={Col} className={className} {...restOfProps}>
      <Form.Label htmlFor={id}>{label}</Form.Label>
      <Form.Select
        id={id}
        value={value || placeholder}
        onChange={(event) => onChange(event.target.value)}
        style={{ color: (value || undefined) === undefined && 'gray' }}
      >
        {renderPlaceholder()}
        {renderSelectableOptions()}
      </Form.Select>
    </Form.Group>
  );
};
