import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContextConsumer } from '../../contexts/authContext';

export const PaywallCheck = ({ children }) => {
  const navigate = useNavigate();
  const currentRoute = window.location.pathname;
  const { userProfile, fetchedUserInfo } = AuthContextConsumer();

  useEffect(() => {
    if (!fetchedUserInfo || userProfile.isSubscribed) return;

    if (userProfile.freeDaysLeft < 0) navigate('/subscribe');
  }, [currentRoute, userProfile]);

  return <> {children} </>;
};
