import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { AuthContextConsumer } from '../../../../contexts/authContext';
import { updateProfile } from '../../../../utils/api';
import { TextField } from '../../../textField';
import { CancelOrAcceptCluster } from '../../../cancelOrAcceptCluster';
import { fireErrorToast, fireSuccessToast } from '../../../../utils/alert';

export const ProfileAddName = ({ toggleModalVisibility }) => {
  const { userProfile, getUserProfile } = AuthContextConsumer();

  const [firstName, setFirstName] = useState(userProfile.firstName);
  const [lastName, setLastName] = useState(userProfile.lastName);
  const [isLoading, setIsLoading] = useState(false);

  const udpateName = async () => {
    setIsLoading(true);
    try {
      await updateProfile({ firstName, lastName });
      await getUserProfile();
      fireSuccessToast(`Name updated successfully`);
      toggleModalVisibility();
    } catch (err) {
      fireErrorToast('Something went wrong. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Row>
      <div className="invite-to-case-form standard-modal">
        <Form>
          <TextField
            classnames={{ input: 'mb-4', label: 'margin-top' }}
            id="firstName"
            value={firstName}
            onChange={setFirstName}
            label={'First Name'}
          />
          <TextField id="lastName" value={lastName} onChange={setLastName} label={'Last Name'} />
          <CancelOrAcceptCluster
            isLoading={isLoading}
            onCancelClick={toggleModalVisibility}
            onAcceptClick={udpateName}
            acceptLabel="Save"
          />
        </Form>
      </div>
    </Row>
  );
};
