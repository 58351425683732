import React from 'react';
import Button from 'react-bootstrap/Button';

export const JoinLitxButton = ({ onClick, isDisabled }) => {
  return (
    <Button variant="primary" disabled={isDisabled} onClick={onClick}>
      Join LitX
    </Button>
  );
};
