import { useState, createRef } from 'react';
import { Recaptcha } from '../components/recaptcha';
import { siteKey } from '../utils/constants';

export const useRecaptcha = () => {
  const [isCaptchaChecked, setIsCaptchaChecked] = useState(false);
  const recaptchaRef = createRef();

  const handleCaptchaChange = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    setIsCaptchaChecked(!!recaptchaValue);
  };

  const renderCaptcha = () => {
    return (
      <div className="captcha__wrapper">
        <Recaptcha forwardRef={recaptchaRef} sitekey={siteKey} onChange={handleCaptchaChange} />
      </div>
    );
  };

  return { isCaptchaChecked, renderCaptcha };
};
