export const Partys = ({ partyList, myPartyId }) => {
  const invitablePartys = partyList?.filter((party) => {
    return !party.partyReps.length || party.id === myPartyId;
  });

  return invitablePartys.map((party) => (
    <option key={`${party.id}`} value={party.partyName}>
      {party.partyName}
    </option>
  ));
};
