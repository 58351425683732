import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { CancelOrAcceptCluster } from '../../../cancelOrAcceptCluster';
import { componentViews } from '../../../../utils/enums';
import { asTypes, TextField } from '../../../textFieldDeprecated';
import { Select } from '../../../customSelect';

export const InviteFromMyAccountToCase = ({
  caseDetails,
  setCurrentComponent,
  partySelection,
  toggleModalVisibility,
  accountUsers,
  userProfile,
  sendInvite,
  isLoading,
  options,
}) => {
  const [inviteData, setInviteData] = useState({
    caseId: caseDetails.id,
    partyId: caseDetails.myParty.partyId,
    emailAddress: '',
    firstName: '',
    lastName: '',
    function: '',
    company: 'N/A',
    addToAccount: false,
    isAdmin: false,
    note: '',
  });

  const handleUserSelected = (selectedEmailAddress) => {
    const user = accountUsers.find((accountUser) => accountUser.emailAddress === selectedEmailAddress);
    const [firstName, lastName] = user.name.split(' ');

    setInviteData({
      ...inviteData,
      firstName,
      lastName,
      emailAddress: user.emailAddress,
      company: userProfile.company,
      addToAccount: false,
    });
  };

  const areRequiredFieldsFilled = ['firstName', 'lastName', 'emailAddress', 'function'].every(
    (field) => !!inviteData[field]
  );

  const accountUsersOptions = accountUsers
    .filter((user) => user.id !== userProfile.id)
    ?.map((user) => ({ name: user.name, value: user.emailAddress }));

  return (
    <Row>
      <Col xs={12}>
        <div className="invite-to-case-form standard-modal">
          <Form>
            <Row className="mb-1">
              <Col>
                <TextField
                  type={'text'}
                  isDisabled={true}
                  value={partySelection.partyName}
                  onChange={setInviteData}
                  controlId={'partySelect'}
                  label={'Invitee will represent'}
                  as={asTypes.input}
                />
              </Col>

              <Col>
                <Select
                  id={'user'}
                  label="Invite account members"
                  placeholder={'Select an acount user'}
                  options={accountUsersOptions}
                  value={inviteData.emailAddress}
                  onChange={handleUserSelected}
                />

                <div
                  className="action-link mt-2 pointer"
                  onClick={() => setCurrentComponent(componentViews.INVITE_USER_TO_MY_PARTY)}
                >
                  &#43; Invite new user
                </div>
              </Col>
            </Row>

            <Row className="mb-4">
              <Select
                id={'function'}
                label={'Invitee will have the following role'}
                placeholder={'Please select a role'}
                onChange={(newFunction) => setInviteData({ ...inviteData, function: newFunction })}
                options={options}
                value={inviteData.function}
              />
            </Row>

            <Row className="mb-3">
              <TextField
                type={'text'}
                name={'note'}
                isDisabled={false}
                label={'Personal note for the invite email (Optional)'}
                value={inviteData.note}
                onChange={setInviteData}
                controlId={'note'}
                as={asTypes.textarea}
              />
            </Row>

            <CancelOrAcceptCluster
              onCancelClick={toggleModalVisibility}
              isLoading={isLoading}
              onAcceptClick={() => sendInvite(inviteData)}
              isDisabled={!areRequiredFieldsFilled}
              acceptLabel={'Invite to Case'}
            />
          </Form>
        </div>
      </Col>
    </Row>
  );
};
