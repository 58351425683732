import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { initialAccountUsers, initialUserProfile } from '../utils/defaults';
import { logOutUser, fetchUser, fetchAccountUsers, updateUserLogin } from '../utils/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { fireErrorToast, fireSuccessToast } from '../utils/alert';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [userProfile, setUserProfile] = useState(initialUserProfile);
  const [accountUsers, setAccountUsers] = useState(initialAccountUsers);
  const [fetchedUserInfo, setFetchedUserInfo] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const currentRoute = useLocation();

  const navigate = useNavigate();

  const getUserProfile = async () => {
    try {
      const userProfileResult = await fetchUser();
      setUserProfile(userProfileResult);

      setFetchedUserInfo(true);

      window.localStorage.setItem('litx-auth', JSON.stringify(true));
      return userProfileResult;
    } catch (e) {
      setFetchedUserInfo(true);
      window.localStorage.removeItem('litx-auth');
      setIsAuthenticated(false);
    }
  };

  const retreiveAccountUsers = async () => {
    try {
      const accountUsersResult = await fetchAccountUsers();
      setAccountUsers(accountUsersResult);
      return accountUsersResult;
    } catch (_) {}
  };

  const logout = async () => {
    try {
      await logOutUser({});

      setUserProfile(initialUserProfile);
      setIsAuthenticated(false);
      setFetchedUserInfo(false);
      window.localStorage.removeItem('litx-auth');
      navigate('/login');
    } catch (e) {
      fireErrorToast('Error logging out');
    }
  };

  const handle2FAChange = async (method, isLoading, setIsLoading) => {
    if (isLoading || method === userProfile.twoFactorPreference) return;

    setIsLoading(true);
    try {
      await updateUserLogin({ twoFactorPreference: method });
      await getUserProfile();
      fireSuccessToast(`Default two factor method set to ${method}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserProfile();
  }, [currentRoute]);

  useEffect(() => {
    retreiveAccountUsers();
  }, [isAuthenticated, userProfile]);

  const finalParams = useMemo(
    () => ({
      accountUsers,
      userProfile,
      setUserProfile,
      isAuthenticated,
      logout,
      getUserProfile,
      fetchedUserInfo,
      retreiveAccountUsers,
      setIsAuthenticated,
      handle2FAChange,
    }),
    [accountUsers, userProfile, fetchedUserInfo, isAuthenticated]
  );

  return <AuthContext.Provider value={finalParams}>{children}</AuthContext.Provider>;
};

export const AuthContextConsumer = () => useContext(AuthContext);
