import { useTable, useSortBy } from 'react-table';
import React, { useEffect, useMemo, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import { CasesContextConsumer } from '../../contexts/casesContext';
import { AccountAdminSelect } from './accountAdminSelect';
import { TableHeaderRow } from './tableHeaderRow';
import { TableBodyRow } from './tableBodyRow';
import { CaseSearch } from './caseSearch';

const DashboardCasesTable = () => {
  const [selectCaseDisplay, setSelectCaseDisplay] = useState(false);
  const { currentCases, getCurrentCases, totalCases } = CasesContextConsumer();
  const navigate = useNavigate();

  useEffect(() => {
    getCurrentCases({ dependant: selectCaseDisplay });
  }, [selectCaseDisplay]);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'caseName',
        Cell: (props) => (
          <Link
            to={`/case-detail?caseId=${props.row.original.id}`}
            className="pointer"
            style={{ textDecoration: 'none', color: 'unset' }}
          >
            {props.row.original.caseName} {props.row.original.active ? '' : '(closed)'}
          </Link>
        ),
      },
      {
        Header: 'Cause No.',
        accessor: 'causeNo',
      },
      {
        Header: 'Type of Case',
        accessor: 'caseType',
      },
      {
        Header: 'Recent Activity',
        accessor: 'updatedAt',
        Cell: (props) => dayjs(props.value).format('MM/DD/YYYY'),
      },
      {
        Header: 'Added',
        accessor: 'createdAt',
        Cell: (props) => dayjs(props.value).format('MM/DD/YYYY'),
      },
      {
        Header: 'Created By',
        accessor: 'createdBy',
      },
    ],
    [currentCases]
  );

  const initialSortedColumn = useMemo(() => [{ id: 'createdAt', desc: true }]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data: currentCases, initialState: { sortBy: initialSortedColumn } },
    useSortBy
  );

  return (
    <>
      <Row className="d-flex justify-content-between align-items-center mb-3">
        <div className=" d-flex align-items-center cases-detail">
          <div className="litx-table__cases-count me-4">
            {totalCases} {totalCases > 1 ? 'cases' : 'case'}
          </div>
          <Button onClick={() => navigate('/create-case')}>New Case</Button>
          <AccountAdminSelect onChange={setSelectCaseDisplay} />
        </div>
        <CaseSearch selectCaseDisplay={selectCaseDisplay} />
      </Row>
      <table className="dashboard-multicase__table" {...getTableProps()} style={{ border: 'solid 2px #ECEAE4' }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <TableHeaderRow key={Math.random()} headerGroup={headerGroup} />
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => (
            <TableBodyRow key={Math.random()} row={row} prepareRow={prepareRow} />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default DashboardCasesTable;
