import './index.scss';
import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { updateProfile } from '../../../../utils/api';
import { TextField } from '../../../textField';
import { CancelOrAcceptCluster } from '../../../cancelOrAcceptCluster';
import { AuthContextConsumer } from '../../../../contexts/authContext';
import { fireErrorToast, fireSuccessToast } from '../../../../utils/alert';

export const EditProfile = ({ currentModalField, toggleModalVisibility }) => {
  const { getUserProfile, userProfile } = AuthContextConsumer();

  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(userProfile[currentModalField]);

  const submitEditProfile = async () => {
    setIsLoading(true);
    try {
      await updateProfile({ [currentModalField]: value });
      await getUserProfile();
      fireSuccessToast(`Profile updated successfully`);
      toggleModalVisibility();
    } catch (err) {
      fireErrorToast('Something went wrong. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Row>
      <div className="invite-to-case-form standard-modal">
        <Form>
          <TextField id={currentModalField} value={value} onChange={setValue} />
          <CancelOrAcceptCluster
            onCancelClick={toggleModalVisibility}
            onAcceptClick={submitEditProfile}
            acceptLabel="Save"
            isLoading={isLoading}
          />
        </Form>
      </div>
    </Row>
  );
};
