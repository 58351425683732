import React from 'react';
import BaseModal from '../../base_modal';
import AddNewParty from './addNewParty';

export const AddNewPartyModal = ({ isVisible, toggleModalVisibility, caseDetails, getCaseDetails }) => (
  <BaseModal
    isVisible={isVisible}
    toggleVisibility={toggleModalVisibility}
    modalContent={
      <AddNewParty
        toggleModalVisibility={toggleModalVisibility}
        caseDetails={caseDetails}
        getCaseDetails={getCaseDetails}
      />
    }
    headerCopy="Add new party to the case"
    wide={true}
  />
);
