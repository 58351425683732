import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { fireErrorToast, fireSuccessToast } from '../../../../utils/alert';
import { updateProfile } from '../../../../utils/api';
import { CancelOrAcceptCluster } from '../../../cancelOrAcceptCluster';
import { AuthContextConsumer } from '../../../../contexts/authContext';
import { CheckBox } from '../../../checkBox';
import { twoFactorPreference } from '../../../../utils/constants';
import { PhoneField } from '../../../phoneField';
import { phoneDigits } from '../../../../utils/constants';

const AddPhone = ({ toggleModalVisibility, currentModalField, savedPhone = '' }) => {
  const [phone, setPhone] = useState(savedPhone.slice(-phoneDigits));
  const [isLoading, setIsLoading] = useState(false);
  const [updateSms, setUpdateSms] = useState(true);

  const { getUserProfile, handle2FAChange } = AuthContextConsumer();

  const handleAddPhoneClick = async () => {
    if (!phone) fireErrorToast('Please enter a phone number');
    if (phone.length !== phoneDigits) fireErrorToast('Please enter a valid phone number');
    setIsLoading(true);

    try {
      await updateProfile({ [currentModalField]: phone });
      await getUserProfile();
      fireSuccessToast(`${currentModalField} updated correctly`);
      updateSms && (await handle2FAChange(twoFactorPreference.sms, isLoading, setIsLoading));
      toggleModalVisibility();
    } catch (err) {
      fireErrorToast(`Saving your ${currentModalField} number failed. Contact support.`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEnter = (e) => {
    if (e.key !== 'Enter') return;

    e.preventDefault();
    if (!isValid || isLoading) return;

    handleAddPhoneClick();
  };

  return (
    <Row>
      <Col xs={12}>
        <div className="add-folder-form standard-modal">
          <Form>
            <Row className="mb-3">
              <PhoneField
                id="phone"
                value={phone}
                label="Add mobile phone number"
                onChange={setPhone}
                onKeyDown={handleEnter}
                placeholder=""
              />
            </Row>
            <Row className="mb-1">
              <CheckBox
                onChange={() => setUpdateSms(!updateSms)}
                isChecked={updateSms}
                name="updateSms"
                label="Update two-step verification method to sms"
                title="updateSms"
              />
            </Row>
            <CancelOrAcceptCluster
              onCancelClick={toggleModalVisibility}
              onAcceptClick={handleAddPhoneClick}
              acceptLabel="Save"
              isLoading={isLoading}
              isDisabled={phone.length !== phoneDigits || isLoading}
            />
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default AddPhone;
