import { useContext } from 'react';
import { CasesContext } from '../../contexts/casesContext';
import { RightArrow } from '../../components/svg/rightArrow';
import { LeftArrow } from '../../components/svg/leftArrow';

export const PageNavigation = () => {
  const { currentPage, setCurrentPage, totalPages } = useContext(CasesContext);

  if (totalPages === 1) return null;

  const handleNextPageClick = () => {
    if (currentPage === totalPages) return;

    setCurrentPage(currentPage + 1);
  };

  const handlePrevPageClick = () => {
    if (currentPage === 1) return;

    setCurrentPage(currentPage - 1);
  };

  return (
    <div className="dashboard-multicase__pagination-container">
      <LeftArrow
        onClick={handlePrevPageClick}
        height="24"
        width="24"
        className={`dashboard-multicase__pagination-arrow ${
          currentPage === 1 && 'dashboard-multicase__pagination-arrow-hidden'
        }`}
      />
      {currentPage} of {totalPages}
      <RightArrow
        onClick={handleNextPageClick}
        height="24"
        width="24"
        className={`dashboard-multicase__pagination-arrow ${
          currentPage === totalPages && 'dashboard-multicase__pagination-arrow-hidden'
        }`}
      />
    </div>
  );
};
