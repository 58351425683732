import './index.scss';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/drop-target/dist/style.css';

import React, { useState, useEffect, useCallback } from 'react';
import UploadedFilesTable from './uploadedFilesTable';
import { AIProvider } from '../../contexts/aiContext';
import { Dashboard as UploadDropBox } from '@uppy/react';
import { PathBreadCrumbs } from '../breadCrumbs';
import { UploadConfirmationModal, AddNewFolderModal } from '../modals';
import { sendGetRequest, sendPostRequest, routes } from '../../utils/api';
import { useUppy } from '../../hooks/useUppy';
import { fireErrorToast } from '../../utils/alert';
import { ClawBackRequestModal } from '../modals/modal_content/clawBackRequestModal/modal';
import { FilesConsumer } from '../../contexts/filesContext';
import { MoveModal } from '../modals/modal_content/move/modal';
import { RenameModal } from '../modals/modal_content/rename/modal';
import { RemoveEmptyFolderModal } from '../modals/modal_content/removeEmptyFolder/modal';
import { downloadFile } from '../../utils/api';
import { Controls } from './controls';
import { AiChat } from './aiChat';
import { useResizeHorizontal } from '../../hooks/useResizeHorizontal';
import { Col } from 'react-bootstrap';

const FilesSection = () => {
  const {
    courtCaseId,
    caseDetails,
    currentPath,
    setCurrentPath,
    uploadedFiles,
    fileNamesToUpload,
    setFileNamesToUpload,
    clawbackFile,
    setClawbackFile,
    getUploadedFiles,
    clearSelectedPathObjs,
    toggleSelectedFileId,
    forceSingleSelectedFileId,
    searchTerm,
    setSearchTerm,
    leftPanelRef,
    rightPanelRef,
  } = FilesConsumer();
  const toggle = (prevValue) => !prevValue;

  const [showUploadConfirmationModal, setShowUploadConfirmationModal] = useState(false);
  const toggleUploadConfirmationModal = () => setShowUploadConfirmationModal(toggle);

  const [displayAddFolder, setDisplayAddFolder] = useState(false);
  const toggleAddFolderModal = () => setDisplayAddFolder(toggle);

  const [showClawbackRequestModal, setShowClawbackRequestModal] = useState(false);
  const toggleClawbackRequestModal = () => setShowClawbackRequestModal(toggle);

  const [showMoveModal, setShowMoveModal] = useState(false);
  const toggleMoveModal = () => setShowMoveModal(toggle);

  const [showRenameModal, setShowRenameModal] = useState(false);
  const toggleRenameModal = () => setShowRenameModal(toggle);

  const [showRemoveEmptyFolder, setShowRemoveEmptyFolder] = useState(false);
  const toggleRemoveEmptyFolderModal = () => setShowRemoveEmptyFolder(toggle);

  const [showUploadArea, setShowUploadArea] = useState(false);

  const { onMouseDown, onTouchStart, onTouchMove, onTouchEnd } = useResizeHorizontal();

  const handleAddFolder = async (folderName) => {
    const folder = { name: folderName };

    if (!isFileUniqueUrl(folder)) {
      fireErrorToast('Folder already exists');

      return;
    }

    await sendPostRequest(routes.folders, { url: buildFileUrl(folder), courtCaseId });
    await getUploadedFiles();
    toggleAddFolderModal();
  };

  const handleBeforeUpload = useCallback((newFilesToUpload) => {
    const newFileNamesToUpload = Object.values(newFilesToUpload).map((file) => file.name);
    setFileNamesToUpload(newFileNamesToUpload);
    toggleUploadConfirmationModal();

    return false;
  }, []);

  const normalizeText = (text) => {
    const charMap = {
      '\u00A0': ' ', // Non-breaking space
      '\u2000': ' ', // En Quad
      '\u2001': ' ', // Em Quad
      '\u2002': ' ', // En Space
      '\u2003': ' ', // Em Space
      '\u2004': ' ', // Three-Per-Em Space
      '\u2005': ' ', // Four-Per-Em Space
      '\u2006': ' ', // Six-Per-Em Space
      '\u2007': ' ', // Figure Space
      '\u2008': ' ', // Punctuation Space
      '\u2009': ' ', // Thin Space
      '\u200A': ' ', // Hair Space
      '\u202F': ' ', // Narrow No-Break Space
      '\u205F': ' ', // Medium Mathematical Space
      '\u3000': ' ', // Ideographic Space
      '\u2018': "'", // Left Single Quotation Mark
      '\u2019': "'", // Right Single Quotation Mark
      '\u201C': '"', // Left Double Quotation Mark
      '\u201D': '"', // Right Double Quotation Mark
      '\u2013': '-', // En Dash
      '\u2014': '-', // Em Dash
    };

    for (let char in charMap) {
      let regExp = new RegExp(char, 'g');
      text = text.replace(regExp, charMap[char]);
    }

    return text;
  };

  const buildFileUrl = useCallback(
    (file) => courtCaseId + '/' + currentPath + normalizeText(file.name),
    [currentPath, courtCaseId]
  );

  const isFileUniqueUrl = useCallback(
    (fileBeingAdded) => {
      const fileBeingAddedUrl = buildFileUrl(fileBeingAdded);
      const uploadedFilesUrls = uploadedFiles.map((uploadedFile) => uploadedFile.url);

      return !uploadedFilesUrls.includes(fileBeingAddedUrl);
    },
    [buildFileUrl, uploadedFiles.length]
  );

  const uppy = useUppy(uploadedFiles, handleBeforeUpload, isFileUniqueUrl);

  const configureUpload = () => {
    const filesToUpload = uppy.getFiles();

    for (const file of filesToUpload) {
      file.meta.name = encodeURI(file.meta.name);
      uppy.setFileMeta(file.id, { url: buildFileUrl(file) });
    }

    uppy.setOptions({ onBeforeUpload: () => true });
  };

  const handleUpload = async () => {
    const { items: ownerCaseList } = await sendGetRequest(routes.cases, new URLSearchParams({ dependant: false }));

    if (!ownerCaseList.some((courtCase) => courtCase.id === courtCaseId)) {
      fireErrorToast('Upload failed, you are not a member of this case.');
      toggleUploadConfirmationModal();
      return;
    }

    toggleUploadConfirmationModal();
    configureUpload();

    const result = await uppy.upload();

    if (result?.failed?.length > 0) return fireErrorToast('Failed to upload files');

    const files = result.successful.map((file) => {
      return {
        folder: '',
        fileMime: file.data.type,
        fileName: file.data.name,
        fileSize: file.data.size,
        courtCaseId,
        fileURL: file.meta.url,
      };
    });

    await sendPostRequest(routes.files, { files });
    await getUploadedFiles();
    setShowUploadArea(false);

    uppy.cancelAll({ reason: 'user' });
    uppy.setOptions({ onBeforeUpload: handleBeforeUpload });
  };

  const handleUploadCancel = () => {
    setFileNamesToUpload([]);
    setShowUploadArea(false);
    toggleUploadConfirmationModal();
  };

  const handlePathChange = (newPath) => {
    setCurrentPath(newPath);
    clearSelectedPathObjs();
  };

  const handlePathObjectDoubleClick = async (pathObj) => {
    if (pathObj.isFile) {
      await downloadFile(pathObj.id, { openInBrowser: true });
      return getUploadedFiles();
    }

    clearSelectedPathObjs();
    setCurrentPath(currentPath + pathObj.name + '/');
  };

  const handleRenameOpenModal = (pathObj) => {
    forceSingleSelectedFileId(pathObj);
    return toggleRenameModal(pathObj);
  };

  const handleDownloadCaseFile = async (pathObj) => {
    await downloadFile(pathObj.id, { openInBrowser: false });
    await getUploadedFiles();
    forceSingleSelectedFileId(pathObj);
  };

  useEffect(() => () => uppy.close({ reason: 'unmount' }), []);
  useEffect(() => setShowUploadArea(uploadedFiles.length === 0), [uploadedFiles.length]);
  useEffect(() => {
    getUploadedFiles();
  }, [courtCaseId, getUploadedFiles, showUploadArea]);

  const renderModals = () => {
    return (
      <>
        <UploadConfirmationModal
          isVisible={showUploadConfirmationModal}
          toggleModalVisibility={toggleUploadConfirmationModal}
          onUploadConfirm={handleUpload}
          onUploadCancel={handleUploadCancel}
          fileNamesToUpload={fileNamesToUpload}
        />
        <AddNewFolderModal
          isVisible={displayAddFolder}
          toggleModalVisibility={toggleAddFolderModal}
          onAddFolder={handleAddFolder}
        />
        <ClawBackRequestModal
          isVisible={showClawbackRequestModal}
          toggleModalVisibility={toggleClawbackRequestModal}
          caseDetails={caseDetails}
          clawbackFile={clawbackFile}
        />
        <ClawBackRequestModal
          isVisible={showClawbackRequestModal}
          toggleModalVisibility={toggleClawbackRequestModal}
          caseDetails={caseDetails}
          clawbackFile={clawbackFile}
        />
        <MoveModal isVisible={showMoveModal} toggleModalVisibility={toggleMoveModal} />
        <RenameModal isVisible={showRenameModal} toggleModalVisibility={toggleRenameModal} />
        <RemoveEmptyFolderModal
          isVisible={showRemoveEmptyFolder}
          toggleModalVisibility={toggleRemoveEmptyFolderModal}
        />
      </>
    );
  };

  const renderDashboard = () => {
    return (
      <div className="filesSection__chat_container">
        {showUploadArea ? (
          <Col id="left-panel" xs={12} md={6} ref={leftPanelRef}>
            <UploadDropBox uppy={uppy} proudlyDisplayPoweredByUppy={false} inline={true} height={600} width={2000} />
          </Col>
        ) : (
          <UploadedFilesTable
            toggleRemoveEmptyFolderModal={toggleRemoveEmptyFolderModal}
            toggleMoveModal={toggleMoveModal}
            toggleRenameModal={handleRenameOpenModal}
            toggleClawbackRequestModal={toggleClawbackRequestModal}
            onClawback={setClawbackFile}
            onClick={(pathObj) => toggleSelectedFileId(pathObj)}
            onDoubleClick={handlePathObjectDoubleClick}
            onDownload={handleDownloadCaseFile}
            onReceiptDownload={(fileId) => downloadFile(fileId, { openInBrowser: false, isReceipt: true })}
            forwardRef={leftPanelRef}
          />
        )}
        <div
          className="filesSection__divider"
          onMouseDown={onMouseDown}
          // onTouchStart={onTouchStart}
          // onTouchMove={onTouchMove}
          // onTouchEnd={onTouchEnd}
        ></div>
        <AiChat forwardRef={rightPanelRef} />
      </div>
    );
  };

  const renderBreadCrumbs = () => {
    if (!currentPath) return;

    return (
      <PathBreadCrumbs
        courtCaseName={caseDetails.caseName}
        currentPath={currentPath}
        onCurrentPathChange={handlePathChange}
      />
    );
  };

  return (
    <AIProvider>
      <div
        className="documents-background"
        onClick={(e) => {
          if (e.target !== e.currentTarget) return;
          return clearSelectedPathObjs();
        }}
      >
        <div className="filesSection__documents">
          <Controls
            showUploadArea={showUploadArea}
            searchTerm={searchTerm}
            onSearchChange={(newSearchTerm) => {
              setShowUploadArea(false);
              setSearchTerm(newSearchTerm);
            }}
            onAddFolderClick={() => {
              setShowUploadArea(false);
              toggleAddFolderModal();
            }}
            onFilesClick={() => {
              setShowUploadArea(false);
            }}
            onUploadClick={() => {
              setShowUploadArea(true);
            }}
          />
          {renderBreadCrumbs()}
          <div className="mb-3">
            {renderDashboard()}
            {renderModals()}
          </div>
        </div>
      </div>
    </AIProvider>
  );
};

export default FilesSection;
