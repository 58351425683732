import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { AuthContextConsumer } from '../../../contexts/authContext';
import { fireErrorToast, fireSuccessToast } from '../../../utils/alert';
import { updateProfile } from '../../../utils/api';

const NotificationSettings = () => {
  const { userProfile, setUserProfile, getUserProfile } = AuthContextConsumer();

  const [isChecked, setIsChecked] = useState(!!userProfile?.newDocumentEmail);

  const handleToggleNotificationConfig = async () => {
    try {
      const newIsChecked = !isChecked;
      setIsChecked(newIsChecked);
      await updateProfile({ newDocumentEmail: newIsChecked });
      setUserProfile((prev) => ({
        ...prev,
        newDocumentEmail: newIsChecked,
      }));
      await getUserProfile();
      const notificationsMessage = newIsChecked ? 'disabled' : 'enabled';
      fireSuccessToast(`Document notification settings ${notificationsMessage}`);
    } catch (err) {
      fireErrorToast('Error updating notification settings please try again');
    }
  };

  return (
    <div className="account-settings">
      <div className="subscription col-xs-12 col-lg-6 mb-5">
        <div className="subscription__row" />
        <div className="subscription__row">
          <div className="left-col">Send email when a new Document is added to a case</div>
          <div className="middle-col"></div>
          <div className="right-col">
            <Form.Check type="switch" id="custom-switch">
              <Form.Check.Input
                isInvalid
                checked={isChecked}
                className="switch"
                onChange={handleToggleNotificationConfig}
              />
            </Form.Check>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;
