import './index.scss';

import React, { useEffect, useState } from 'react';
import ReactInputVerificationCode from 'react-input-verification-code';
import { useNavigate } from 'react-router-dom';
import { fireErrorToast, fireSuccessToast } from '../../utils/alert';
import { AuthContextConsumer } from '../../contexts/authContext';
import { Button } from '../../components/button';
import { send2FAToken, verify2FA } from '../../utils/api';
import { twoFactorPreference } from '../../utils/constants';
import { useQueryParams } from '../../hooks/useQueryParams';
import { EnterpriseLayout } from '../../layouts/enterpriseLayout';

export const VerificationPage = () => {
  const { pendingCaseRequestId } = useQueryParams();

  const [verificationCode, setVerificationCode] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { getUserProfile, userProfile, setIsAuthenticated } = AuthContextConsumer();
  const [last2FAMethodUsed, setLast2FAMethodUsed] = useState();

  const isVerifyCodeButtonEnabled = !loading && verificationCode?.length === 6;
  const defaultTwoFAMethod = userProfile.twoFactorPreference;
  const isSMS2FaMethodLastUsed = (last2FAMethodUsed || defaultTwoFAMethod) === twoFactorPreference.sms;

  const handleVerifyCode = async (yes) => {
    try {
      setLoading(true);
      const { redirect } = await verify2FA({ code: Number(yes || verificationCode), pendingCaseRequestId });
      setIsAuthenticated(true);
      navigate(redirect);
    } catch (err) {
      fireErrorToast('Please enter a valid verification code.');
    } finally {
      setLoading(false);
    }
  };

  const handleSend2FA = async (method) => {
    await send2FAToken({ twoFactorPreference: method });
    fireSuccessToast(`Verification code sent through ${method}`);
    setLast2FAMethodUsed(method);
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  const renderReSendDefaultMethod = () => (
    <div className="verification-form-reset" onClick={() => handleSend2FA(defaultTwoFAMethod)}>
      Send code again
    </div>
  );

  const renderSendAlternativeMethod = () => {
    const isAlternateMethodConfigured = !!userProfile.phone;
    const alternativeMethod =
      defaultTwoFAMethod === twoFactorPreference.email ? twoFactorPreference.sms : twoFactorPreference.email;

    if (!isAlternateMethodConfigured) return;

    return (
      <div className="verification-form-reset" onClick={() => handleSend2FA(alternativeMethod)}>
        Send code through alternative method {alternativeMethod}
      </div>
    );
  };

  return (
    <EnterpriseLayout>
      <div className="verification-form-container">
        <h1 className="verification-form-header">Verify</h1>

        <h2 className="verification-form-subheader">
          Please check your {isSMS2FaMethodLastUsed ? 'mobile phone' : 'email'} and enter the 6-digit passcode
        </h2>
        <ReactInputVerificationCode
          length={6}
          placeholder=""
          onChange={setVerificationCode}
          value={verificationCode}
          autoSubmit={true}
          onCompleted={handleVerifyCode}
        />

        <div className="verification-form-actions-container">
          <Button
            className="verification-form-verify-btn"
            onClick={handleVerifyCode}
            label="Verify"
            loading={loading}
            isDisabled={!isVerifyCodeButtonEnabled}
          />
          {renderReSendDefaultMethod()}
          {renderSendAlternativeMethod()}
        </div>
      </div>
    </EnterpriseLayout>
  );
};
