import React from 'react';
import { Folder, FileEarmarkText } from 'react-bootstrap-icons';

export const Row = ({ pathObject, onDoubleClick }) => {
  const isFolder = pathObject.isFolder;

  const handleDoubleClick = () => {
    if (!isFolder) return;

    onDoubleClick(pathObject);
  };

  const Icon = isFolder ? Folder : FileEarmarkText;
  const className = `table-row ${isFolder && 'table-row-folder-hover'}`;

  return (
    <div className={className} onDoubleClick={handleDoubleClick}>
      <Icon className="file-icon" />
      <span className="path-object-name"> {pathObject.name} </span>
    </div>
  );
};
