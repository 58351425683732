import './index.scss';
import GreenPartialLogo from '../../assets/litx_partial--green.svg';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, variant } from '../../components/button';
import { TextField } from '../../components/textField';
import { Select } from '../../components/customSelect';
import Form from 'react-bootstrap/Form';
import { createCase } from '../../utils/api';
import { caseRoles, caseTypes, neutralRoles } from '../../utils/defaults';
import { fireErrorToast } from '../../utils/alert';
import { caseType } from '../../utils/enums';
import { CheckBox } from '../../components/checkBox';
import { isEmptyLabel } from '../../utils/string';

const createParty = ({ partyName = '', type, partyReps = [] }) => {
  return { id: Math.floor(Math.random() * 1000).toString(), partyName, type, partyReps };
};

const CreateCase = () => {
  const [loading, setLoading] = useState(false);
  const [createCaseForm, setCreateCaseForm] = useState({
    caseName: '',
    courtName: '',
    causeNo: '',
    caseType: '',
    caseParties: [createParty({ type: caseType.plaintiff }), createParty({ type: caseType.defendant })],
    myParty: undefined,
  });
  const [role, setRole] = useState();
  const [neutralParty, setNeutralParty] = useState();

  const navigate = useNavigate();

  const caseParties = neutralParty ? [...createCaseForm.caseParties, neutralParty] : createCaseForm.caseParties;
  const casePartiesWithoutNeutralParty = createCaseForm.caseParties;

  const isCreateCaseButtonEnabled = [
    createCaseForm.caseName,
    createCaseForm.courtName,
    role,
    createCaseForm?.myParty?.id,
    createCaseForm.caseParties?.[0]?.id,
    createCaseForm.caseParties?.[1]?.id,
  ].every((field) => !isEmptyLabel(field));

  const handleCreateCaseClick = async () => {
    const areEmptyPartyNames = caseParties.filter((party) => !party.partyName).length > 0;
    if (areEmptyPartyNames) {
      fireErrorToast('Please fill empty party names or remove them!');
      return;
    }

    createCaseForm.myParty.partyReps = [{ function: role }];
    createCaseForm.caseParties = caseParties.map((party) => {
      const partyWithoutId = { ...party, id: undefined };
      if (party.id === createCaseForm.myParty.id) partyWithoutId.partyReps = createCaseForm.myParty.partyReps;

      return partyWithoutId;
    });

    try {
      setLoading(true);
      const { id } = await createCase(createCaseForm);
      navigate(`/case-detail?caseId=${id}`);
    } catch (err) {
      fireErrorToast('There was an error creating your case. Please try again or contact support');
    } finally {
      setLoading(false);
    }
  };

  const handleRemovePartyClick = (id) => {
    const caseParties = casePartiesWithoutNeutralParty.filter((party) => party.id !== id);
    setCreateCaseForm({ ...createCaseForm, caseParties });
  };

  const handleAddPartyClick = (type) => {
    const caseParties = [...casePartiesWithoutNeutralParty, createParty({ type })];
    setCreateCaseForm({ ...createCaseForm, caseParties });
  };

  const handleMyPartyChange = (id) => {
    const myParty = caseParties.find((party) => party.id === id);
    setCreateCaseForm({ ...createCaseForm, myParty });
  };

  const handlePartyNameChange = (party, partyName) => {
    const isNameUnique =
      casePartiesWithoutNeutralParty.filter((party) => party.partyName).filter((party) => party.partyName === partyName)
        .length === 0;
    if (!isNameUnique) return;

    const partyToUpdate = casePartiesWithoutNeutralParty.find((caseParty) => caseParty.id === party.id);
    partyToUpdate.partyName = partyName;
    if (!partyName && party.id === createCaseForm.myParty?.id) createCaseForm.myParty = undefined;

    setCreateCaseForm({ ...createCaseForm });
  };

  const handleToggleAddNeutralParty = (isChecked) => {
    const wasNeutralMyParty = neutralParty && neutralParty.id === createCaseForm.myParty?.id;
    const newMyParty = wasNeutralMyParty ? undefined : createCaseForm.myParty;
    setCreateCaseForm({ ...createCaseForm, myParty: newMyParty });

    const newNeutralParty = isChecked ? createParty({ partyName: 'Neutral', type: caseType.neutral }) : undefined;
    setNeutralParty(newNeutralParty);
  };

  const renderPartiesColumn = (type) => {
    const parties = casePartiesWithoutNeutralParty.filter((party) => party.type === type);

    return parties.map((party, index) => {
      const isFirst = index === 0;
      const isPlantiff = type === caseType.plaintiff;
      const label = `Name of ${isPlantiff ? 'Plaintiff' : 'Defendant'}`;
      const labelWithRemove = (
        <div key={Math.random()}>
          {label}
          <span className="pointer add-party-link" onClick={() => handleRemovePartyClick(party.id)}>
            Remove
          </span>
        </div>
      );

      return (
        <TextField
          key={party.id}
          id={party.id}
          label={isFirst ? label : labelWithRemove}
          value={party.partyName}
          className="mb-2"
          onChange={(partyName) => handlePartyNameChange(party, partyName)}
        />
      );
    });
  };

  const renderAddAnotherParty = (type) => (
    <Col>
      <div className="add-party-link pointer" onClick={() => handleAddPartyClick(type)}>
        &#43; &nbsp; Add Another {type[0].toUpperCase() + type.slice(1, type.length)}
      </div>
    </Col>
  );

  const renderMyParty = () => {
    const partyOptions = caseParties
      .map((party) => ({
        name: party.partyName,
        value: party.id,
      }))
      .filter((option) => !isEmptyLabel(option.name));
    const minimumPartiesToCreateCase = neutralParty ? 3 : 2;

    if (partyOptions.length < minimumPartiesToCreateCase) return;

    const roleOptions =
      createCaseForm.myParty?.type === caseType.neutral
        ? neutralRoles.map((role) => ({ name: role, value: role }))
        : caseRoles.map((role) => ({ name: role, value: role }));

    return (
      <>
        <Col>
          <Select
            id="myPartySelect"
            label="I represent the party"
            placeholder="Please Select"
            options={partyOptions}
            value={createCaseForm.myParty?.id}
            onChange={handleMyPartyChange}
          />
        </Col>
        <Col>
          <Select
            id="myPartyRoleSelect"
            label="My role is"
            placeholder="Please Select"
            value={role}
            options={roleOptions}
            onChange={setRole}
          />
        </Col>
      </>
    );
  };

  return (
    <div className="create-case">
      <Col xs={12} md={{ span: 6, offset: 3 }}>
        <div className="create-case-form">
          <div className="create-case-form__title mb-5">Create a Case</div>
          <Form>
            <Row className="mb-3">
              <TextField
                id="caseName"
                label="Name of Case"
                value={createCaseForm.caseName}
                onChange={(caseName) => setCreateCaseForm({ ...createCaseForm, caseName })}
              />

              <Col>
                <TextField
                  id="courtName"
                  label="Court Assigned"
                  value={createCaseForm.courtName}
                  onChange={(courtName) => setCreateCaseForm({ ...createCaseForm, courtName })}
                />
                <div className="mt-2 small-text">(Eg. In the 123rd District Court of Example County, TX)</div>
              </Col>
            </Row>

            <Row className="mb-3">
              <TextField
                id="causeNo"
                label="Cause Number"
                value={createCaseForm.causeNo}
                onChange={(causeNo) => setCreateCaseForm({ ...createCaseForm, causeNo })}
              />

              <Select
                id="caseTypeSelect"
                label="Type of Case (optional)"
                placeholder="Please Select"
                value={createCaseForm.caseType}
                options={caseTypes.map((type) => ({ name: type, value: type }))}
                onChange={(caseType) => setCreateCaseForm({ ...createCaseForm, caseType })}
              />
            </Row>

            <Row className="mb-3">
              <Col>
                <CheckBox
                  className="add-neutral-party"
                  label="Add Neutral Party"
                  isChecked={!!neutralParty}
                  onChange={handleToggleAddNeutralParty}
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>{renderPartiesColumn(caseType.plaintiff)}</Col>
              <Col>{renderPartiesColumn(caseType.defendant)}</Col>
            </Row>

            <Row className="mb-5">
              {renderAddAnotherParty(caseType.plaintiff)}
              {renderAddAnotherParty(caseType.defendant)}
            </Row>

            <Row className="mb-5">{renderMyParty()}</Row>

            <Row className="centered-button-row mb-5">
              <Button
                variant={variant.PRIMARY}
                loading={loading}
                onClick={handleCreateCaseClick}
                label="Create Case"
                isDisabled={!isCreateCaseButtonEnabled}
              />
            </Row>
          </Form>
        </div>
      </Col>

      <div className="create-case__branding-image">
        <img src={GreenPartialLogo} alt="" />
      </div>
    </div>
  );
};

export default CreateCase;
