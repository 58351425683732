import React, { useMemo, useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Search } from 'react-bootstrap-icons';
import dayjs from 'dayjs';
import { avatarBackgroundStyle, titleCase } from '../../utils/handlers';
import { fireSuccessToast, fireErrorToast } from '../../utils/alert';
import { useNavigate } from 'react-router-dom';
import { AuthContextConsumer } from '../../contexts/authContext';
import { Button } from 'react-bootstrap';
import casesIcon from '../../assets/casesIcon.png';
import { SortableTableHeaders } from '../../components/sortableTableHeaders';
import { declineCaseInvite, joinParty, submitResendCaseInvite } from '../../utils/api';

const inviteTabs = [
  {
    label: 'Sent',
    key: 'sent',
  },
  {
    label: 'Received',
    key: 'received',
  },
];

const InvitationsTable = ({ invitations, getInvitations }) => {
  const [currentTab, setCurrentTab] = useState('sent');

  const navigate = useNavigate();
  const { userProfile } = AuthContextConsumer();

  const resendCaseInvite = async (inviteId) => {
    try {
      await submitResendCaseInvite({ inviteId });
      fireSuccessToast('Invitation resent successfully');
    } catch (error) {
      fireErrorToast('Error resending invitation please try again later');
    }
  };

  const acceptInvite = async (inviteId) => {
    try {
      await joinParty({ inviteId });
      await getInvitations();
      fireSuccessToast('Invitation accepted successfully');
    } catch (error) {
      fireErrorToast('Error accepting invitation please try again later');
    }
  };

  const declineInvite = async (inviteId) => {
    try {
      await declineCaseInvite({ inviteId });
      await getInvitations();
      fireSuccessToast('Invitation declined successfully');
    } catch (error) {
      fireErrorToast('Error declining invitation please try again later');
    }
  };

  const data = currentTab === 'sent' ? invitations.sentInvites : invitations.receivedInvites;

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'createdAt',
        Cell: (props) => dayjs(props.value).format('MM/DD/YYYY h:mm A'),
      },
      {
        Header: 'Case',
        accessor: 'caseParty.courtCase.caseName',
        Cell: (props) => (
          <span
            className="pointer"
            onClick={() => navigate(`/case-detail?caseId=${props.row.original.caseParty.courtCase.id}`)}
          >
            <img src={casesIcon} className="cases-icon" /> {props.value}
          </span>
        ),
      },
      {
        Header: currentTab === 'sent' ? 'To' : 'From',
        accessor: currentTab === 'sent' ? 'receiverEmailAddress' : 'userLogin.emailAddress',
        Cell: (props) => {
          const fullName = props.row.original.receiverName || '';
          const nameSplit = fullName.split(' ');
          const firstNameInitial = nameSplit?.[0]?.[0] || '';
          const secondNameInitial = nameSplit?.[1]?.[0] || '';

          return (
            <div className="avatar-cell">
              {currentTab === 'sent' && (
                <div
                  className="avatar"
                  style={avatarBackgroundStyle(props.row.index, props.row.original.id === userProfile.id)}
                >{`${firstNameInitial}${secondNameInitial}`}</div>
              )}
              <span className="cell-info">{props.value}</span>
            </div>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (props) => <span>{titleCase(props.value)}</span>,
      },
      {
        Header: '',
        accessor: 'col5',
        Cell: (props) =>
          (props.row.original.status === 'PENDING' && props.row.original.senderUserId === userProfile.id && (
            <span className="resend-text pointer" onClick={() => resendCaseInvite(props.row.original.id)}>
              Resend Invitation
            </span>
          )) ||
          (props.row.original.status === 'PENDING' &&
            props.row.original.receiverEmailAddress === userProfile.emailAddress && (
              <div className="d-flex flex-column align-items-center mt-2">
                <Button className="join-button" onClick={() => acceptInvite(props.row.original.id)}>
                  Join Case
                </Button>
                <span className="mt-3" onClick={() => declineInvite(props.row.original.id)}>
                  Decline
                </span>
              </div>
            )),
      },
    ],
    [currentTab]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: { sortBy: [{ id: 'createdAt', desc: true }] },
    },
    useSortBy
  );

  return (
    <>
      <Row className="d-flex justify-content-between align-items-center mb-3">
        <div className="litx-table__tab-row mb-3">
          {inviteTabs.map((tab) => (
            <div
              key={tab.key}
              className={`litx-table__tab pointer ${currentTab === tab.key ? 'active' : ''}`}
              onClick={() => setCurrentTab(tab.key)}
            >
              {tab.label}
            </div>
          ))}
        </div>
        <Col xs={12} md={3} style={{ position: 'relative' }}>
          <Form.Control type="search" placeholder="Search Invitations" />
          <Search className="search-svg" />
        </Col>
      </Row>
      <table className="invitations__table" {...getTableProps()} style={{ border: 'solid 2px #ECEAE4' }}>
        <SortableTableHeaders headerGroups={headerGroups} />
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: '10px',
                        background: 'none',
                      }}
                    >
                      <div className="cell-info">{cell.render('Cell')}</div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {!rows.length && (
        <span className="d-flex justify-content-center none-sent">
          {currentTab === 'sent'
            ? "You haven't sent any case invitations yet."
            : "You haven't received any case invitations yet."}
        </span>
      )}
    </>
  );
};

export default InvitationsTable;
