import Uppy from '@uppy/core';
import Tus from '@uppy/tus';
import { useMemo } from 'react';
import { apiEndpoint } from '../utils/env';

export const useUppy = (uploadedFiles, onBeforeUpload, isFileUniqueUrl) => {
  return useMemo(() => {
    const uppyConfig = {
      id: 'files',
      debug: true,
      serviceWorker: true,
      onBeforeUpload,
    };
    const tusConfig = {
      endpoint: `${apiEndpoint}/upload`,
      retryDelays: [0, 1000, 3000, 5000],
      removeFingerprintOnSuccess: true,
      uploadDataDuringCreation: true,
      withCredentials: true,
    };

    const uppy = new Uppy(uppyConfig).use(Tus, tusConfig);

    const onBeforeFileAdded = (file) => {
      if (isFileUniqueUrl(file)) return;

      uppy.info(`File already exists.`);
      return false;
    };

    uppy.setOptions({ onBeforeFileAdded });

    return uppy;
  }, [uploadedFiles.length, isFileUniqueUrl]);
};
