import React from 'react';
import { TextField } from '../textField';

export const EmailField = ({
  id = 'email',
  value,
  label,
  placeholder,
  isDisabled = false,
  onChange,
  onKeyDown,
  classnames = { label: '', input: '' },
  ...containerProps
}) => {
  const handleOnChange = (event) => {
    // preserve caret position
    const caret = event.target.selectionStart;
    const element = event.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });

    const disallowedChars = '!#$%^&*()=[]{}\\|"\';:,<>?/`~ ';
    let filteredEmail = event.target.value;

    for (const disallowedChar of disallowedChars) {
      filteredEmail = filteredEmail.replaceAll(disallowedChar, '');
    }

    onChange(filteredEmail.trim().toLowerCase());
  };

  return (
    <TextField
      id={id}
      value={value}
      rawOnChange={handleOnChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      label={label}
      classnames={classnames}
      isDisabled={isDisabled}
      isPassword={false}
      {...containerProps}
    />
  );
};
