import './styles.scss';
import { useState } from 'react';
import { AIChatHelpModal } from '../../modals/modal_content/aiChatHelp/modal';
import { Prompt } from './prompt';
import { Header } from './header';
import { History } from './history';
import { Chat } from './chat';
import { AliaSVG } from '../../../components/svg/ai';

export const AiChat = ({ forwardRef }) => {
  const isHelpModalHidden = JSON.parse(localStorage.getItem('aiChatHelpModal'));
  const [isModalVisible, setIsModalVisible] = useState(!isHelpModalHidden);
  const toggleModalVisibility = () => setIsModalVisible(!isModalVisible);
  return (
    <div ref={forwardRef} id="right-panel" className="ai-chat__container">
      <div className="ai-chat__container-section">
        <Header />
        <History />
      </div>
      <div className="ai-chat__container-section">
        <div className="ai-chat__header">
          <div className="ai-chat__title-container">
            <p>{<AliaSVG className="ai-chat__title_logo" />}ALIA (A.I. Assistant)</p>
          </div>
          <button className="ai-chat__help_icon" onClick={toggleModalVisibility}>
            ?
          </button>
        </div>
        <div className="ai-chat__window">
          <Chat />
          <Prompt />
        </div>
      </div>
      <AIChatHelpModal isVisible={isModalVisible} toggleModalVisibility={toggleModalVisibility} />
    </div>
  );
};
