import React from 'react';
import BaseModal from '../../base_modal';
import AiChatHelp from './aiChatHelp';

export const AIChatHelpModal = ({ isVisible, toggleModalVisibility }) => (
  <BaseModal
    isVisible={isVisible}
    toggleVisibility={toggleModalVisibility}
    modalContent={<AiChatHelp toggleModalVisibility={toggleModalVisibility} />}
    headerCopy="Welcome to ALIA (A.I. Assistant)"
  />
);
