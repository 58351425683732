import './index.scss';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { AuthContextConsumer } from '../../contexts/authContext';
import { CasesContextConsumer } from '../../contexts/casesContext';
import { fireErrorToast, fireSuccessToast } from '../../utils/alert';
import { Button } from '../../components/button';
import { authLogin, submitVerifyEmail } from '../../utils/api';
import { EmailField } from '../../components/emailField';
import { TextField } from '../../components/textField';
import { useRecaptcha } from '../../hooks/useRecaptcha';
import { EnterpriseLayout } from '../../layouts/enterpriseLayout';

export const LoginPage = () => {
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { isCaptchaChecked, renderCaptcha } = useRecaptcha();

  const { isAuthenticated, fetchedUserInfo } = AuthContextConsumer();
  const { currentCases, getCurrentCases } = CasesContextConsumer();

  const navigate = useNavigate();
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const { state: locationState } = useLocation();

  const verifyEmail = async () => {
    try {
      await submitVerifyEmail({ token: query.get('token') });
      fireSuccessToast('Email confirmed');
    } catch (err) {
      fireErrorToast('Error confirming email. Please try again.');
    }
  };

  useEffect(() => {
    if (search && query.get('token')) {
      verifyEmail();
    }
  }, [search]);

  useEffect(() => {
    if (!isAuthenticated || !fetchedUserInfo) return;

    const dashboardRedirect = async () => {
      await getCurrentCases();
      if (currentCases) return navigate('/dashboard');
      navigate('/dashboard-welcome');
    };

    dashboardRedirect();
  }, [fetchedUserInfo, isAuthenticated]);

  const handleSubmit = async () => {
    if (!emailAddress || !password) {
      alert('Please enter an email and password.');
    }

    setLoading(true);
    try {
      await authLogin({ emailAddress, password });
      navigate('/verify', { state: locationState });
    } catch (err) {
      fireErrorToast('Error logging in. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordKeyDown = (event) => {
    if (event.keyCode === 13 && isCaptchaChecked) {
      handleSubmit();
    }
  };

  return (
    <EnterpriseLayout>
      <div className="login-form-container">
        <h1 className="login-form-header">Sign In</h1>

        <div className="login-form-inputs-container">
          <EmailField label="Email Address" placeholder="Enter email" value={emailAddress} onChange={setEmailAddress} />
          <TextField
            id="password"
            isPassword
            value={password}
            onChange={setPassword}
            onKeyDown={handlePasswordKeyDown}
            placeholder="Password"
            label="Password"
          />
        </div>

        <Link to="/forgot-password" className="link login-reset-password-link">
          Reset Password?
        </Link>

        {renderCaptcha()}
        <div className="login-actions-container">
          <Button
            className={`login-btn ${!isCaptchaChecked && 'login-btn__hidden'}`}
            label="Login"
            onClick={handleSubmit}
            loading={loading}
            isDisabled={!emailAddress || !password}
          />

          <div className="login-no-account">
            Don't have an account yet?{' '}
            <Link to="/register" className="link">
              Create Account
            </Link>
          </div>
        </div>
      </div>
    </EnterpriseLayout>
  );
};
