import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { ChatMessage, Chat, ResponseLoading } from '../components/filesSection/aiChat/models';
import { useQueryParams } from '../hooks/useQueryParams';
import { sendGetRequest, routes, sendPostRequest } from '../utils/api';
import { FilesConsumer } from './filesContext';

export const AIContext = createContext({});

export const AIProvider = ({ children }) => {
  const [prompt, setPrompt] = useState('');
  const [currentChat, setCurrentChat] = useState(new Chat());
  const [responseLoading, setResponseLoading] = useState({});
  const [chats, setChats] = useState({});
  const { selectedPathObjs } = FilesConsumer();

  const { courtCaseId } = useQueryParams();
  const messagesInChats = Object.values(chats);
  const orderedChats = useMemo(
    () => messagesInChats.sort((a, b) => b.updatedAt - a.updatedAt),
    [messagesInChats.length]
  );
  const isResponseLoading = new ResponseLoading(responseLoading).isLoading(currentChat.id);

  const createNewChat = useCallback(() => {
    const newChat = new Chat();
    setPrompt('');
    setCurrentChat(newChat);
    setChats({ ...chats, [newChat.id]: newChat });
  }, [chats]);

  const openChat = useCallback(
    (chatId) => {
      setCurrentChat(chats[chatId]);
      setPrompt('');
    },
    [chats]
  );

  const sendMessage = useCallback(
    async (text) => {
      const newMessage = new ChatMessage({ text });
      currentChat.push(newMessage);
      setResponseLoading((prevResponseLoading) => new ResponseLoading(prevResponseLoading).add(currentChat.id));

      try {
        const { aiMessage } = await sendPostRequest(routes.messages, {
          courtCaseId,
          message: newMessage.text,
          chatId: currentChat.id,
          id: newMessage.id,
          filter: {
            fileIds: selectedPathObjs.filter((pathObj) => pathObj.isFile).map((pathObj) => pathObj.id),
            folderNames: selectedPathObjs.filter((pathObj) => pathObj.isFolder).map((pathObj) => pathObj.name),
          },
        });
        currentChat.push(new ChatMessage({ text: aiMessage.message, isUser: false }));
      } catch (err) {
        currentChat.push(
          new ChatMessage({
            text: 'We had some issues processing your chat message, please try again or contact support.',
            isUser: false,
          })
        );
      } finally {
        setResponseLoading((prevResponseLoading) => new ResponseLoading(prevResponseLoading).remove(currentChat.id));
      }
    },
    [currentChat, selectedPathObjs]
  );

  useEffect(() => {
    const fetchChats = async () => {
      const { chats } = await sendGetRequest(routes.messages, { courtCaseId });

      const fetchedChats = {};
      for (const [chatId, rawMessages] of Object.entries(chats)) {
        const messages = rawMessages.map(ChatMessage.fromRawMsg);

        fetchedChats[chatId] = new Chat(messages, chatId);
      }

      setChats({ ...fetchedChats, [currentChat.id]: currentChat });
    };

    fetchChats();
  }, []);

  const value = useMemo(
    () => ({
      prompt,
      setPrompt,
      openChat,
      createNewChat,
      currentChat,
      sendMessage,
      isResponseLoading,
      chats: orderedChats,
    }),
    [prompt, chats, isResponseLoading, currentChat]
  );

  return <AIContext.Provider value={value}>{children}</AIContext.Provider>;
};
