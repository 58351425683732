import './index.scss';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { CheckBox } from '../../../checkBox';
import { Col } from 'react-bootstrap';

const AiChatHelp = ({ toggleModalVisibility }) => {
  const [isChecked, setIsChecked] = useState(JSON.parse(localStorage.getItem('aiChatHelpModal')));
  return (
    <Col className="upload-roadblock standard-modal">
      <iframe
        className="aiChatHelp__video"
        src="https://www.youtube.com/embed/gW_8mZXu5dY?si=wP-xi0gJYk2Lx9F5"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen="true"
      ></iframe>

      <br />
      <sub className="mb-5">Alia supports the PDF, XPS, EPUB, MOBI, FB2, CBZ, SVG, and TXT file formats.</sub>

      <CheckBox
        isChecked={isChecked}
        onChange={() => {
          setIsChecked(!isChecked);
          return localStorage.setItem('aiChatHelpModal', !isChecked);
        }}
        className="aiChatHelp__help_checkbox"
        title="Never show this again"
        name="Never show this again"
        label="Never show this again"
      />

      <div className="modal-buttons__container aiChatHelp__buttons_container">
        <Button variant="primary" onClick={toggleModalVisibility}>
          OK
        </Button>
      </div>
    </Col>
  );
};

export default AiChatHelp;
