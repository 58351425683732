import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// images
import handpointerIcon from '../../assets/handpointer.png';
import shieldIcon from '../../assets/shield.png';
import magnifyingGlassIcon from '../../assets/magnifyingglass.png';
import toolkitIcon from '../../assets/toolkit.png';
import whiteCheck from '../../assets/whiteCheck.png';
import addRecipient from '../../assets/add recipient.png';

// constants
import { freeTrialDays } from '../../utils/constants';

export const GettingStarted = () => {
  return (
    <Col lg={6}>
      <div className="registration__getting_started">
        <h3>Getting started with LitX</h3>
        <div className="registration__welcome_video">
          <iframe
            src="https://www.youtube.com/embed/L3UByXbPEgA?si=kZJOztamJ9mMDtnJ"
            title="YouTube video player"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>

        {/* Benefits Section */}
        <Row style={{ paddingTop: '30px' }}>
          <Col md={6}>
            <div className="icon-container">
              <img src={handpointerIcon} alt="Hand Pointer" className="icon-image" />
              <p className="text-paragraph">
                An easy-to-use, web-based platform that offers verifiable service of documents.
              </p>
            </div>
          </Col>
          <Col md={6}>
            <div className="icon-container">
              <img src={shieldIcon} alt="Shield" className="icon-image shield-nudge" />
              <p className="text-paragraph">Secure tools that help you to avoid phishing and malware scams.</p>
            </div>
          </Col>
          <Col md={6}>
            <div className="icon-container">
              <img src={magnifyingGlassIcon} alt="Magnifying Glass" className="icon-image" />
              <p className="text-paragraph">
                A verifiable record of what files have been exchanged, when, and by whom.
              </p>
            </div>
          </Col>
          <Col md={6}>
            <div className="icon-container">
              <img src={addRecipient} alt="Add Recipient" className="icon-image add-recipient" />
              <p className="text-paragraph">Easily invite members of your own and opposing council to cases.</p>
            </div>
          </Col>
          <Col md={6}>
            <div className="icon-container">
              <img src={toolkitIcon} alt="Toolkit" className="icon-image tool-kit-nudge" />
              <p className="text-paragraph">
                Powerful litigation document exchange platform saves time using alternative methods of service.
              </p>
            </div>
          </Col>
          <Col md={6}>
            <div className="icon-container">
              <img src={whiteCheck} alt="White Check" className="icon-image" />
              <p className="text-paragraph">Try LitX with a {freeTrialDays} day free trial, no credit card required.</p>
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  );
};
