import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Select } from '../../../customSelect';
import { TextField } from '../../../textField';
import { caseType, partyName } from '../../../../utils/enums';
import { Button } from '../../../button';
import { addParty } from '../../../../utils/api';
import { fireErrorToast } from '../../../../utils/alert';
import { isEmptyLabel } from '../../../../utils/string';

const AddNewParty = ({ toggleModalVisibility, caseDetails, getCaseDetails }) => {
  const [partyData, setPartyData] = useState({ courtCaseId: caseDetails.id, type: '', partyName: '', note: '' });
  const [isLoading, setIsLoading] = useState(false);

  const handleAddPartyClick = async () => {
    setIsLoading(true);

    try {
      await addParty(partyData);
      await getCaseDetails();
      toggleModalVisibility();
    } catch (err) {
      fireErrorToast('There was a problem creating the party. Please try again or contact support.');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePartyNameChange = (newPartyName) => {
    const existingPartyNames = (caseDetails?.caseParties || []).map((party) => party?.partyName);
    if (existingPartyNames.includes(newPartyName)) return;

    setPartyData({ ...partyData, partyName: newPartyName });
  };

  return (
    <Row>
      <Col xs={12}>
        <div className="invite-to-case-form standard-modal">
          <Form>
            <Row className="mb-3">
              <Select
                label="The new party will be representing"
                placeholder="Please Select"
                options={[
                  { value: caseType.defendant, name: partyName.defendant },
                  { value: caseType.plaintiff, name: partyName.plaintiff },
                  { value: caseType.neutral, name: partyName.neutral },
                ]}
                value={partyData.type}
                onChange={(type) => setPartyData({ ...partyData, type })}
              />
            </Row>

            <Row className="mb-4">
              <TextField label="Party Name" value={partyData.partyName} onChange={handlePartyNameChange} />
            </Row>

            <Row className="mb-4">
              <Form.Group as={Col} controlId="note">
                <Form.Label>Include a note to other party admins (optional)</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  name="note"
                  onChange={(e) => setPartyData({ ...partyData, note: e.target.value })}
                />
              </Form.Group>
            </Row>

            <Row className="modal-buttons__container">
              <Button label="Cancel" onClick={toggleModalVisibility} />
              <Button
                label="Add New Party"
                onClick={handleAddPartyClick}
                isDisabled={isEmptyLabel(partyData.partyName) || !partyData.type}
                loading={isLoading}
              />
            </Row>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default AddNewParty;
