import React from 'react';
import BaseModal from '../../base_modal';
import { EditProfile } from './editProfile';

export const EditProfileModal = ({ isVisible, toggleModalVisibility, headerCopy, currentModalField }) => (
  <BaseModal
    isVisible={isVisible}
    toggleVisibility={toggleModalVisibility}
    modalContent={<EditProfile toggleModalVisibility={toggleModalVisibility} currentModalField={currentModalField} />}
    headerCopy={headerCopy}
    wide={false}
  />
);
