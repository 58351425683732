import { useContext } from 'react';
import { AIContext } from '../../../contexts/aiContext';
import { Message } from './message';
import { ChatMessage } from './models';

export const Chat = () => {
  const { currentChat, isResponseLoading } = useContext(AIContext);

  const renderIsResponseLoading = () => {
    if (!isResponseLoading) return;

    return <Message message={new ChatMessage({ text: '', isUser: false })} isLoading />;
  };

  return (
    <div className="ai-chat__messages_container">
      {currentChat.messages.map((message) => (
        <Message key={message.id} message={message} />
      ))}

      {renderIsResponseLoading()}
    </div>
  );
};
