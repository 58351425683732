import React from 'react';

export const PasswordRequirements = () => (
  <>
    <p>Password must contain:</p>
    <ul>
      <li>8 characters minimum</li>
      <li>1 capital letter</li>
      <li>1 number</li>
      <li>1 special characters</li>
    </ul>
  </>
);
