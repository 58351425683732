export const hostName = window.location.hostname;

export const localhost = 'localhost';
export const devEnv = 'dev-litx-frontend.litx.legal';
export const stgEnv = 'stg-litx-frontend.litx.legal';
export const prodEnv = 'site.app.litx.legal';

export const isLocalhost = () => hostName === localhost;
export const isDev = () => hostName === devEnv;
export const isStg = () => hostName === stgEnv;
export const isProd = () => hostName === prodEnv;

const endpoints = {
  [localhost]: '',
  [devEnv]: 'https://dev-litx-api.litx.legal',
  [stgEnv]: 'https://stg-litx-api.litx.legal',
  [prodEnv]: 'https://api.app.litx.legal',
};

export const apiEndpoint = endpoints[hostName];

const stripeKeys = {
  [localhost]:
    'pk_test_51LcbK2IvOl0GjMkiPyr2KtERy0pEyISxhS6o8zg4wKh9g03HFU5YZXR5mQyxQ2BSxWTw0DjiRpNsolmYX3ayMmeA00hDhXMnH2',
  [devEnv]:
    'pk_test_51LcbK2IvOl0GjMkiPyr2KtERy0pEyISxhS6o8zg4wKh9g03HFU5YZXR5mQyxQ2BSxWTw0DjiRpNsolmYX3ayMmeA00hDhXMnH2',
  [stgEnv]:
    'pk_test_51MsqUAL2hS9vFRo7YEXrYdtoak9Y3SvymGCLR2YJa33mgrCjgi1F12Qhjm346MonO2vdDH7w4RbKOiW821j4cdZ000VfP4PLpU',
  [prodEnv]:
    'pk_live_51MsqUAL2hS9vFRo7znqrmzu9Vm3EwW6mjtJUhVU9RZzu2DOKU7o5cVjqLpMOF0SocCkOJznU0tTtxa3kuGsSN9g700ZB1sg1q3',
};

export const stripeKey = stripeKeys[hostName];
