import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { inputHandler } from '../../../../utils/handlers';
import { caseRoles, caseTypes } from '../../../../utils/defaults';
import { fireErrorToast, fireSuccessToast } from '../../../../utils/alert';
import { submitEditCase } from '../../../../utils/api';

const EditCase = ({ getCaseDetails, caseDetails, toggleModalVisibility }) => {
  const [caseData, setCaseData] = useState({
    caseName: caseDetails?.caseName,
    courtName: caseDetails?.courtName,
    causeNo: caseDetails?.causeNo,
    caseType: caseDetails.caseType,
    caseRole: caseDetails.myParty.caseRole,
    caseId: caseDetails.id,
    partyRepId: caseDetails.myParty.partyRepId,
  });

  const updateCase = async () => {
    try {
      await submitEditCase(caseData);
      await getCaseDetails();
      fireSuccessToast('Case updated successfully');
      toggleModalVisibility('');
    } catch (error) {
      fireErrorToast('Error updating case');
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <div className="invite-to-case-form standard-modal">
          <Form>
            <Row className="mb-4">
              <Form.Group as={Col} controlId="caseName">
                <Form.Label>Name of Case</Form.Label>
                <Form.Control
                  type="text"
                  name="caseName"
                  defaultValue={caseDetails?.caseName}
                  onChange={(e) => inputHandler(e, setCaseData)}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="courtName">
                <Form.Label>Name of Court</Form.Label>
                <Form.Control
                  type="text"
                  name="courtName"
                  defaultValue={caseDetails?.courtName}
                  onChange={(e) => inputHandler(e, setCaseData)}
                />
              </Form.Group>
            </Row>

            <Row className="mb-4">
              <Form.Group as={Col} controlId="causeNo">
                <Form.Label>Cause Number</Form.Label>
                <Form.Control
                  type="text"
                  name="causeNo"
                  defaultValue={caseDetails?.causeNo}
                  onChange={(e) => inputHandler(e, setCaseData)}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="caseType">
                <Form.Label>Type of Case (Optional)</Form.Label>
                <Form.Select name="caseType" onChange={(e) => inputHandler(e, setCaseData)}>
                  <option value={caseDetails.caseType}>{caseDetails.caseType}</option>
                  {caseTypes
                    .filter((type) => type !== caseDetails.caseType)
                    .map((type) => (
                      <option value={type}>{type}</option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Row>

            <Row className="mb-4">
              <Form.Group as={Col} md={6} className="mb-3" controlId="caseRole">
                <Form.Label className="required-input">My role is</Form.Label>
                <Form.Select name="caseRole" onChange={(e) => inputHandler(e, setCaseData)}>
                  <option value={caseDetails.myParty.caseRole}>{caseDetails.myParty.caseRole}</option>
                  {caseRoles
                    .filter((role) => role !== caseDetails.myParty.caseRole)
                    .map((role) => (
                      <option value={role}>{role}</option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Row>

            <Row className="modal-buttons__container">
              <Button variant="light" onClick={() => toggleModalVisibility('')}>
                Cancel
              </Button>

              <Button variant="primary" onClick={() => updateCase()}>
                Save Changes
              </Button>
            </Row>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default EditCase;
