export const isPasswordValid = (password = '') => {
  const isLong = password.length >= 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasSpecialCharacter = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password);
  const hasNumber = /[0-9]/.test(password);

  return isLong && hasUpperCase && hasLowerCase && hasSpecialCharacter && hasNumber;
};

export const isEmptyLabel = (text = '') => !text || Array.from(text).every((char) => char === ' ');

export const capitalizeWord = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const formatCamelCaseToString = (field) => {
  return field
    .replace(/([A-Z])/g, ' $1')
    .split(' ')
    .map(capitalizeWord)
    .join(' ')
    .trim();
};

export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = `${phoneNumber}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  return match ? `+1 (${match[1]}) ${match[2]}-${match[3]}` : null;
};
