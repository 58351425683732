import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useNavigate, Link } from 'react-router-dom';
import { Button } from '../../components/button';
import { EmailField } from '../../components/emailField';
import { fireErrorToast, fireSuccessToast } from '../../utils/alert';
import { createForgotPassword } from '../../utils/api';

const ForgotPassword = () => {
  const [emailAddress, setEmailAddress] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const isResetPasswordButtonEnabled = !loading && emailAddress;

  const handleResetPassword = async () => {
    setLoading(true);

    try {
      await createForgotPassword({ emailAddress });
      navigate('/login');
      fireSuccessToast('Verification link sent successfully.');
    } catch (err) {
      fireErrorToast('There seems to be a problem with that email. Please try again or contact support.');
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key !== 'Enter') return;

    e.preventDefault();
    handleResetPassword();
  };

  return (
    <Row>
      <Col xs={12} md={10}>
        <div className="login-form">
          <div className="login-form__title mb-3">Reset password</div>
          <Form className="mb-5" validated={false}>
            <EmailField
              className="mb-3"
              value={emailAddress}
              onChange={setEmailAddress}
              label={'Email address'}
              onKeyDown={handleKeyDown}
            />

            <div className="mt-4 mb-4">We'll email you a verification link, so you can create a new password.</div>
            <Button
              onClick={handleResetPassword}
              className="w-100"
              color="primary"
              label="Continue"
              loading={loading}
              isDisabled={!isResetPasswordButtonEnabled}
            />
          </Form>
          <div className="login-form__assistance-links">
            Back to&nbsp;&nbsp;
            <Link to="/login" className="password-reset-link">
              Sign in
            </Link>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ForgotPassword;
