import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { submitResendCaseInvite } from '../../../utils/api';
import { fireSuccessToast, fireErrorToast } from '../../../utils/alert';
import { Button } from '../../button';

const ResendInvite = ({ toggleModalVisibility, inviteId, invitee }) => {
  const [loading, setLoading] = useState(false);

  const resendInvite = async () => {
    setLoading(true);
    try {
      await submitResendCaseInvite({ inviteId });
      fireSuccessToast('Invite resent');
      toggleModalVisibility('');
    } catch (err) {
      fireErrorToast('Error resending invite');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <div className="invite-to-case-form standard-modal">
          <Form>
            <div>
              An invite already exists for {`${invitee.name} (${invitee.emailAddress})`}, would you like to resend the
              invite?
            </div>

            <Row className="modal-buttons__container">
              <Button onClick={() => toggleModalVisibility('')} color="light" label="Cancel" />
              <Button color="primary" loading={loading} onClick={resendInvite} label="Send Invite" />
            </Row>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default ResendInvite;
