import React, { useMemo, useState, useRef, useEffect } from 'react';
import { useTable, useSortBy } from 'react-table';
import { SortableTableHeaders } from '../sortableTableHeaders';
import { FileTableRows } from './filetableRows';
import { formatFileTableColumns } from './formatFileTableColumns';
import { FilesConsumer } from '../../contexts/filesContext';
import { RowContextMenu } from './rowContextMenu';

const UploadedFilesTable = ({
  toggleClawbackRequestModal,
  toggleMoveModal,
  toggleRenameModal,
  onClawback,
  onDoubleClick,
  onClick,
  onDownload,
  onReceiptDownload,
  toggleRemoveEmptyFolderModal,
  forwardRef,
}) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [selectedPathObj, setSelectedPathObj] = useState(undefined);
  const contextMenuRef = useRef(null);

  const { filteredUploadedFiles, pathManager, selectedPathObjs, clearSelectedPathObjs } = FilesConsumer();

  useEffect(() => {
    const showContextMenu = (event) => {
      const tableDataTagName = 'TD';
      if (event.target.tagName !== tableDataTagName) return;
      event.preventDefault();
      setMousePosition({ x: event.clientX, y: event.clientY });
      setShowContextMenu(true);
    };

    const hideContextMenu = (event) => {
      if (contextMenuRef.current && contextMenuRef.current.contains(event.target)) return;
      setShowContextMenu(false);
    };

    document.addEventListener('contextmenu', showContextMenu);
    document.addEventListener('click', hideContextMenu);

    return () => {
      document.removeEventListener('contextmenu', showContextMenu);
      document.removeEventListener('click', hideContextMenu);
    };
  }, []);

  const fileTableColumns = useMemo(() => formatFileTableColumns(), [pathManager]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: fileTableColumns,
      data: filteredUploadedFiles,
      initialState: { sortBy: [{ id: 'createdAt', desc: true }] },
    },
    useSortBy
  );

  const renderDropDownMenu = (selectedPathObj) => {
    if (!selectedPathObj) return null;

    const download = {
      title: 'Download',
      action: () => onDownload(selectedPathObj),
    };

    const requestClawback = {
      title: 'Request Clawback',
      action: () => {
        onClawback(selectedPathObj);
        clearSelectedPathObjs();
        toggleClawbackRequestModal();
      },
    };

    const move = {
      title: 'Move',
      action: () => toggleMoveModal(selectedPathObj),
    };

    const rename = {
      title: 'Rename',
      action: () => toggleRenameModal(selectedPathObj),
    };

    const downloadServeReceipt = {
      title: 'Service Receipt',
      action: () => onReceiptDownload(selectedPathObj.id),
    };

    const items = [download, requestClawback, move, rename, downloadServeReceipt];
    if (pathManager.isEmptyFolder(selectedPathObj)) {
      const removeEmptyFolder = {
        title: 'Remove',
        action: () => toggleRemoveEmptyFolderModal(selectedPathObj),
      };

      items.push(removeEmptyFolder);
    }

    return (
      <RowContextMenu
        items={items}
        isShown={showContextMenu}
        mousePosition={mousePosition}
        forwardRef={contextMenuRef}
      />
    );
  };

  const onRightClick = (e, pathObj) => {
    e.preventDefault();
    setSelectedPathObj(pathObj);
    const isSelected = selectedPathObjs.find((selectedPathObj) => selectedPathObj.id === pathObj.id);
    if (isSelected) return setShowContextMenu(true);
    onClick(pathObj);
    return setShowContextMenu(true);
  };

  return (
    <>
      <table ref={forwardRef} id="left-panel" className="filesSection__table" {...getTableProps()}>
        <SortableTableHeaders headerGroups={headerGroups} />
        <FileTableRows
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          rows={rows}
          onClick={onClick}
          onDoubleClick={onDoubleClick}
          setShowContextMenu={setShowContextMenu}
          setSelectedPathObj={setSelectedPathObj}
          onRightClick={onRightClick}
        />
      </table>
      {renderDropDownMenu(selectedPathObj)}
    </>
  );
};

export default UploadedFilesTable;
