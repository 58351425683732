import React from 'react';
import './index.scss';
import { Dropdown } from 'react-bootstrap';

export const RowContextMenu = ({ items, mousePosition, isShown, ref }) => (
  <Dropdown
    show={isShown}
    ref={ref}
    style={{ position: 'fixed', top: mousePosition.y, left: mousePosition.x, zIndex: 1000 }}
  >
    <Dropdown.Menu show={isShown}>
      {items?.map((item) => (
        <Dropdown.Item key={item.title} onClick={item.action}>
          {item.title}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);
