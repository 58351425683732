import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { AuthContextConsumer } from '../../../../contexts/authContext';
import { updateProfile } from '../../../../utils/api';
import { TextField } from '../../../textField';
import { EmailField } from '../../../emailField';
import { CancelOrAcceptCluster } from '../../../cancelOrAcceptCluster';
import { fireErrorToast, fireSuccessToast } from '../../../../utils/alert';

export const EditEmailAddress = ({ toggleModalVisibility }) => {
  const { logout, userProfile } = AuthContextConsumer();

  const [isLoading, setIsLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState(userProfile.emailAddress);
  const [password, setPassword] = useState('');

  const submitChangeEmail = async () => {
    if (!password) return fireErrorToast('Please input your password to validate change.');
    setIsLoading(true);
    try {
      await updateProfile({ emailAddress, password });
      fireSuccessToast(`Email address updated successfully. Please login with your new email address.`);
      await logout();
    } catch (err) {
      if (err.status === 409)
        return fireErrorToast(
          'To change your email address you must input an email address that is not already in use.'
        );
      fireErrorToast('Something went wrong. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Row>
      <div className="invite-to-case-form standard-modal">
        <Form>
          <EmailField
            classnames={{ input: 'mb-4', label: 'margin-top' }}
            value={emailAddress}
            onChange={setEmailAddress}
            label={'Input your new email address'}
          />
          <TextField
            id="password"
            value={password}
            onChange={setPassword}
            label={'To validate change input password'}
            isPassword={true}
          />
          <CancelOrAcceptCluster
            onCancelClick={toggleModalVisibility}
            onAcceptClick={submitChangeEmail}
            acceptLabel="Save"
            isLoading={isLoading}
          />
        </Form>
      </div>
    </Row>
  );
};
