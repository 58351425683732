import React, { Suspense } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Loading from './Loading';
import { useEffect } from 'react';
import { useState } from 'react';
import { AuthContextConsumer } from '../contexts/authContext';

const ProtectedRoute = () => {
  const [isAuth, setisAuth] = useState(window.localStorage.getItem('litx-auth'));
  const location = useLocation();

  const authContext = AuthContextConsumer();
  const { fetchedUserInfo } = authContext;

  useEffect(() => {
    if (fetchedUserInfo) {
      const auth = window.localStorage.getItem('litx-auth');
      setisAuth(auth);
    }
  }, [location.pathname, fetchedUserInfo]);

  return isAuth ? (
    <Suspense fallback={<Loading />}>
      <Outlet />
    </Suspense>
  ) : (
    <Navigate to="/login" state={{ redirectTo: location }} />
  );
};

export default ProtectedRoute;
