import React, { useState } from 'react';

export const useModal = (modalId) => {
  const [openModalId, updateOpenModal] = useState(modalId);

  const toggleModalVisibility = async (newModalId) => {
    const clearModals = await updateOpenModal('');
    updateOpenModal(newModalId);
  };

  return [openModalId, toggleModalVisibility];
};
