import './requests.scss';

import React from 'react';
import Col from 'react-bootstrap/Col';
import { CaseInvite } from './requestsComponents/caseInvite';
import { CloseCase } from './requestsComponents/closeCase';
import { ShowTotalRequests } from './requestsComponents/showTotalRequests';
import { ClawbackRequests } from './requestsComponents/clawbackRequests';
import { AccountMergeInvites } from './requestsComponents/accoutMergeInvites';

const RequestsDropdown = ({ toggleModalVisibility, isVisible }) => {
  if (!isVisible) return null;

  return (
    <>
      <div className="overlay" onClick={toggleModalVisibility}></div>
      <div className="requests-dropdown">
        <div className="requests-dropdown__header">
          <div className="requests-title">Requests</div>
          <span className="requests-close pointer" onClick={toggleModalVisibility}>
            &times;
          </span>
        </div>
        <div>
          <Col xs={12} md={4}>
            <CaseInvite />
            <CloseCase />
            <ClawbackRequests />
            <ShowTotalRequests />
            <AccountMergeInvites />
          </Col>
        </div>
      </div>
    </>
  );
};

export default RequestsDropdown;
