import './index.scss';
import Logo from '../logo.svg';
import GreenPartialLogo from '../assets/litx_partial--green.svg';
import React from 'react';

const OnboardingLayout = ({ children }) => {
  return (
    <div className="onboarding-layout">
      <div>
        <a href="/">
          <img className="onboarding-layout-logo" src={Logo} alt="" />
        </a>
      </div>
      {children}
      <div className="branding-image">
        <a href="/">
          <img src={GreenPartialLogo} alt="" />
        </a>
      </div>
    </div>
  );
};

export default OnboardingLayout;
