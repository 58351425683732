import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { fireErrorToast, fireSuccessToast } from '../../../../utils/alert';
import { Button } from '../../../../components/button';
import { AuthContextConsumer } from '../../../../contexts/authContext';
import { removeUser } from '../../../../utils/api';

export const RemoveUser = ({ toggleModalVisibility, selectedUser }) => {
  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const { retreiveAccountUsers } = AuthContextConsumer();

  const submitRemoveUser = async () => {
    if (!confirmed) return fireErrorToast("Please confirm you'd like to remove this user");

    setLoading(true);
    try {
      await removeUser({ userId: selectedUser.id });
      await retreiveAccountUsers();
      fireSuccessToast('Successfully removed user from account');
      toggleModalVisibility();
    } catch (err) {
      if (err.status === 401) return fireErrorToast('You are not authorized to remove this user');
      fireErrorToast();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <div className="add-folder-form standard-modal">
          <Form>
            <Row className="mb-3">
              <span className="remove-user-modal-text">
                Are you sure you want to remove {selectedUser.name} from your account? The user will no longer be able
                to access their cases until a new subscription is established.
              </span>
            </Row>

            <Row className="mb-4">
              <Form.Group className="admin-checkbox mb-2">
                <Form.Check
                  type="checkbox"
                  label="Yes, remove user from my billing account"
                  name="confirmation"
                  checked={confirmed}
                  onChange={(event) => setConfirmed(event.target.checked)}
                />
              </Form.Group>
            </Row>

            <Row className="modal-buttons__container">
              <Button color="light" onClick={toggleModalVisibility} label="Cancel" />
              <Button color="primary" onClick={submitRemoveUser} label="Remove Account" loading={loading} />
            </Row>
          </Form>
        </div>
      </Col>
    </Row>
  );
};
