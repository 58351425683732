import './index.scss';
import React from 'react';

const BaseModal = ({ isVisible, toggleVisibility, modalContent, headerCopy, wide, max }) => {
  const modal = (
    <>
      <div className="backdrop" onClick={toggleVisibility} />
      <div
        className={`base-modal ${max ? 'max' : ''} ${wide ? 'wide' : ''}`}
        aria-modal
        aria-label="Modal Details"
        role="dialog"
      >
        <span className="modal-close" aria-label="Close Modal Details" onClick={toggleVisibility}>
          &times;
        </span>

        <h1 className={'modal-header mb-4'}>{headerCopy}</h1>
        {modalContent}
      </div>
    </>
  );

  return isVisible && modal;
};

export default BaseModal;
