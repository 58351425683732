import './index.scss';

import React from 'react';
import InvitedRegistrationForm from './invitedRegistrationForm';

const InvitedRegistration = () => {
  return (
    <div className="invited-registration">
      <InvitedRegistrationForm />
    </div>
  );
};

export default InvitedRegistration;
