import { CasesContextConsumer } from '../../../contexts/casesContext';

export const ShowTotalRequests = () => {
  const { totalRequests } = CasesContextConsumer();
  return (
    totalRequests === 0 && (
      <div className="requests-dropdown__card p-3 d-flex justify-content-center">
        <span className="">Zero pending requests</span>
      </div>
    )
  );
};
