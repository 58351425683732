import React from 'react';
import { FilesConsumer } from '../../contexts/filesContext';
import { useTouchHold } from '../../hooks/useTouchHold';

export const FileTableRows = ({ getTableBodyProps, prepareRow, rows, onClick, onDoubleClick, onRightClick }) => {
  const { selectedPathObjs } = FilesConsumer();
  const { onTouchStart, onTouchEnd } = useTouchHold();

  return (
    <tbody {...getTableBodyProps()}>
      {rows.map((row) => {
        prepareRow(row);

        return (
          <tr {...row.getRowProps()}>
            {row.cells.map((cell) => {
              const pathObj = cell.row.original;
              const isSelected = selectedPathObjs.find((selectedPathObj) => selectedPathObj.id === pathObj.id);

              return (
                <td
                  onClick={() => onClick(pathObj)}
                  onDoubleClick={() => onDoubleClick(pathObj)}
                  onTouchStart={(e) => onTouchStart(e, () => onDoubleClick(pathObj))}
                  onTouchEnd={onTouchEnd}
                  onContextMenu={(e) => onRightClick(e, pathObj)}
                  className={`filesSection__file-table-cell ${
                    isSelected ? 'filesSection__file-table-cell-selected' : ''
                  }`}
                  {...cell.getCellProps()}
                >
                  {cell.render('Cell')}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};
