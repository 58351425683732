import React from 'react';
import BaseButton from 'react-bootstrap/Button';
import { Oval } from 'react-loader-spinner';

export const variant = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const Button = ({ label, loading, color = variant.PRIMARY, onClick, className, isDisabled }) => (
  <BaseButton
    className={className}
    style={{ padding: loading ? 0 : '10px 20px' }}
    variant={color}
    disabled={isDisabled || loading}
    onClick={() => onClick()}
  >
    {loading ? (
      <div className="d-flex justify-content-center align-items-center">
        <Oval
          height={30}
          width={40}
          color="#DFE4EF"
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#00AFAA"
          strokeWidth={4}
          strokeWidthSecondary={4}
        />
        <span className="mx-2">Loading</span>
      </div>
    ) : (
      label
    )}
  </BaseButton>
);

export default Button;
