import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { fireErrorToast, fireSuccessToast } from '../../../../utils/alert';
import { PasswordRequirements } from '../../../../components/passwordRequirements';
import { submitUpdatePassword } from '../../../../utils/api';
import { CancelOrAcceptCluster } from '../../../cancelOrAcceptCluster';
import { TextField } from '../../../textField';
import { AuthContextConsumer } from '../../../../contexts/authContext';

export const ChangePassword = ({ toggleModalVisibility }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const { logout } = AuthContextConsumer();

  const isPasswordValid = (password) => {
    if (password.length < 8) return fireErrorToast('Password must be at least 8 characters');
    if (!password.match(/[A-Z]/)) return fireErrorToast('Password must contain at least one capital letter');
    if (!password.match(/[0-9]/)) return fireErrorToast('Password must contain at least one number');
    if (!password.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/))
      return fireErrorToast('Password must contain at least one special character');

    return true;
  };

  const updatePassword = async () => {
    if (!isPasswordValid(newPassword) || !isPasswordValid(confirmNewPassword)) return;
    if (newPassword !== confirmNewPassword) return fireErrorToast('Passwords do not match');

    setIsLoading(true);
    try {
      await submitUpdatePassword({ currentPassword, newPassword });
      fireSuccessToast('Password updated successfully. Please log in again to complete password update.');
      toggleModalVisibility();
      logout();
    } catch (error) {
      if (error.status === 401) return fireErrorToast('Current password is incorrect');
      if (error.status === 409) return fireErrorToast('New password cannot be the same as the current password');
      fireErrorToast();
    } finally {
      setIsLoading(false);
    }
  };

  const isAcceptButtonDisabled = currentPassword && newPassword && confirmNewPassword;

  return (
    <Row>
      <Col xs={12}>
        <div className="invite-to-case-form standard-modal">
          <Form>
            <TextField
              id="currentPassword"
              label="Current Password"
              value={currentPassword}
              onChange={setCurrentPassword}
              classnames={{ label: 'pass-label', input: 'pass-input' }}
              isPassword
              required
            />

            <TextField
              id="newPassword"
              label="New Password"
              value={newPassword}
              onChange={setNewPassword}
              isPassword
              required
            />

            <TextField
              id="confirmNewPassword"
              label="Confirm New Password"
              value={confirmNewPassword}
              onChange={setConfirmNewPassword}
              isPassword
              required
            />

            <PasswordRequirements />

            <CancelOrAcceptCluster
              onCancelClick={toggleModalVisibility}
              onAcceptClick={updatePassword}
              acceptLabel="Update Password"
              isDisabled={!isAcceptButtonDisabled}
              isLoading={isLoading}
            />
          </Form>
        </div>
      </Col>
    </Row>
  );
};
