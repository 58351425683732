import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { inputHandler } from '../../../../utils/handlers';
import { Button } from '../../../button';
import casesIcon from '../../../../assets/casesIconBlack.png';
import { fireErrorToast, fireSuccessToast } from '../../../../utils/alert';
import { closeCaseHandler } from '../../../../utils/api';

export const CloseCase = ({ caseDetails, caseAdmins, toggleModalVisibility }) => {
  const [closeData, setCloseData] = useState({
    note: '',
    caseId: caseDetails.id,
    requestingPartyName: caseDetails.myParty.partyName,
    requestingPartyId: caseDetails.myParty.partyId,
  });
  const [loading, setLoading] = useState(false);

  const closeCase = async () => {
    setLoading(true);
    try {
      const closeResult = await closeCaseHandler(closeData);
      fireSuccessToast(closeResult.message);
      toggleModalVisibility('');
    } catch (error) {
      if (error.message === 'A case closure request already exists.')
        fireErrorToast('Close case request already exists.');
      else fireErrorToast('Close case request failed.');
      toggleModalVisibility('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <div className="invite-to-case-form standard-modal">
          <Form>
            <Row>
              <div className="close-case-name">
                <img src={casesIcon} className="close-case-icon" /> {caseDetails.caseName}
              </div>
              <div>
                A case admin from each party must agree to close this case. A Close Case Request will be sent to the
                following parties:
                <span className="case-admins">{caseAdmins.map((admin) => admin)}</span>
              </div>
            </Row>

            <Row className="mb-5">
              <Form.Group as={Col} controlId="note">
                <Form.Label>Personal note for the close case email (Optional)</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  name="note"
                  onChange={(event) => inputHandler(event, setCloseData)}
                />
              </Form.Group>
            </Row>

            <Row className="modal-buttons__container">
              <Button onClick={() => toggleModalVisibility('')} color="light" label="Cancel" />
              <Button color="primary" loading={loading} onClick={closeCase} label="Request Close Case" />
            </Row>
          </Form>
        </div>
      </Col>
    </Row>
  );
};
