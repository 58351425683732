import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

export const TextField = ({
  id,
  value,
  label,
  placeholder,
  isDisabled = false,
  onChange,
  rawOnChange,
  onKeyDown,
  classnames = { label: '', input: '' },
  isPassword = false,
  ...containerProps
}) => {
  const handleChange = (event) => {
    if (rawOnChange) rawOnChange(event);
    if (onChange) onChange(event.target.value);
  };

  return (
    <Form.Group as={Col} {...containerProps}>
      <Form.Label className={classnames.label} htmlFor={id}>
        {label}
      </Form.Label>
      <Form.Control
        id={id}
        className={classnames.input}
        disabled={isDisabled}
        type={isPassword ? 'password' : 'text'}
        value={value}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
      ></Form.Control>
    </Form.Group>
  );
};
