import './index.scss';

import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { ChangePasswordModal } from '../../../components/modals/modal_content/changePassword/modal';
import { AuthContextConsumer } from '../../../contexts/authContext';
import { twoFactorPreference } from '../../../utils/constants';
import { AddPhoneModal } from '../../../components/modals/modal_content/addPhone/modal';

const SecuritySettings = () => {
  const [displayPasswordChangeModal, setDisplayPasswordChangeModal] = useState();
  const [displayAddPhoneModal, setDisplayAddPhoneModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { userProfile, handle2FAChange } = AuthContextConsumer();

  const toggleChangePasswordModal = () => setDisplayPasswordChangeModal(!displayPasswordChangeModal);
  const toggleDisplayAddPhoneModal = () => setDisplayAddPhoneModal(!displayAddPhoneModal);

  const is2FAEmail = userProfile.twoFactorPreference === twoFactorPreference.email;
  const isPhoneConfigured = !!userProfile.phone;

  const renderModals = () => {
    return (
      <>
        <AddPhoneModal
          isVisible={displayAddPhoneModal}
          toggleModalVisibility={toggleDisplayAddPhoneModal}
          currentModalField="phone"
        />

        <ChangePasswordModal isVisible={displayPasswordChangeModal} toggleModalVisibility={toggleChangePasswordModal} />
      </>
    );
  };

  const renderUseEmail = () => (
    <div
      className="subscription__row fa-method-container"
      onClick={() => handle2FAChange(twoFactorPreference.email, isLoading, setIsLoading)}
    >
      <div className="left-col">Use email</div>
      <div className="profile-middle-col"></div>
      <div className="right-col">
        <Form.Check type="radio" aria-label="text" name="twoStepSelection">
          <Form.Check.Input
            type="radio"
            name="twoStepSelection"
            className="radio-select"
            checked={is2FAEmail}
            onChange={() => {}}
          />
        </Form.Check>
      </div>
    </div>
  );

  const renderUseSMS = () => {
    const handleClick = () => {
      if (!isPhoneConfigured) return toggleDisplayAddPhoneModal();

      handle2FAChange(twoFactorPreference.sms, isLoading, setIsLoading);
    };

    return (
      <div className="subscription__row fa-method-container" onClick={handleClick}>
        <div className={`left-col ${!isPhoneConfigured && 'not-configured'}`}>Use text</div>
        <div className="profile-middle-col profile-middle-col-start">
          {isPhoneConfigured ? '' : 'Add phone number to enable two-step verification via text'}
        </div>
        <div className="right-col">
          <Form.Check type="radio" aria-label="text" name="twoStepSelection">
            <Form.Check.Input
              type="radio"
              name="twoStepSelection"
              className="radio-select"
              checked={!is2FAEmail}
              onChange={() => {}}
            />
          </Form.Check>
        </div>
      </div>
    );
  };

  return (
    <div className="account-settings">
      <div className="subscription col-xs-12 col-lg-6 mb-5">
        <div className="subscription__row">
          <div className="title left-col">Password</div>
          <div className="profile-middle-col"></div>
          <div className="right-col pointer" onClick={toggleChangePasswordModal}>
            Change Password
          </div>
        </div>
      </div>

      <div className="users col-xs-12 col-lg-6 mb-5">
        <div className="users__row">
          <div className="title left-col">Two-step verification</div>
          <div className="title middle-col"></div>
          <div className="title right-col pointer"></div>
        </div>

        {renderUseEmail()}
        {renderUseSMS()}
      </div>

      {renderModals()}
    </div>
  );
};

export default SecuritySettings;
