import './index.scss';

import React from 'react';

export const PathBreadCrumbs = ({ courtCaseName, currentPath, onCurrentPathChange }) => {
  const rawSegments = currentPath.split('/').filter((rawSegment) => rawSegment);
  const pathSegmentsWithCaseName = [courtCaseName, ...rawSegments];

  const pathSegments = pathSegmentsWithCaseName.map((segment, i) => {
    const isFirstSegment = i === 0;
    const isLastSegment = i === pathSegmentsWithCaseName.length - 1;
    const className = isLastSegment ? 'bread-crumb-segment' : 'bread-crumb-segment-button';

    const handleClick = () => {
      if (isLastSegment) return;
      if (isFirstSegment) return onCurrentPathChange('');

      const newCurrentPathIfClicked = rawSegments.slice(0, i).join('/') + '/';
      onCurrentPathChange(newCurrentPathIfClicked);
    };

    const segmentComponent = (
      <span className={className} onClick={handleClick}>
        {segment}
      </span>
    );
    const separatorComponent = <span className="bread-crumb-segment-separator"> / </span>;

    return (
      <span key={`${segment}-${i}`}>
        {segmentComponent}
        {!isLastSegment && separatorComponent}
      </span>
    );
  });

  return <div>{pathSegments}</div>;
};
