import React from 'react';
import Form from 'react-bootstrap/Form';

export const UserAgreementCheckBox = ({ toggleUserAgreement, onChange, checked }) => {
  return (
    <Form.Group className="mb-4">
      <Form.Check type="checkbox">
        <Form.Check.Input type="checkbox" isValid onChange={onChange} checked={checked} />
        <Form.Check.Label>
          <span className="agreement-text">
            I agree to the{' '}
            <span className="agreement-text-link" onClick={toggleUserAgreement}>
              user agreement
            </span>
          </span>
        </Form.Check.Label>
      </Form.Check>
    </Form.Group>
  );
};
