import { useState } from 'react';

export const useTouchHold = (delay = 300) => {
  const [timer, setTimer] = useState(null);

  const handleTouchStart = (e, callback) => {
    e.stopPropagation();
    setTimer(setTimeout(() => callback(), delay));
  };

  const handleTouchEnd = () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  return { onTouchStart: handleTouchStart, onTouchEnd: handleTouchEnd };
};
