import React from 'react';
import BaseModal from '../../base_modal';
import RemovePartyRep from './removePartyRep';

export const RemovePartyRepModal = ({ isVisible, toggleModalVisibility, getCaseDetails, caseDetails, userProfile }) => (
  <BaseModal
    isVisible={isVisible}
    toggleVisibility={toggleModalVisibility}
    modalContent={
      <RemovePartyRep
        getCaseDetails={getCaseDetails}
        toggleModalVisibility={toggleModalVisibility}
        caseDetails={caseDetails}
        userProfile={userProfile}
      />
    }
    headerCopy="Remove a Party Representative"
    wide={true}
  />
);
