import './index.scss';
import LogoImg from '../../logo.svg';

export const Logo = () => (
  <div className="logo-img-container">
    <a href="/">
      <img src={LogoImg} alt="Logo" className="logo-img" />
    </a>
  </div>
);
