import Badge from 'react-bootstrap/Badge';
import dayjs from 'dayjs';
import { ClawbackIcon } from './clawbackIcon';
import { PathObjIcon } from './table/pathObjIcon';

export const formatFileTableColumns = () => {
  const renderName = (props) => {
    const obj = props.row.original;
    const isFolder = !obj.isFile;

    const renderClawbackIcon = () => {
      if (!obj._raw.clawbackRequest) return;
      return <ClawbackIcon clawbackResponses={obj._raw.clawbackRequest.clawbackResponses} />;
    };

    const renderNewBadge = () => {
      if (isFolder || obj.lastViewedBy) return null;

      return <Badge className="new-badge">NEW</Badge>;
    };

    return (
      <>
        {renderClawbackIcon()}
        {<PathObjIcon embeddingStatus={obj.embeddingStatus} isFolder={isFolder} />}
        {obj.name}
        {renderNewBadge()}
      </>
    );
  };

  return [
    {
      Header: 'File Name',
      accessor: 'name',
      Cell: renderName,
    },
    {
      Header: 'Uploaded by',
      accessor: 'uploadedBy',
    },
    {
      Header: 'Added',
      accessor: 'createdAt',
      Cell: (props) => dayjs(props.value).format('MM/DD/YYYY'),
    },
  ];
};
