import { useMemo } from 'react';
import { PathManager } from '../utils/files';

export const usePathManager = (uploadedFiles, currentPath, searchTerm) => {
  const pathManager = useMemo(() => new PathManager(uploadedFiles, currentPath), [uploadedFiles, currentPath]);

  const results = useMemo(() => {
    return searchTerm ? pathManager.search(searchTerm) : pathManager.list();
  }, [pathManager, searchTerm]);

  return { results, pathManager };
};
