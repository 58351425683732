import './index.scss';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Logo from '../../logo.svg';
import welcomeImage from '../../assets/welcome_image.png';

const AuthWrapper = ({ children }) => {
  return (
    <Row xs={12} md={9} lg={12} className="auth__container">
      <Col xs={12} md={7} lg={4} className="login">
        <div className="login__sidebar">
          <div className="mb-4">
            <a href="/">
              <img src={Logo} alt="Logo" />
            </a>
          </div>
          {children}
        </div>
      </Col>
      <Col xs={1} md={4} lg={8} className="content_right">
        <div className="content_right__inner">
          <h1 className="content_right__content-title mb-3">The Modern Litigation Workspace.</h1>
          <img src={welcomeImage} alt="Welcome" />
          <span className="content_right__copyright">© 2023 Litigation Exchange, LLC </span>
        </div>
      </Col>
    </Row>
  );
};

export default AuthWrapper;
