import { QueryClient } from 'react-query';
import { apiEndpoint } from './env';

export const queryClient = new QueryClient();

export const routes = {
  caseInvitation: 'case-invitation',
  user: 'user',
  login: 'user/login',
  profile: 'user/profile',
  twoFactorSend: 'user/2fa/send',
  accountUsers: 'list-users',
  verify2FA: 'verify-code',
  createCase: 'create-case',
  forgotPassword: 'forgot-password',
  accountInvitation: 'pending-account-invites',
  signCaseAgreement: 'sign-case-agreement',
  logOut: 'logout',
  cases: 'cases',
  caseDetails: 'case-details',
  allRequests: 'all-requests',
  joinParty: 'join-party',
  closeCase: 'close-case',
  closeCaseResponse: 'close-case-response',
  declineCase: 'decline-invite',
  subscription: 'subscription',
  accountInvite: 'account/invite',
  leaveAccount: 'user/leaveAccount',
  removeUser: 'remove-user',
  accountMerge: 'account/merge',
  party: 'party',
  casesSearch: 'cases/search',
  resetPassword: 'reset-password',
  invitations: 'invitations',
  verifyEmail: 'verify-email',
  removeParty: 'remove-party',
  leaveCase: 'case/party-rep',
  authLogin: 'login',
  resendCaseInvite: 'resend-case-invite',
  editCase: 'edit-case',
  updatePassword: 'update-password',
  removePartyRep: 'remove-party-rep',

  // files
  signedFileUrl: 'file/signed-url',
  files: 'files',
  folders: 'folders',
  filesMove: 'files/move',
  filesRename: 'files/rename',
  requestClawback: 'request-clawback',
  clawbackResponse: 'clawback-response',

  messages: 'ai/messages',
};

export const sendGetRequest = async (route, queryParams) => {
  let fullRoute = `${apiEndpoint}/${route}`;
  if (queryParams) fullRoute += '?' + new URLSearchParams(queryParams);

  const res = await fetch(fullRoute, { credentials: 'include' });
  if (!res.ok) throw res;

  return res.json();
};

export const sendPostRequest = async (route, body) => {
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  const res = await fetch(`${apiEndpoint}/${route}`, options);
  if (!res.ok) throw res;

  return res.json();
};

const sendPatchRequest = async (route, body) => {
  const options = {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  const res = await fetch(`${apiEndpoint}/${route}`, options);
  if (!res.ok) throw res;

  return res.json();
};

export const sendDeleteRequest = async (route, body) => {
  const options = {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  const res = await fetch(`${apiEndpoint}/${route}`, options);
  if (!res.ok) throw res;

  return res.json();
};

export const downloadFile = async (fileId, { openInBrowser, isReceipt = false }) => {
  const { fileUrl } = await sendGetRequest(routes.signedFileUrl, { fileId, openInBrowser, isReceipt });

  const a = document.createElement('a');
  a.href = fileUrl;
  if (openInBrowser) a.target = '_blank';

  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const inviteUserToCase = async (body) => {
  return sendPostRequest(routes.caseInvitation, body);
};

export const registerUser = async (body) => {
  return sendPostRequest(routes.user, body);
};

export const fetchUser = async () => {
  return sendGetRequest(routes.user);
};

export const updateUserLogin = async (body) => {
  return sendPatchRequest(routes.login, body);
};

export const getCaseInvitation = async (queryParams) => {
  return sendGetRequest(routes.caseInvitation, queryParams);
};

export const fetchAccountInvitations = async (queryParams) => {
  return sendGetRequest(routes.accountInvitation, queryParams);
};

export const verify2FA = async (body) => {
  return sendPostRequest(routes.verify2FA, body);
};

export const createForgotPassword = async (body) => {
  return sendPostRequest(routes.forgotPassword, body);
};

export const signCaseAgreement = async (body) => {
  return sendPostRequest(routes.signCaseAgreement, body);
};

export const logOutUser = async (body) => {
  return sendPostRequest(routes.logOut, body);
};

export const getCaseDetails = async (queryParams) => {
  return sendGetRequest(routes.caseDetails, queryParams);
};

export const getPendingRequests = async () => {
  return sendGetRequest(routes.allRequests);
};

export const joinParty = async (body) => {
  return sendPostRequest(routes.joinParty, body);
};

export const closeCaseHandler = async (body) => {
  return sendPostRequest(routes.closeCase, body);
};

export const sendCloseCaseResponse = async (body) => {
  return sendPostRequest(routes.closeCaseResponse, body);
};

export const declineCaseInvite = async (body) => {
  return sendPostRequest(routes.declineCase, body);
};

export const requestClawback = async (body) => {
  return sendPostRequest(routes.requestClawback, body);
};

export const sendClawbackResponse = async (body) => {
  return sendPostRequest(routes.clawbackResponse, body);
};

export const subscribe = async (body) => {
  return sendPostRequest(routes.subscription, body);
};

export const getSubscription = async () => {
  return sendGetRequest(routes.subscription);
};

export const send2FAToken = async (body) => {
  return sendPostRequest(routes.twoFactorSend, body);
};

export const fetchAccountUsers = async () => {
  return sendPostRequest(routes.accountUsers, {});
};

export const addUserToAccount = async (body) => {
  return sendPostRequest(routes.accountInvite, body);
};

export const getAccountInvitation = async (queryParams) => {
  return sendGetRequest(routes.accountInvite, queryParams);
};

export const createCase = async (body) => {
  return sendPostRequest(routes.createCase, body);
};

export const updatePayment = async (body) => {
  return sendPatchRequest(routes.subscription, body);
};

export const leaveAccount = async (body = {}) => {
  return sendPostRequest(routes.leaveAccount, body);
};

export const removeUser = async (body) => {
  return sendPostRequest(routes.removeUser, body);
};

export const cancelSubscription = async (body = {}) => {
  return sendDeleteRequest(routes.subscription, body);
};

export const mergeAccount = async (body = {}) => {
  return sendPostRequest(routes.accountMerge, body);
};

export const declineAccountMerge = async (body = {}) => {
  return sendDeleteRequest(routes.accountMerge, body);
};

export const addParty = async (body) => {
  return sendPostRequest(routes.party, body);
};

export const submitSearchCases = async (queryParams) => {
  return sendGetRequest(routes.casesSearch, queryParams);
};

export const submitResetPassword = async (body) => {
  return sendPostRequest(routes.resetPassword, body);
};

export const fetchInvitations = async () => {
  return sendGetRequest(routes.invitations);
};

export const submitVerifyEmail = async (body) => {
  return sendPostRequest(routes.verifyEmail, body);
};

export const removePartyFromCase = async (body) => {
  return sendPostRequest(routes.removeParty, body);
};

export const submitLeaveCase = async (body) => {
  return sendDeleteRequest(routes.leaveCase, body);
};

export const updateProfile = async (body) => {
  return sendPatchRequest(routes.profile, body);
};

export const authLogin = async (body) => {
  return sendPostRequest(routes.authLogin, body);
};

export const submitResendCaseInvite = async (body) => {
  return sendPostRequest(routes.resendCaseInvite, body);
};

export const fetchCaseFileDetails = async (body) => {
  return sendGetRequest(routes.caseFileDetails, body);
};

export const submitEditCase = async (body) => {
  return sendPatchRequest(routes.editCase, body);
};

export const submitUpdatePassword = async (body) => {
  return sendPatchRequest(routes.updatePassword, body);
};

export const submitRemovePartyRep = async (body) => {
  return sendPostRequest(routes.removePartyRep, body);
};
