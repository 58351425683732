import React from 'react';
import BaseModal from '../../base_modal';
import UploadRoadblock from './uploadRoadblock';

export const UploadConfirmationModal = ({
  isVisible,
  toggleModalVisibility,
  fileNamesToUpload,
  onUploadConfirm,
  onUploadCancel,
}) => (
  <BaseModal
    isVisible={isVisible}
    toggleVisibility={toggleModalVisibility}
    modalContent={
      <UploadRoadblock files={fileNamesToUpload} onUploadConfirm={onUploadConfirm} onUploadCancel={onUploadCancel} />
    }
    headerCopy="Upload files"
    wide={true}
  />
);
