import './index.scss';
import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { splitCase } from '../../utils/handlers';
import { isPasswordValid } from '../../utils/string';
import { Button } from '../../components/button';
import { fireSuccessToast, fireErrorToast, fireValidationMessages } from '../../utils/alert';
import { PasswordRequirements } from '../../components/passwordRequirements';
import { registerUser } from '../../utils/api';
import { useRecaptcha } from '../../hooks/useRecaptcha';
import { GettingStarted } from './gettingStarted';
import { TextField } from '../../components/textField';
import { EmailField } from '../../components/emailField';
import { UserAgreement } from './userAgreement';
import { PhoneField } from '../../components/phoneField';
import { phoneDigits } from '../../utils/constants';

export const RegistrationForm = ({ title }) => {
  const [registrationForm, setRegistrationForm] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    companyName: '',
    password: '',
    userAgreement: false,
    phone: '',
  });
  const [loading, setLoading] = useState(false);
  const { isCaptchaChecked, renderCaptcha } = useRecaptcha();

  const navigate = useNavigate();

  const register = async () => {
    if (registrationForm.phone && registrationForm.phone.length !== phoneDigits)
      return fireErrorToast('Please enter a valid phone number');

    const requiredFields = ['firstName', 'lastName', 'emailAddress', 'password', 'userAgreement'];
    const errors = requiredFields
      .map((field) => {
        const isMissing = !registrationForm[field];
        if (isMissing) return `${splitCase(field)} is missing!`;
      })
      .filter((error) => error);

    if (!isPasswordValid(registrationForm.password)) {
      errors.push(
        'Password does not meet minimum requirements: 1 lowercase letter, 1 upper case letter, 1 number, 1 special character, at least 8 characters long.'
      );
    }
    if (errors.length !== 0) return fireValidationMessages(errors);

    try {
      setLoading(true);
      await registerUser(registrationForm);

      const verificationMethod = registrationForm.phone ? 'mobile phone' : 'email';
      fireSuccessToast(`Please check your ${verificationMethod} for your verification code`);

      navigate('/verify');
    } catch (err) {
      fireErrorToast('There seems to be something wrong with the registration. Try again later or contact support');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={6}>
        <div className="registration-form">
          <div className="registration-form__title mb-4">{title}</div>
          <Form>
            <Row className="mb-3">
              <TextField
                id="firstName"
                value={registrationForm.firstName}
                label="First Name *"
                onChange={(firstName) => setRegistrationForm({ ...registrationForm, firstName })}
              />
              <TextField
                id="lastName"
                value={registrationForm.lastName}
                label="Last Name *"
                onChange={(lastName) => setRegistrationForm({ ...registrationForm, lastName })}
              />
            </Row>

            <TextField
              id="companyName"
              value={registrationForm.companyName}
              label="Company Name (Optional)"
              className="mb-3"
              onChange={(companyName) => setRegistrationForm({ ...registrationForm, companyName })}
            />
            <PhoneField
              id="phone"
              value={registrationForm.phone}
              label="Mobile Number (Optional - Used for two factor identification)"
              className="mb-3"
              onChange={(phone) => setRegistrationForm({ ...registrationForm, phone })}
            />
            <EmailField
              value={registrationForm.emailAddress}
              label="Business Email Address *"
              className="mb-3"
              onChange={(emailAddress) => setRegistrationForm({ ...registrationForm, emailAddress })}
            />
            <TextField
              id="pass"
              value={registrationForm.password}
              label="Create Password *"
              className="mb-3"
              isPassword
              onChange={(password) => setRegistrationForm({ ...registrationForm, password })}
            />
            <PasswordRequirements />
            <UserAgreement
              isChecked={registrationForm.userAgreement}
              onChange={(isChecked) => setRegistrationForm({ ...registrationForm, userAgreement: isChecked })}
            />

            {renderCaptcha()}
            {(isCaptchaChecked || process.env.NODE_ENV === 'development') && (
              <Row className="centered-button-row">
                <Button onClick={register} label="Sign Up" loading={loading} />
              </Row>
            )}
          </Form>
        </div>
      </Col>
      <GettingStarted />
    </Row>
  );
};
