import React, { useContext } from 'react';
import { TextArea } from '../../textarea';
import { AIContext } from '../../../contexts/aiContext';
import { SendSVG } from '../../svg/send';
import { SelectedFilesCount } from '../../selectedFilesCount';

export const Prompt = () => {
  const { prompt, setPrompt, sendMessage, isResponseLoading } = useContext(AIContext);

  const isDisabled = !prompt || isResponseLoading;
  const maxChars = 300;
  const charCount = prompt.length;

  const handleSend = async () => {
    if (isDisabled) return;

    const promptToSend = prompt;
    setPrompt('');
    await sendMessage(promptToSend);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  return (
    <div className="ai-chat__prompt-container">
      <div className="ai-chat__prompt-header-container">
        <SelectedFilesCount className="ai-chat__selected_files_count" />

        <div className={`ai-chat__prompt-char_count ${charCount === maxChars && 'ai-chat__prompt-char_count_limit'}`}>
          {charCount}/{maxChars}
        </div>
      </div>

      <TextArea
        className="ai-chat__prompt-textarea"
        value={prompt}
        onChange={(value) => setPrompt(value.slice(0, maxChars))}
        onKeyDown={handleKeyDown}
        placeholder="Ask something about the case..."
        disabled={isResponseLoading}
        autoFocus
      />
      <SendSVG
        className={`ai-chat__prompt-send-button ${isDisabled && 'ai-chat__prompt-send-button_disabled'}`}
        onClick={handleSend}
      />
    </div>
  );
};
