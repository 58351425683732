import { CasesContextConsumer } from '../../../contexts/casesContext';
import { fireErrorToast, fireSuccessToast } from '../../../utils/alert';
import { sendClawbackResponse } from '../../../utils/api';
import { Briefcase } from 'react-bootstrap-icons';
import { clawbackStatus } from '../../../utils/constants';
import { RequestCard } from './requestCard';

export const ClawbackRequests = () => {
  const { pendingRequests, getPendingRequests, getCurrentCases } = CasesContextConsumer();

  const approveClawback = async (responseId) => {
    try {
      await sendClawbackResponse({ responseId, status: clawbackStatus.ACCEPTED });
      await getPendingRequests();
      getCurrentCases();
      fireSuccessToast('You have accepted the clawback request.');
    } catch (error) {
      fireErrorToast();
    }
  };

  const declineClawBack = async (responseId) => {
    try {
      await sendClawbackResponse({ responseId, status: clawbackStatus.DECLINED });
      getPendingRequests();
      fireSuccessToast('You have declined the clawback request.');
    } catch (error) {
      fireErrorToast();
    }
  };

  return pendingRequests?.clawbackRequests.map((request) => {
    const inviterFullName = `${request.clawbackRequest.userLogin.userProfile.firstName} ${request.clawbackRequest.userLogin.userProfile.lastName}`;
    return (
      <RequestCard
        key={request.id}
        createdAt={request.createdAt}
        header={'Clawback Request'}
        title={request.caseParty.partyName}
        inviterFullName={inviterFullName}
        icon={<Briefcase />}
        action={'Approve Clawback'}
        onDeclineClick={() => declineClawBack(request.id)}
        onApproveClick={() => approveClawback(request.id)}
        requestType="Requested"
      />
    );
  });
};
