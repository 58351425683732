import { useEffect, useState } from 'react';
import { FilesConsumer } from '../contexts/filesContext';

export const useResizeHorizontal = () => {
  const [isDragging, setIsDragging] = useState(false);

  const { refLastXDividerPosition, leftPanelRef, rightPanelRef } = FilesConsumer();

  const onMouseDown = (e) => {
    e.preventDefault();
    refLastXDividerPosition.current = e.clientX;
    setIsDragging(true);
  };

  const handleOnMouseMove = (e) => {
    e.preventDefault();
    if (!isDragging || !leftPanelRef.current || !rightPanelRef.current) return;
    const delta = e.clientX - refLastXDividerPosition.current;
    refLastXDividerPosition.current = e.clientX;

    const leftPanel = leftPanelRef.current;
    const rightPanel = rightPanelRef.current;

    const leftWidth = leftPanel.offsetWidth + delta;
    const rightWidth = rightPanel.offsetWidth - delta;

    leftPanel.style.width = `${leftWidth}px`;
    rightPanel.style.width = `${rightWidth}px`;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleOnMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleOnMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  });

  const onTouchStart = (e) => {
    refLastXDividerPosition.current = Math.floor(e.touches[0].clientX);
    setIsDragging(true);
  };

  const onTouchMove = (e) => {
    if (!isDragging || !leftPanelRef.current || !rightPanelRef.current) return;
    const delta = Math.floor(e.changedTouches[0].clientX - refLastXDividerPosition.current);
    refLastXDividerPosition.current = delta > 0 ? e.touches[0].clientX + delta : e.touches[0].clientX - delta;
    console.log('\n\n', refLastXDividerPosition, '\n\n');

    const leftPanel = leftPanelRef.current;
    const rightPanel = rightPanelRef.current;

    const leftWidth = leftPanel.offsetWidth + delta;
    const rightWidth = rightPanel.offsetWidth - delta;

    leftPanel.style.width = `${leftWidth}px`;
    rightPanel.style.width = `${rightWidth}px`;
  };

  const onTouchEnd = () => {
    setIsDragging(false);
  };

  return { onMouseDown, onTouchStart, onTouchMove, onTouchEnd };
};
