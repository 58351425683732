import { FilesConsumer } from '../../contexts/filesContext';

export const SelectedFilesCount = ({ className }) => {
  const { selectedPathObjs, clearSelectedPathObjs } = FilesConsumer();

  return (
    <div className={className}>
      <sub>
        Selected Files: {selectedPathObjs.length}{' '}
        {selectedPathObjs?.length > 0 && <button onClick={clearSelectedPathObjs}>Clear</button>}
      </sub>
    </div>
  );
};
