import { useContext, useEffect, useRef } from 'react';
import { AIContext } from '../../../contexts/aiContext';
import { AliaSVG } from '../../../components/svg/ai';
import { aiName } from '../../../utils/constants';

export const Message = ({ message, isLoading }) => {
  const { currentChat } = useContext(AIContext);
  const ref = useRef();

  useEffect(() => {
    if (currentChat.lastMessage?.id !== message.id) return;

    ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }, []);

  const renderHeader = () => {
    const name = message.isUser ? 'Me' : aiName;

    return (
      <div className="ai-chat__message_header">
        <p>
          {!message.isUser && <AliaSVG className="ai-chat__message_logo" />}
          {name + ':'}{' '}
        </p>
      </div>
    );
  };

  const renderContent = () => <div className={isLoading && 'ai-chat__message_text_loading'}>{message.text}</div>;

  return (
    <div className="ai-chat__message_container">
      {renderHeader()}
      {renderContent()}
      <div ref={ref} />
    </div>
  );
};
