import './styles.scss';

import React, { useState, useRef, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Button } from '../../../button';
import { fireProcessingFileErrors } from '../../../../utils/alert';
import { cleanS3FileName } from '../../../../utils/s3';

const Rename = ({ toggleModalVisibility, pathObj, type, onRename }) => {
  const [newName, setNewName] = useState('');
  const [loading, setLoading] = useState(false);

  const inputRef = useRef(null);

  const isDisabled = loading || !newName || newName === pathObj.name;

  const handleNameChange = (event) => {
    const value = event.target.value || '';
    const safeName = cleanS3FileName(value);
    setNewName(safeName);
  };

  const handleRename = async () => {
    setLoading(true);

    try {
      await onRename({
        id: pathObj.id,
        name: pathObj.name,
        newName: pathObj.isFolder ? newName : newName + pathObj.extension,
        courtCaseId: pathObj.caseId,
      });
      toggleModalVisibility();
    } catch (err) {
      fireProcessingFileErrors(err, `Something went wrong renaming your ${type}. Try again or contact support`);
    } finally {
      setLoading(false);
    }
  };

  const handleEnter = (e) => {
    if (e.key !== 'Enter' || isDisabled) return;

    e.preventDefault();
    handleRename();
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <Row style={{ minWidth: '500px' }}>
      <Col xs={12}>
        <div className="standard-modal">
          <Form>
            <p className="mb-0">Enter a new name for the {`${pathObj.isFile ? 'file' : 'folder'}`}:</p>
            <p className="mb-4">"{pathObj.name}"</p>
            <Row className="mb-5">
              <Form.Group as={Col} xs={12} md={12} controlId="formName">
                <Form.Label className="required-input">Name</Form.Label>
                <div className="rename-modal__input_container">
                  <Form.Control
                    type="text"
                    required
                    name="name"
                    ref={inputRef}
                    onChange={handleNameChange}
                    onKeyDown={handleEnter}
                    value={newName}
                    className="rename-modal__input"
                  />

                  <span className="rename-modal__input_extension"> {pathObj.extension} </span>
                </div>
              </Form.Group>
            </Row>

            <Row className="modal-buttons__container">
              <Button color="light" onClick={toggleModalVisibility} label="Cancel" />
              <Button
                color="primary"
                isDisabled={isDisabled}
                onClick={handleRename}
                label={`Rename ${type}`}
                loading={loading}
              />
            </Row>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default Rename;
