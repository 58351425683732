import { CasesContextConsumer } from '../../../contexts/casesContext';
import { fireErrorToast, fireSuccessToast } from '../../../utils/alert';
import { declineCaseInvite, joinParty } from '../../../utils/api';
import { Briefcase } from 'react-bootstrap-icons';
import { RequestCard } from './requestCard';
import { useNavigate } from 'react-router-dom';

export const CaseInvite = () => {
  const { pendingRequests, getPendingRequests, getCurrentCases } = CasesContextConsumer();

  const navigate = useNavigate();

  const joinCase = async (pendingCaseRequestId) => {
    try {
      const { courtCaseId } = await joinParty({ pendingCaseRequestId });
      await getPendingRequests();
      await getCurrentCases();
      fireSuccessToast('You have joined the case.');
      navigate(`/case-detail?caseId=${courtCaseId}`);
    } catch (error) {
      fireErrorToast();
    }
  };

  const submitDeclineCaseInvite = async (requestId) => {
    try {
      await declineCaseInvite({ inviteId: requestId });
      await getPendingRequests();
      fireSuccessToast('You have declined the invite.');
    } catch (error) {
      fireErrorToast();
    }
  };

  return pendingRequests.partyInvites?.map((request) => {
    const inviterFullName = `${request.partyInvite.userLogin.userProfile.firstName} ${request.partyInvite.userLogin.userProfile.lastName}`;
    return (
      <RequestCard
        key={request.id}
        createdAt={request.createdAt}
        header={'Invited to Case'}
        title={request.caseName}
        inviterFullName={inviterFullName}
        icon={<Briefcase />}
        action={'Join Case'}
        onDeclineClick={() => submitDeclineCaseInvite(request.id)}
        onApproveClick={() => joinCase(request.id)}
      />
    );
  });
};
