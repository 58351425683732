import './styles.scss';
import { useState } from 'react';
import {
  FileEarmarkText,
  Folder,
  FiletypeAi,
  X,
  FileEarmarkXFill,
  FileEarmarkTextFill,
  FileEarmarkMinus,
} from 'react-bootstrap-icons';
import { embeddingStatuses } from '../../../utils/enums';

const AiIcon = ({ embeddingStatus }) => {
  const [displayTooltip, setDisplayTooltip] = useState(false);

  const renderMessage = () => {
    switch (embeddingStatus) {
      case embeddingStatuses.DONE:
        return 'This file is ready for questions!';
      case embeddingStatuses.FAILED:
        return 'ALIA was unable to read this file - please try again later';
      case embeddingStatuses.NOT_SUPPORTED:
        return 'ALIA is unable to read this file as it is not supported';
      case embeddingStatuses.EMPTY_DOCUMENT:
        return 'ALIA is making this file searchable';
      case embeddingStatuses.NO_TEXT:
        return 'ALIA is unable to read this file as it has no text';
      default:
        return 'This file is being embedded in the AI model';
    }
  };

  const renderIcon = () => {
    const XIcon = (
      <X
        className="table-X-icon"
        onMouseEnter={() => setDisplayTooltip(true)}
        onMouseLeave={() => setDisplayTooltip(false)}
        data-testid="failed-embedding"
      />
    );

    const FiletypeAiIcon = (
      <FiletypeAi
        className="table-file-icon"
        onMouseEnter={() => setDisplayTooltip(true)}
        onMouseLeave={() => setDisplayTooltip(false)}
        data-testid="done-embedding"
      />
    );

    const FileEarmarkIcon = (
      <FileEarmarkText
        className="table-file-icon"
        onMouseEnter={() => setDisplayTooltip(true)}
        onMouseLeave={() => setDisplayTooltip(false)}
        data-testid="pending-embedding"
      />
    );

    const FileEarmarkXFillIcon = (
      <FileEarmarkXFill
        className="table-file-fill-icon"
        onMouseEnter={() => setDisplayTooltip(true)}
        onMouseLeave={() => setDisplayTooltip(false)}
        data-testid="not-supported-embedding"
      />
    );

    const FileEarmarkPdfFillIcon = (
      <FileEarmarkTextFill
        className="table-file-fill-icon"
        onMouseEnter={() => setDisplayTooltip(true)}
        onMouseLeave={() => setDisplayTooltip(false)}
        data-testid="empty-document-embedding"
      />
    );

    const FileEarmarkMinusIcon = (
      <FileEarmarkMinus
        className="table-file-icon"
        onMouseEnter={() => setDisplayTooltip(true)}
        onMouseLeave={() => setDisplayTooltip(false)}
        data-testid="no-text-embedding"
      />
    );

    switch (embeddingStatus) {
      case embeddingStatuses.DONE:
        return FiletypeAiIcon;
      case embeddingStatuses.FAILED:
        return XIcon;
      case embeddingStatuses.NOT_SUPPORTED:
        return FileEarmarkXFillIcon;
      case embeddingStatuses.EMPTY_DOCUMENT:
        return FileEarmarkPdfFillIcon;
      case embeddingStatuses.NO_TEXT:
        return FileEarmarkMinusIcon;
      default:
        return FileEarmarkIcon;
    }
  };

  return (
    <span className="table-file-icon-ai__container">
      {renderIcon()}
      {displayTooltip && <span className="table-file-icon-ai__tooltip">{renderMessage()}</span>}
    </span>
  );
};

export const PathObjIcon = ({ isFolder, embeddingStatus }) => {
  if (isFolder) return <Folder className="table-file-icon" />;
  return <AiIcon embeddingStatus={embeddingStatus} />;
};
