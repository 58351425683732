import './index.scss';
import React, { useRef, useState } from 'react';
import TextLogo from '../../assets/litx_logo_text.svg';
import { AuthContextConsumer } from '../../contexts/authContext';
import { CasesContextConsumer } from '../../contexts/casesContext';
import RequestsDropdown from './requestsDropdown';
import NavigationModal from '../modals/navigation_modal';
import menuIcon from '../../assets/menuIcon.png';

const coloredRoutes = ['/case-detail', '/settings', '/create-case', '/case-agreement', '/dashboard-welcome'];

const Header = ({ openModalId, toggleModalVisibility }) => {
  const [showRequestsDropdown, setShowRequestsDropdown] = useState(false);
  const toggleRequestsDropdown = () => setShowRequestsDropdown((prevState) => !prevState);

  const { userProfile } = AuthContextConsumer();
  const { totalRequests } = CasesContextConsumer();

  const parentNodeRef = useRef(null);

  const handleAvatarClick = async (e) => {
    e.stopPropagation();
    toggleRequestsDropdown();
  };

  const HeaderComponent = () => (
    <div
      className="header"
      style={{ backgroundColor: coloredRoutes.includes(window.location.pathname) ? '#ECEAE4' : '' }}
    >
      <div className="header-left">
        <span className="pointer" onClick={() => toggleModalVisibility('navigation-sidebar')}>
          <img src={menuIcon} className="header-menu-icon" />
        </span>
        <img src={TextLogo} alt="" />
      </div>

      <div className="header-right" ref={parentNodeRef}>
        <RequestsDropdown toggleModalVisibility={toggleRequestsDropdown} isVisible={showRequestsDropdown} />

        <div className="icons-wrapper pointer" data-testid={'avatar'} onClick={handleAvatarClick}>
          {totalRequests >= 1 && <span className="notification-badge">{totalRequests}</span>}
          <div className="avatar">{`${userProfile?.firstName?.slice(0, 1)}${userProfile?.lastName?.slice(0, 1)}`}</div>
        </div>
      </div>
      {openModalId === 'navigation-sidebar' && (
        <NavigationModal
          isVisible={openModalId === 'navigation-sidebar'}
          toggleModalVisibility={toggleModalVisibility}
        />
      )}
    </div>
  );

  return <HeaderComponent />;
};

export default Header;
