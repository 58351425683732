import { useContext, useMemo } from 'react';
import { AIContext } from '../../../contexts/aiContext';
import { GroupedChats } from './models';

export const History = () => {
  const { chats } = useContext(AIContext);

  const groupedChats = useMemo(() => {
    const _chats = new GroupedChats();
    chats.forEach((chat) => _chats.add(chat));

    return _chats.group();
  }, [chats]);

  return (
    <div className="ai-chat__history_container">
      {groupedChats.map((data) => (
        <Section key={data.sectionName} name={data.sectionName} chats={data.sectionChats} />
      ))}
    </div>
  );
};

const Section = ({ chats, name }) => (
  <div className="ai-chat__history_section_container">
    <div className="ai-chat__history_section_name"> {name} </div>

    {chats.map((chat) => (
      <Chat key={chat.id} id={chat.id} title={chat.title} />
    ))}
  </div>
);

const Chat = ({ title, id }) => {
  const { currentChat, openChat } = useContext(AIContext);
  const isSelected = currentChat.id === id;

  const handleClick = () => {
    if (currentChat.id === id) return;

    openChat(id);
  };

  return (
    <div
      className={`ai-chat__history_chat_title ${isSelected ? 'ai-chat__history_active_chat' : undefined}`}
      onClick={handleClick}
    >
      {title}
    </div>
  );
};
