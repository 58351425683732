import { CasesContextConsumer } from '../../../contexts/casesContext';
import { fireErrorToast, fireSuccessToast } from '../../../utils/alert';
import { sendCloseCaseResponse } from '../../../utils/api';
import { X } from 'react-bootstrap-icons';
import { closeCaseStatus } from '../../../utils/constants';
import { RequestCard } from './requestCard';

export const CloseCase = () => {
  const { pendingRequests, getPendingRequests, getCurrentCases } = CasesContextConsumer();

  const approveCloseCase = async (responseId) => {
    try {
      await sendCloseCaseResponse({ responseId, status: closeCaseStatus.APPROVED });
      await getPendingRequests();
      getCurrentCases();
      fireSuccessToast('You have accepted the close case request.');
    } catch (error) {
      fireErrorToast('Something went wrong when accepting the close case request.');
    }
  };

  const declineCloseCase = async (responseId) => {
    try {
      await sendCloseCaseResponse({ responseId, status: closeCaseStatus.DECLINED });
      getPendingRequests();
      fireSuccessToast('You have declined the close case request.');
    } catch (error) {
      fireErrorToast('Something went wrong when declining the close case request.');
    }
  };

  return pendingRequests?.closeCaseRequests.map((request) => {
    const inviterFullName = `${request.closeCaseRequest.userLogin.userProfile.firstName} ${request.closeCaseRequest.userLogin.userProfile.lastName}`;
    return (
      <RequestCard
        key={request.id}
        createdAt={request.createdAt}
        header={'Close Case Request'}
        title={request.closeCaseRequest.courtCase.caseName}
        inviterFullName={inviterFullName}
        icon={<X />}
        action={'Close Case'}
        onDeclineClick={() => declineCloseCase(request.id)}
        onApproveClick={() => approveCloseCase(request.id)}
        requestType="Requested"
      />
    );
  });
};
