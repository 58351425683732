import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useFetchPendingRequests } from '../hooks/useFetchAllRequests';
import { routes, sendGetRequest } from '../utils/api';
import { casesPageSize } from '../utils/constants';

export const CasesContext = createContext({});

export const CasesProvider = ({ children }) => {
  const [currentCases, setCurrentCases] = useState([]);
  const [pendingRequests, setPendingRequests] = useState({
    partyInvites: [],
    partyApproval: [],
    closeCaseRequests: [],
  });
  const [totalRequests, setTotalRequests] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCases, setTotalCases] = useState(0);

  const { data: pendingRequestsData, refetch: getPendingRequests, isError } = useFetchPendingRequests();

  const getCurrentCases = async ({ dependant = false } = {}) => {
    const { items: cases, paginator } = await sendGetRequest(
      routes.cases,
      new URLSearchParams({ dependant, page: currentPage, limit: casesPageSize })
    );
    setTotalPages(paginator.pages);
    setTotalCases(paginator.totalCount);

    if (!cases) return;

    setCurrentCases(cases);
    return cases;
  };

  useEffect(() => {
    getCurrentCases();
  }, []);

  useEffect(() => {
    if (!pendingRequestsData || isError) return;
    setPendingRequests(pendingRequestsData);
    setTotalRequests(pendingRequestsData.total);
  }, [pendingRequestsData]);

  const finalParams = useMemo(
    () => ({
      currentCases,
      setCurrentCases,
      pendingRequests,
      setPendingRequests,
      totalRequests,
      setTotalRequests,
      getPendingRequests,
      getCurrentCases,
      currentPage,
      setCurrentPage,
      totalPages,
      totalCases,
    }),
    [currentCases, pendingRequests, currentPage, totalPages, setCurrentPage, totalCases]
  );

  return <CasesContext.Provider value={finalParams}>{children}</CasesContext.Provider>;
};

export const CasesContextConsumer = () => useContext(CasesContext);
