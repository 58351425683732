import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

export const asTypes = {
  input: 'input',
  textarea: 'textarea',
};

export const TextField = ({ value, controlId, label, isDisabled = false, as, name = '', md = 0, xs = 0, onChange }) => {
  const handleChange = (event) => {
    onChange((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <Form.Group as={Col} xs={xs} md={md} className="mb-1" controlId={controlId}>
      <Form.Label className="required-input">{label}</Form.Label>
      <Form.Control
        className="mb-4"
        disabled={isDisabled}
        type={'text'}
        value={value}
        as={as}
        name={name}
        onChange={handleChange}
      ></Form.Control>
    </Form.Group>
  );
};
