export const inputHandler = function (e, setState) {
  const { name, value, checked, type } = e.target;
  const newVal = type === 'checkbox' ? checked : value;

  setState((prevState) => ({
    ...prevState,
    [name]: newVal,
  }));
};

export const splitCase = (str) => {
  if (!str) {
    return '';
  }

  return str
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
};

export const formatFileName = (name) => {
  if (!name) {
    return '';
  }

  return name.split('.')[0];
};

export const randomBgColor = () => {
  const x = Math.floor(Math.random() * 256);
  const y = Math.floor(Math.random() * 256);
  const z = Math.floor(Math.random() * 256);
  return 'rgb(' + x + ',' + y + ',' + z + ')';
};

const avatarColors = [
  { backgroundColor: '#C2BBA8', color: 'black' },
  { backgroundColor: '#D6D6D6', color: 'black' },
  { backgroundColor: '#615F59', color: 'white' },
];

const userAvatarColor = { backgroundColor: '#B23E12', color: 'white' };

export const avatarBackgroundStyle = (idx, isUser) => {
  if (isUser) return userAvatarColor;

  if (idx % 3 === 0) return avatarColors[2];
  else if (idx % 2 === 0) return avatarColors[1];
  else return avatarColors[0];
};

export const titleCase = (string) => {
  if (!string) return '';

  return string[0].toUpperCase() + string.slice(1).toLowerCase();
};

export const parseCompany = (party) => {
  const tempData = { ...party };
  if (tempData.companyName && tempData.companyName.trim() === '') {
    tempData.companyName = 'N/A';
  }

  if (tempData.company && tempData.company.trim() === '') {
    tempData.company = 'N/A';
  }

  return tempData;
};
