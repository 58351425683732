import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Button } from '../../../button';
import { fireProcessingFileErrors } from '../../../../utils/alert';
import { FilesConsumer } from '../../../../contexts/filesContext';
import { sendDeleteRequest, routes } from '../../../../utils/api';

const RemoveEmptyFolder = ({ toggleModalVisibility }) => {
  const {
    clearSelectedPathObjs,
    selectedPathObjs,
    filteredUploadedFiles,
    getUploadedFiles: refreshFileSystem,
  } = FilesConsumer();
  const [loading, setLoading] = useState(false);

  const folder = filteredUploadedFiles.find((pathObj) => pathObj.id === selectedPathObjs?.[0].id);

  const handleConfirm = async () => {
    setLoading(true);

    try {
      await sendDeleteRequest(routes.folders, { id: folder.id, courtCaseId: folder.caseId, name: folder.name });
      await refreshFileSystem();
      clearSelectedPathObjs();
      toggleModalVisibility();
    } catch (err) {
      fireProcessingFileErrors(err, 'Something went wrong deleting your folder. Try again or contact support');
    } finally {
      setLoading(false);
    }
  };
  const handleCancel = () => {
    clearSelectedPathObjs();
    toggleModalVisibility();
  };

  return (
    <Row style={{ minWidth: '500px' }}>
      <Col xs={12}>
        <div className="add-folder-form standard-modal">
          <Form>
            <Row className="mb-5">Are you sure you want to delete the empty folder with name "{folder.name}"?</Row>

            <Row className="modal-buttons__container">
              <Button color="light" onClick={handleCancel} label="Cancel" />
              <Button color="primary" onClick={handleConfirm} label="Delete" loading={loading} />
            </Row>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default RemoveEmptyFolder;
