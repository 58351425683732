import '../index.scss';

import { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { CancelOrAcceptCluster } from '../../../cancelOrAcceptCluster';
import { TextField } from '../../../textField';
import { Select } from '../../../customSelect';
import { EmailField } from '../../../emailField';

export const InviteOtherPartyToCase = ({
  caseDetails,
  partySelection,
  toggleModalVisibility,
  sendInvite,
  isLoading,
  options,
}) => {
  const [inviteData, setInviteData] = useState({
    caseId: caseDetails.id,
    partyId: partySelection.partyId,
    emailAddress: '',
    firstName: '',
    lastName: '',
    function: '',
    companyName: '',
    addToAccount: false,
    isAdmin: true,
    note: '',
  });

  const handleSelectChange = (value) => {
    setInviteData((prevState) => ({
      ...prevState,
      function: value,
    }));
  };

  const areRequiredFieldsFilled = ['firstName', 'lastName', 'emailAddress', 'function'].every(
    (field) => !!inviteData[field]
  );

  return (
    <Row>
      <Col xs={12}>
        <div className="invite-to-case-form standard-modal">
          <Form>
            <Row className="mb-1">
              <TextField
                isDisabled={true}
                className="mb-4"
                value={partySelection.partyName}
                label={'Invitee will represent'}
                xs={12}
                md={6}
              />
            </Row>

            <Row className="mb-1">
              <TextField
                id={'firstName'}
                className="mb-4"
                value={inviteData.firstName}
                onChange={(firstName) => setInviteData({ ...inviteData, firstName })}
                label="First Name"
              />

              <TextField
                id={'lastName'}
                className="mb-4"
                value={inviteData.lastName}
                onChange={(lastName) => setInviteData({ ...inviteData, lastName })}
                label="Last Name"
              />
            </Row>

            <Row className="mb-1">
              <TextField
                id={'companyName'}
                className="mb-4"
                value={inviteData.companyName}
                onChange={(companyName) => setInviteData({ ...inviteData, companyName })}
                label="Company Name"
              />

              <EmailField
                className="mb-4"
                value={inviteData.emailAddress}
                onChange={(emailAddress) => setInviteData({ ...inviteData, emailAddress })}
                label="Email Address"
              />
            </Row>

            <Row className="mb-4">
              <Select
                id={'functionSelect'}
                label={'Invitee will have the following role'}
                placeholder={'Please Select a Role'}
                onChange={handleSelectChange}
                options={options}
                value={inviteData.function}
                controlId={'functionSelect'}
              />
            </Row>

            <Row className="mb-1">
              <TextField
                id={'note'}
                label={'Personal note for the invite email (Optional)'}
                value={inviteData.note}
                onChange={(note) => setInviteData({ ...inviteData, note })}
              />
            </Row>

            <CancelOrAcceptCluster
              onCancelClick={toggleModalVisibility}
              isLoading={isLoading}
              onAcceptClick={() => sendInvite(inviteData)}
              isDisabled={!areRequiredFieldsFilled}
              acceptLabel={'Invite to Case'}
            />
          </Form>
        </div>
      </Col>
    </Row>
  );
};
