export const initialUserInfo = {
  id: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  company: '',
  userAgreement: false,
};

export const initialTrialInfo = {
  firstName: '',
  lastName: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  paymentMethodId: 'pm_0',
};

export const initialCaseDetails = {
  caseName: '',
  courtName: '',
  causeNo: '',
  caseType: '',
  caseParties: [{ partyName: '', type: '' }],
};

export const defaultCaseDetails = {
  id: '',
  active: true,
  caseName: '',
  causeNo: '',
  caseOwner: false,
  courtName: '',
  caseType: '',
  createdAt: '',
  updatedAt: '',
  caseParties: [
    {
      id: '',
      partyName: '',
      createdAt: '',
      updatedAt: '',
      courtCaseId: '',
      partyReps: [
        {
          id: '',
          role: '',
          userName: '',
          function: '',
          userLoginId: '',
          createdAt: '',
          updatedAt: '',
          casePartyId: '',
        },
      ],
    },
  ],
  files: [],
  myParty: {
    partyName: '',
    partyId: '',
    role: '',
    partyType: '',
    caseRole: '',
    partyRepId: '',
  },
  hasSignedAgreement: true,
};

export const initialAccountUsers = [
  {
    id: '',
    avatar: '',
    name: '',
    permission: '',
    emailAddress: '',
  },
];

export const initialUserProfile = {
  emailAddress: '',
  id: '',
  firstName: '',
  lastName: '',
  company: '',
  photoUrl: '',
  bio: '',
  title: '',
  secondaryEmail: '',
  phone: '',
  fax: '',
  streetAddress: '',
  city: '',
  state: '',
  zipCode: '',
  showUploadPrompt: true,
  newDocumentEmail: true,
  createdAt: '',
  updatedAt: '',
  userLoginId: '',
  twoFactorPreference: '',
  smsMobile: '',
  subscribed: false,
  activeCases: 0,
  isAccountOwner: false,
};

export const initialPendingCaseApproval = {
  id: '',
  caseName: '',
  partyName: '',
  firstName: '',
  lastName: '',
  adminApproved: false,
};

export const caseRoles = [
  'Assistant',
  'Associate',
  'Clerk',
  'Lawyer',
  'Legal Assistant',
  'Other',
  'Paralegal',
  'Partner',
];

export const neutralRoles = ['Arbitrator', 'Judge', 'Mediator', 'Other'];

export const caseTypes = [
  'Assault/Battery',
  'Construction',
  'Contract',
  'Defamation',
  'Employment',
  'Family Law',
  'Malpractice',
  'Motor Vehicle Accident',
  'Premises',
  'Product Liability',
  'Real Property',
  'Related to Criminal Matters',
  'Tax',
  'Other Civil',
  'Other Injury or Damage',
];

export const authRoutes = ['/register', '/', '/register', '/invited-registration', '/login', '/verify'];
