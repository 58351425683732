import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { submitLeaveCase } from '../../../../utils/api';
import { useNavigate } from 'react-router-dom';
import { fireErrorToast, fireSuccessToast } from '../../../../utils/alert';
import { CancelOrAcceptCluster } from '../../../cancelOrAcceptCluster';
import { AuthContextConsumer } from '../../../../contexts/authContext';
import { Select } from '../../../customSelect';
import { partyRepRoles } from '../../../../utils/enums';

const LeaveCase = ({ caseDetails, toggleModalVisibility }) => {
  const [partyReps, setPartyReps] = useState([]);
  const [selectedPartyAdmin, setSelectedPartyAdmin] = useState('');
  const [isPartyAdminNeeded, setIsPartyAdminNeeded] = useState(true);

  const { userProfile } = AuthContextConsumer();

  const navigate = useNavigate();

  useEffect(() => {
    if (caseDetails.myParty.role !== partyRepRoles.ADMIN) return;

    const myParty = caseDetails.caseParties.find((party) => party.id === caseDetails.myParty.partyId);
    const partyReps = myParty.partyReps.filter((partyRep) => partyRep.userLoginId !== userProfile.userLoginId);
    const isPartyAdmin = partyReps.find((partyRep) => partyRep.role === partyRepRoles.ADMIN);
    if (isPartyAdmin || partyReps.length === 0) return setIsPartyAdminNeeded(false);

    const options = partyReps.map((partyRep) => ({
      value: partyRep.id,
      name: partyRep.userLogin.userProfile.firstName + ' ' + partyRep.userLogin.userProfile.lastName,
    }));

    setPartyReps(options);
  }, [caseDetails]);

  const leaveCase = async () => {
    try {
      await submitLeaveCase({
        partyAdmin: selectedPartyAdmin,
        courtCaseId: caseDetails.id,
      });
      fireSuccessToast('You have successfully left the case');
      toggleModalVisibility();
      navigate('/dashboard');
    } catch (error) {
      fireErrorToast('Error leaving case please try again');
    }
  };

  const isDisabled = (() => {
    if (caseDetails.myParty.role !== partyRepRoles.ADMIN || !isPartyAdminNeeded) return false;
    return selectedPartyAdmin === '';
  })();

  return (
    <Row>
      <Col xs={12}>
        <div className="invite-to-case-form standard-modal">
          <Form>
            <Row>
              <Form.Group as={Col} xs={12} md={12} controlId="partySelect">
                <Form.Label className="required-input">
                  You’re about to leave the case {caseDetails.caseName}. By leaving the case, you’ll no longer have
                  access to the case documents or any other materials. Please confirm this is what you’d like to do.
                </Form.Label>

                {caseDetails.myParty.role === partyRepRoles.ADMIN && isPartyAdminNeeded && (
                  <>
                    <Select
                      id="new party admin select"
                      options={partyReps}
                      onChange={setSelectedPartyAdmin}
                      value={selectedPartyAdmin}
                      placeholder="Please Select"
                      label="Select a new party admin"
                    />
                  </>
                )}
              </Form.Group>
            </Row>

            <CancelOrAcceptCluster
              acceptLabel="Leave Case"
              onCancelClick={toggleModalVisibility}
              onAcceptClick={leaveCase}
              isDisabled={isDisabled}
            />
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default LeaveCase;
