import React from 'react';
import BaseModal from '../../base_modal';
import AddPhone from './addPhone';

export const AddPhoneModal = ({
  isVisible,
  toggleModalVisibility,
  currentModalField,
  savedPhone,
  headerCopy = 'Add mobile phone number',
}) => (
  <BaseModal
    isVisible={isVisible}
    toggleVisibility={toggleModalVisibility}
    modalContent={
      <AddPhone
        toggleModalVisibility={toggleModalVisibility}
        currentModalField={currentModalField}
        savedPhone={savedPhone}
      />
    }
    headerCopy={headerCopy}
  />
);
