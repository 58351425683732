import './index.scss';
import { useContext, useEffect, useState } from 'react';
import { LoadingBar } from '../../components/loadingBar';
import DashboardCasesTable from './dashboardCasesTable';
import { PageNavigation } from './pageNavigation';
import { CasesContext } from '../../contexts/casesContext';

const DashboardMulticase = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { getCurrentCases, currentPage } = useContext(CasesContext);

  useEffect(() => {
    const getCasesWithLoading = async () => {
      setIsLoading(true);

      try {
        await getCurrentCases();
      } finally {
        setIsLoading(false);
      }
    };

    getCasesWithLoading();
  }, [currentPage]);

  const renderContent = () => {
    if (isLoading) return <LoadingBar />;

    return (
      <>
        <DashboardCasesTable />
        <PageNavigation />
      </>
    );
  };

  return (
    <div className="dashboard-multicase">
      <div className="litx-table">
        <h1 className="litx-table__title mb-4">Cases</h1>
        {renderContent()}
      </div>
    </div>
  );
};

export default DashboardMulticase;
