import './index.scss';
import { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { AuthContextConsumer } from '../../contexts/authContext';
import { splitCase } from '../../utils/handlers';
import { initialTrialInfo } from '../../utils/defaults';
import { fireValidationMessages } from '../../utils/alert';
import { StripeModal } from './stripeModal';
import { TextField } from '../../components/textField';
import { CancelOrAcceptCluster } from '../../components/cancelOrAcceptCluster';
import { useNavigate } from 'react-router-dom';
import { Col } from 'react-bootstrap';

export const SubscribeView = ({ navigate = useNavigate() }) => {
  const [trialUserInfo, setTrialUserInfo] = useState(initialTrialInfo);
  const [showStripeModal, setShowStripeModal] = useState(false);

  const { userProfile } = AuthContextConsumer();

  const toggleStripeModal = () => setShowStripeModal(!showStripeModal);

  const handleZipChange = (newZip = '') => {
    const parsedZip = newZip
      .match(/[0-9]*/g)
      .join('')
      .slice(0, 5);

    setTrialUserInfo({ ...trialUserInfo, zip: parsedZip });
  };

  useEffect(() => {
    setTrialUserInfo({
      ...initialTrialInfo,
      firstName: userProfile?.firstName || '',
      lastName: userProfile?.lastName || '',
    });
  }, [userProfile]);

  const openStripeModal = () => {
    const fields = Object.keys(trialUserInfo);
    const errors = fields
      .map((field) => {
        const isMissing = !trialUserInfo[field];
        if (isMissing) return `${splitCase(field)} is missing!`;
      })
      .filter((error) => error);

    if (errors.length === 0) return toggleStripeModal();

    fireValidationMessages(errors);
  };

  return (
    <div className="subscribe">
      <Col className="subscribe-form">
        <h1 className="subscribe-form__title">Begin your LitX subscription</h1>
        <h2 className="subscribe-form__subtitle mb-3">Enter your information to start your subscription</h2>
        <Form>
          <Row className="mb-3">
            <TextField
              label="First Name"
              id="firstName"
              value={trialUserInfo.firstName}
              onChange={(firstName) => setTrialUserInfo({ ...trialUserInfo, firstName })}
            />
            <TextField
              label="Last Name"
              id="lastName"
              value={trialUserInfo.lastName}
              onChange={(lastName) => setTrialUserInfo({ ...trialUserInfo, lastName })}
            />
          </Row>
          <Row className="mb-3">
            <TextField
              label="Address"
              id="address"
              value={trialUserInfo.address}
              onChange={(address) => setTrialUserInfo({ ...trialUserInfo, address })}
            />
          </Row>

          <Row className="mb-3">
            <TextField
              label="City"
              id="city"
              value={trialUserInfo.city}
              onChange={(city) => setTrialUserInfo({ ...trialUserInfo, city })}
              xs={6}
            />
            <TextField
              label="State"
              id="state"
              value={trialUserInfo.state}
              onChange={(state) => setTrialUserInfo({ ...trialUserInfo, state })}
              xs={2}
            />
            <TextField label="Zip" id="zip" value={trialUserInfo.zip} onChange={handleZipChange} xs={4} />
          </Row>

          <div className="subscribe__centered_button_row">
            <CancelOrAcceptCluster
              onAcceptClick={openStripeModal}
              onCancelClick={() => navigate('/dashboard')}
              acceptLabel="Continue"
            />
          </div>
        </Form>

        <StripeModal
          isVisible={showStripeModal}
          toggleModalVisibility={toggleStripeModal}
          trialUserInfo={trialUserInfo}
        />
      </Col>
    </div>
  );
};
