import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { AuthContextConsumer } from '../../../../contexts/authContext';
import { CancelOrAcceptCluster } from '../../../cancelOrAcceptCluster';
import { TextField } from '../../../textField';
import { Select } from '../../../customSelect';
import { CheckBox } from '../../../checkBox';
import { EmailField } from '../../../emailField';

export const InviteUserToMyParty = ({
  caseDetails,
  isLoading,
  options,
  onAcceptClick,
  onCancelClick,
  onBackClick,
  hasDependantUsers,
}) => {
  const { userProfile } = AuthContextConsumer();

  const [inviteData, setInviteData] = useState({
    caseId: caseDetails.id,
    partyId: caseDetails.myParty.partyId,
    emailAddress: '',
    firstName: '',
    lastName: '',
    function: '',
    companyName: '',
    addToAccount: true,
    isAdmin: false,
    note: '',
  });

  const handleSelectChange = (value) => {
    setInviteData((prevState) => ({
      ...prevState,
      function: value,
    }));
  };

  const areRequiredFieldsFilled = ['firstName', 'lastName', 'emailAddress', 'function'].every(
    (field) => inviteData[field]
  );

  const renderBackButton = () => {
    if (!hasDependantUsers) return;

    return (
      <span className="back-button mb-5 pointer" onClick={onBackClick}>
        &#60; Back
      </span>
    );
  };

  return (
    <Row>
      <Col xs={12}>
        <div className="invite-to-case-form standard-modal">
          {renderBackButton()}
          <div className="mt-2">
            <strong>
              Add New User To The <i>{caseDetails.myParty.partyName}</i> Party
            </strong>
          </div>
          <Form>
            <Row className="mb-1 mt-2">
              <TextField
                id="firstName"
                className="mb-4"
                value={inviteData.firstName}
                onChange={(firstName) => setInviteData({ ...inviteData, firstName })}
                label="First Name"
              />

              <TextField
                id="lastName"
                className="mb-4"
                value={inviteData.lastName}
                onChange={(lastName) => setInviteData({ ...inviteData, lastName })}
                label="Last Name"
              />
            </Row>

            <Row className="mb-1">
              <TextField
                id="companyName"
                className="mb-4"
                value={inviteData.companyName}
                onChange={(companyName) => setInviteData({ ...inviteData, companyName })}
                label="Company Name"
              />

              <EmailField
                className="mb-4"
                value={inviteData.emailAddress}
                onChange={(emailAddress) => setInviteData({ ...inviteData, emailAddress })}
                label="Email Address"
              />
            </Row>

            <Row className="mb-3">
              <Select
                id={'functionSelect'}
                label={'Invitee will have the following role'}
                placeholder={'Please Select a Role'}
                onChange={handleSelectChange}
                options={options}
                value={inviteData.function}
                controlId={'functionSelect'}
              />
            </Row>

            <Row className="mb-1">
              <CheckBox
                label={`Make this person a party administrator`}
                name="isAdmin"
                title="isAdmin"
                isChecked={inviteData.isAdmin}
                onChange={(isChecked) => setInviteData({ ...inviteData, isAdmin: isChecked })}
                type="checkbox"
              />

              <CheckBox
                isDisplayed={userProfile.isAccountOwner}
                label={`Add this new user to my account`}
                name="addToAccount"
                title="addToAccount"
                isChecked={inviteData.addToAccount}
                onChange={(isChecked) => setInviteData({ ...inviteData, addToAccount: isChecked })}
                type="checkbox"
              />
            </Row>

            <Row className="mb-4">
              <TextField
                id={'note'}
                className="mb-4"
                label={'Personal note for the invite email (Optional)'}
                value={inviteData.note}
                onChange={(note) => setInviteData({ ...inviteData, note })}
              />
            </Row>

            <CancelOrAcceptCluster
              isLoading={isLoading}
              onCancelClick={onCancelClick}
              onAcceptClick={() => onAcceptClick(inviteData)}
              isDisabled={!areRequiredFieldsFilled}
              acceptLabel={'Invite to Case'}
            />
          </Form>
        </div>
      </Col>
    </Row>
  );
};
