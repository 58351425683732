import { Select } from '../../components/select';
import { AuthContextConsumer } from '../../contexts/authContext';

export const AccountAdminSelect = ({ onChange }) => {
  const { userProfile, accountUsers } = AuthContextConsumer();

  const selectOptions = [
    {
      label: 'My Cases',
      value: false,
    },
    {
      label: 'All Cases',
      value: true,
    },
  ];

  const selectProps = {
    options: selectOptions,
    onChange,
  };

  return (
    userProfile.isAccountOwner &&
    accountUsers.length > 1 && (
      <div className="ms-4">
        <Select {...selectProps} />
      </div>
    )
  );
};
