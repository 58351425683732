import './index.scss';
import upIcon from '../../assets/upIcon.png';
import downIcon from '../../assets/downIcon.png';

export const SortableTableHeaders = ({ headerGroups }) => {
  const renderSortIcon = (column) => {
    if (!column.isSorted) return;
    if (column.isSortedDesc) return <img className="sort-icon" src={downIcon} />;

    return <img className="sort-icon" src={upIcon} />;
  };

  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
              {column.render('Header')}
              <span>{renderSortIcon(column)}</span>
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};
