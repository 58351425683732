import React from 'react';
import './index.scss';
import { Dropdown } from 'react-bootstrap';
import { ThreeDotsVertical } from 'react-bootstrap-icons';

const DropMenu = ({ listItems, parameter, className }) => {
  return (
    <Dropdown className={className} onClick={(event) => event.stopPropagation()}>
      <Dropdown.Toggle
        as={ThreeDotsVertical}
        variant="success"
        id="dropdown-dots"
        className="pointer"
        title="dropdown"
      ></Dropdown.Toggle>

      <Dropdown.Menu show={false}>
        {listItems?.map((item) => (
          <Dropdown.Item
            onClick={(_) => {
              item.action(parameter);
            }}
            key={item.title}
          >
            {item.title}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropMenu;
