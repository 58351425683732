import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Button } from '../../../../components/button';
import { leaveAccount } from '../../../../utils/api';
import { fireErrorToast, fireSuccessToast } from '../../../../utils/alert';
import { AuthContextConsumer } from '../../../../contexts/authContext';

export const LeaveAccount = ({ toggleModalVisibility }) => {
  const [loading, setLoading] = useState(false);

  const { userProfile, getUserProfile, retreiveAccountUsers } = AuthContextConsumer();

  const submitLeaveAccount = async () => {
    try {
      setLoading(true);
      await leaveAccount();
      await getUserProfile();
      await retreiveAccountUsers();
      fireSuccessToast('Successfully left account.');
      toggleModalVisibility();
    } catch (err) {
      fireErrorToast();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <div className="invite-to-case-form standard-modal">
          <Form>
            <div className="mb-5">
              If you leave this account you will no longer be able to access the {userProfile.activeCases} cases
              associated with this account and you will need to start a new account to continue using the LitX platform.
              Please confirm this is what you'd like to do.
            </div>

            <Row className="modal-buttons__container">
              <Button onClick={() => toggleModalVisibility('')} color="light" label={'Cancel'} />
              <Button className="btn-primary" loading={loading} onClick={submitLeaveAccount} label={'Leave Account'} />
            </Row>
          </Form>
        </div>
      </Col>
    </Row>
  );
};
