import { v4 as uuid } from 'uuid';
import { daysToMs } from '../../../utils/dates';

export class ChatMessage {
  constructor({ text, isUser = true, createdAt = new Date(), id = uuid() }) {
    this.id = id;
    this.text = text;
    this.isUser = isUser;
    this.createdAt = createdAt;
  }

  static fromRawMsg(rawMsg) {
    return new ChatMessage({
      ...rawMsg,
      text: rawMsg.message,
      createdAt: new Date(rawMsg.createdAt),
    });
  }
}

export class Chat {
  constructor(messages = [], id = uuid()) {
    this.id = id;
    this.messages = messages;
  }

  get lastMessage() {
    return this.messages.slice(-1)?.[0];
  }

  get updatedAt() {
    return this.lastMessage?.createdAt || new Date();
  }

  get title() {
    return this.messages?.[0]?.text || 'New Chat';
  }

  get isEmpty() {
    return this.messages.length === 0;
  }

  push(message) {
    this.messages = [...this.messages, message];
  }
}

export class GroupedChats {
  constructor() {
    this._chats = {
      Today: [],
      Yesterday: [],
      'Last 7 days': [],
      'Last 30 days': [],
      // plus Month and Year groups
    };
  }

  group() {
    const chats = [];

    for (let [sectionName, sectionChats] of Object.entries(this._chats)) {
      sectionName = sectionName.startsWith('_') ? sectionName.slice(1) : sectionName;
      if (sectionChats?.length) chats.push({ sectionName, sectionChats });
    }

    return chats;
  }

  add(chat) {
    const date = chat.updatedAt;
    const dateStr = date.toISOString().slice(0, 10);
    const todayStr = new Date().toISOString().slice(0, 10);
    const oneDayMs = daysToMs(1);
    const yesterdayStr = new Date(Date.now() - oneDayMs).toISOString().slice(0, 10);
    const diffInDays = (new Date().getTime() - date.getTime()) / oneDayMs;
    const isSameYear = dateStr.slice(0, 4) === todayStr.slice(0, 4);

    if (dateStr === todayStr) this._chats.Today.push(chat);
    else if (dateStr === yesterdayStr) this._chats.Yesterday.push(chat);
    else if (diffInDays <= 7) this._chats['Last 7 days'].push(chat);
    else if (diffInDays <= 30) this._chats['Last 30 days'].push(chat);
    else if (!isSameYear) {
      // forces the key to be a string so the insertion order is kept in the object
      const year = `_${date.getFullYear()}`;
      if (year in this._chats) this._chats[year].push(chat);
      else this._chats[year] = [chat];
    } else {
      const monthName = date.toLocaleString('default', { month: 'long' });
      if (monthName in this._chats) this._chats[monthName].push(chat);
      else this._chats[monthName] = [chat];
    }
  }
}

export class ResponseLoading {
  constructor(msgIdsWaitingForResponse = {}) {
    this.data = { ...msgIdsWaitingForResponse };
  }

  add(chatId) {
    this.data[chatId] = true;

    return this.data;
  }

  remove(chatId) {
    delete this.data[chatId];

    return this.data;
  }

  isLoading(chatId) {
    return this.data[chatId];
  }
}
