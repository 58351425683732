import { Form, Row } from 'react-bootstrap';
import { CancelOrAcceptCluster } from '../../../../components/cancelOrAcceptCluster';
import { TextField } from '../../../../components/textField';
import { EmailField } from '../../../../components/emailField';
import { isEmptyLabel } from '../../../../utils/string';

export const AddUserToAccountForm = ({
  inviteData: { firstName, lastName, emailAddress, companyName, note },
  onChange,
  toggleModalVisibility,
  isLoading,
  onSubmit,
}) => {
  const areRequiredFieldsFilled =
    !isEmptyLabel(firstName) && !isEmptyLabel(lastName) && !isEmptyLabel(emailAddress) && !isEmptyLabel(companyName);

  return (
    <Form>
      <Row className="mb-3">
        <TextField
          value={firstName}
          onChange={(value) => onChange((prev) => ({ ...prev, firstName: value }))}
          label="First Name *"
          id="formFirstName"
        />

        <TextField
          value={lastName}
          onChange={(value) => onChange((prev) => ({ ...prev, lastName: value }))}
          label="Last Name *"
          id="formLastName"
        />
      </Row>

      <Row className="mb-3">
        <EmailField
          value={emailAddress}
          onChange={(value) => onChange((prev) => ({ ...prev, emailAddress: value }))}
          label="Email Address *"
        />

        <TextField
          value={companyName}
          onChange={(value) => onChange((prev) => ({ ...prev, companyName: value }))}
          label="Company Name *"
          id="formCompanyName"
        />
      </Row>

      <Row className="mb-3">
        <TextField
          value={note}
          onChange={(value) => onChange((prev) => ({ ...prev, note: value }))}
          label="Personal note"
          id="note"
        />
      </Row>

      <Row className="modal-buttons__container">
        <CancelOrAcceptCluster
          onCancelClick={toggleModalVisibility}
          isLoading={isLoading}
          onAcceptClick={onSubmit}
          isDisabled={!areRequiredFieldsFilled}
          acceptLabel={'Invite to Account'}
        />
      </Row>
    </Form>
  );
};
