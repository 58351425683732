import { CasesContextConsumer } from '../../../contexts/casesContext';
import { fireErrorToast, fireSuccessToast } from '../../../utils/alert';
import { declineAccountMerge } from '../../../utils/api';
import { Boxes } from 'react-bootstrap-icons';
import { useState } from 'react';
import { MergeAccountsModal } from '../../modals/modal_content/mergeAccounts/modal';
import { RequestCard } from './requestCard';

export const AccountMergeInvites = () => {
  const { pendingRequests, getPendingRequests } = CasesContextConsumer();

  const [isMergeAccountsModalVisible, setIsMergeAccountsModalVisible] = useState(false);
  const toggleMergeAccountsModal = () => setIsMergeAccountsModalVisible(!isMergeAccountsModalVisible);

  const submitDeclineAccountMerge = async (requestId) => {
    try {
      await declineAccountMerge({ inviteId: requestId });
      await getPendingRequests();
      fireSuccessToast('You have declined the invite.');
    } catch (error) {
      fireErrorToast();
    }
  };

  return pendingRequests.accountMergeRequests?.map((request) => {
    const inviterFullName = `${request.userLogin.userProfile.firstName} ${request.userLogin.userProfile.lastName}`;
    return (
      <div key={request.id}>
        <RequestCard
          createdAt={request.createdAt}
          header={'Invited to Merge Accounts'}
          title={'Merge Accounts Invitation'}
          inviterFullName={inviterFullName}
          icon={<Boxes />}
          action={'Merge Accounts'}
          onApproveClick={toggleMergeAccountsModal}
          onDeclineClick={() => submitDeclineAccountMerge(request.id)}
        />
        <MergeAccountsModal
          inviteId={request.id}
          isVisible={isMergeAccountsModalVisible}
          toggleModalVisibility={toggleMergeAccountsModal}
        />
      </div>
    );
  });
};
