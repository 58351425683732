import { useContext } from 'react';
import { AIContext } from '../../../contexts/aiContext';
import { CreateMessageSVG } from '../../svg/createMessage';

export const Header = () => {
  const { createNewChat } = useContext(AIContext);

  return (
    <div className="ai-chat__header">
      <span> New Chat </span>

      <CreateMessageSVG className="ai-chat__header_new_chat_button" onClick={createNewChat} />
    </div>
  );
};
