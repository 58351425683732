import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Button } from '../../../../components/button';
import Row from 'react-bootstrap/Row';
import { fireErrorToast, fireSuccessToast } from '../../../../utils/alert';
import { updatePayment } from '../../../../utils/api';

export const UpdatePayment = ({ toggleModalVisibility, setPaymentMethod }) => {
  const [isPaymentLoading, setPaymentLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const isButtonDisabled = !stripe || !elements || isPaymentLoading;

  const handleUpdatePayment = async () => {
    try {
      setPaymentLoading(true);

      const cardElement = elements?.getElement(CardElement);
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      const subscriptionInfo = await updatePayment({ paymentMethodId: paymentMethod.id });
      setPaymentMethod(subscriptionInfo);

      fireSuccessToast('Payment method updated');
    } catch (err) {
      console.error(err);
      fireErrorToast('Error updating payment method');
    } finally {
      setPaymentLoading(false);
      toggleModalVisibility();
    }
  };

  return (
    <>
      <div className="mb-3">Enter your payment details below.</div>
      <CardElement />
      <div className="invite-to-case-form standard-modal">
        <Row className="modal-buttons__container justify-content-center mt-5">
          <Button label={'Cancel'} onClick={() => toggleModalVisibility('')} />
          <Button label={'Update'} onClick={handleUpdatePayment} isDisabled={isButtonDisabled} />
        </Row>
      </div>
    </>
  );
};
