import upIcon from '../../assets/upIcon.png';
import downIcon from '../../assets/downIcon.png';

export const TableHeaderRow = ({ headerGroup }) => {
  const renderIconImg = (isSorted, isSortedDesc) => {
    if (isSortedDesc) return <img className="sort-icon" src={downIcon} />;
    if (isSorted) return <img className="sort-icon" src={upIcon} />;
  };

  return (
    <tr {...headerGroup.getHeaderGroupProps()}>
      {headerGroup.headers.map((column) => (
        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
          {column.render('Header')}
          <span>{renderIconImg(column.isSorted, column.isSortedDesc)}</span>
        </th>
      ))}
    </tr>
  );
};
