export const AccountSettingsRow = ({
  leftCol,
  middleCol = '',
  rightCol = '',
  middleColClassName = 'middle-col',
  rightColClassName = 'right-col pointer',
  onClick = () => {},
}) => (
  <div className="subscription__row">
    <div className="left-col">
      <p>{leftCol}</p>
    </div>
    <div className={middleColClassName}>
      <p>{middleCol}</p>
    </div>
    <div onClick={onClick} className={rightColClassName}>
      {rightCol}
    </div>
  </div>
);
