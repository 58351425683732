import './index.scss';
import React, { useEffect, useState } from 'react';
import CaseInviteSent from '../caseInviteSent/caseInviteSent';
import ResendInvite from '../resendInvite';
import { InviteUserToMyParty } from '../inviteUserToMyParty';
import { InviteFromMyAccountToCase } from '../inviteFromMyAccountToCase';
import { InviteOtherPartyToCase } from '../inviteOtherPartyToCase';
import { inviteUserToCase } from '../../../../utils/api';
import { fireErrorToast, fireSuccessToast } from '../../../../utils/alert';
import { caseRoles } from '../../../../utils/defaults';
import { InvitablePartysSelect } from './invitablePartysSelect';
import { Col, Row } from 'react-bootstrap';
import { componentViews } from '../../../../utils/enums';

const InviteUserToCase = ({
  caseDetails,
  caseParties,
  initialComponent,
  selectedParty,
  toggleModalVisibility,
  userProfile,
  accountUsers,
  getCaseDetails,
}) => {
  const [partySelection, setPartySelection] = useState(selectedParty);
  const [currentComponent, setCurrentComponent] = useState(initialComponent);
  const [inviteId, setInviteId] = useState('');
  const [invitee, setInvitee] = useState({ name: '', emailAddress: '' });
  const [isLoading, setIsLoading] = useState(false);

  const hasDependantUsers = accountUsers?.length > 1;

  const sendInvite = async (inviteData) => {
    try {
      setIsLoading(true);
      await inviteUserToCase(inviteData);
      fireSuccessToast('Invitation sent successfully.');
      await getCaseDetails();
      toggleModalVisibility();
    } catch (err) {
      fireErrorToast('We had a problem creating the invitation. Try again later or contact support.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setPartySelection(selectedParty);
  }, [selectedParty]);

  const options = caseRoles.map((role) => ({
    name: role,
    value: role,
  }));

  const handleSelectInitialParty = (event) => {
    const parties = caseParties.plaintiff.concat(caseParties.defendant);
    const partySelected = parties.find((party) => party.partyName === event.target.value);
    partySelected.partyId = partySelected.id;

    setPartySelection(partySelected);
    if (caseDetails.myParty.partyName !== partySelected.partyName) {
      setCurrentComponent('InviteOtherPartyToCase');
    } else {
      setCurrentComponent('InviteFromMyAccountToCase');
    }
  };

  const InitialSelect = () => (
    <InvitablePartysSelect
      caseParties={caseParties}
      myPartyId={caseDetails.myParty.partyId}
      onChange={handleSelectInitialParty}
    />
  );

  const FormScreens = {
    InitialSelect,
    InviteUserToMyParty,
    InviteOtherPartyToCase,
    InviteFromMyAccountToCase: hasDependantUsers ? InviteFromMyAccountToCase : InviteUserToMyParty,
    CaseInviteSent,
    ResendInvite,
  };

  const closeInviteUserToParty = () => {
    if (hasDependantUsers) setCurrentComponent(componentViews.INVITE_FROM_MY_ACCOUNT_TO_CASE);

    toggleModalVisibility();
  };

  const Component = FormScreens[currentComponent];
  return (
    <Row>
      <Col xs={12} lg={12} xl={12}>
        <div className="standard-modal">
          <Component
            {...{
              caseDetails,
              setCurrentComponent,
              caseParties,
              partySelection,
              selectedParty,
              toggleModalVisibility,
              setInviteId,
              inviteId,
              userProfile,
              accountUsers,
              setInvitee,
              invitee,
              sendInvite,
              isLoading,
              options,
              hasDependantUsers,
              onAcceptClick: sendInvite,
              onCancelClick: closeInviteUserToParty,
              onBackClick: closeInviteUserToParty,
            }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default InviteUserToCase;
