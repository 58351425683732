import Swal from 'sweetalert2';
import { renderToString } from 'react-dom/server';

export const alertDefaults = {
  confirmButtonColor: '#003732',
  background: '#ECEAE4',
  customClass: 'speziaserif',
};

export const Toast = Swal.mixin({
  toast: true,
  iconColor: 'black',
  position: 'top',
  customClass: {
    popup: 'colored-toast',
  },
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
});

export const authToast = Swal.mixin({
  toast: true,
  iconColor: 'black',
  position: 'top',
  customClass: {
    popup: 'colored-toast',
  },
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: false,
});

export const fireErrorToast = (message = 'Something went wrong. Please try again later or contact LitX support.') => {
  Toast.fire({
    icon: 'error',
    text: message,
  });
};

export const fireSuccessToast = (message = '') => {
  authToast.fire({
    icon: 'success',
    text: message,
  });
};

export const fireValidationMessages = (errors = []) => {
  const html = renderToString(
    <>
      {errors.map((error) => (
        <div key={error}> {error} </div>
      ))}
    </>
  );

  Swal.fire({
    ...alertDefaults,
    title: 'Error',
    html,
  });
};

export const fireProcessingFileErrors = (
  err,
  defaultMessage = 'Something went wrong... Try again or contact support.'
) => {
  const msg = err.message;

  const isAlreadyExistError = msg.includes('already exist');
  const isSimultaneousUserError = msg.includes('Court case files are being updated');

  if (isAlreadyExistError || isSimultaneousUserError) fireErrorToast(msg);
  else if (defaultMessage) fireErrorToast(defaultMessage);

  throw Error;
};
