export const TableBodyRow = ({ row, prepareRow }) => {
  prepareRow(row);
  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => {
        return (
          <td {...cell.getCellProps()}>
            <div className="avatar"></div>
            <div className="cell-info">{cell.render('Cell')}</div>
          </td>
        );
      })}
    </tr>
  );
};
