import React, { useState } from 'react';
import { AddPhoneModal } from '../../../components/modals/modal_content/addPhone/modal';
import { AuthContextConsumer } from '../../../contexts/authContext';
import { ProfileRow } from './profileRow';
import { EditProfileModal } from '../../../components/modals/modal_content/editProfile/modal';
import { ProfileAddNameModal } from '../../../components/modals/modal_content/profileAddName/modal';
import { EditEmailAddressModal } from '../../../components/modals/modal_content/editEmailAddress/modal';
import { formatCamelCaseToString, formatPhoneNumber } from '../../../utils/string';

const ProfileSettings = () => {
  const [currentModalField, setCurrentModalField] = useState('');

  const [displayPhoneModal, setDisplayPhoneModal] = useState(false);
  const toggleDisplayAddPhoneModal = () => setDisplayPhoneModal(!displayPhoneModal);

  const [displayEditProfileModal, setDisplayEditProfileModal] = useState(false);
  const toggleDisplayEditProfileModal = () => setDisplayEditProfileModal(!displayEditProfileModal);

  const [displayAddNameModal, setDisplayAddNameModal] = useState(false);
  const toggleDisplayAddNameModal = () => setDisplayAddNameModal(!displayAddNameModal);

  const [displayEditEmailAddressModal, setDisplayEditEmailAddressModal] = useState(false);
  const toggleDisplayEditEmailAddressModal = () => setDisplayEditEmailAddressModal(!displayEditEmailAddressModal);

  const { userProfile } = AuthContextConsumer();

  const profileSettingsRows = [
    'name',
    'company',
    'title',
    'emailAddress',
    'secondaryEmail',
    'phone',
    'fax',
    'streetAddress',
    'city',
    'state',
    'zipCode',
  ];

  const modalHeaders = {
    phone: 'Mobile Phone',
    emailAddress: 'Email',
  };

  const displayEditOrAdd = (field) => {
    if (field === 'name') userProfile['firstName'] ? 'Edit' : 'Add';
    return userProfile[field] ? 'Edit ' : 'Add ';
  };

  const displayHeader = (field) => {
    const formattedField = modalHeaders[field] ? modalHeaders[field] : formatCamelCaseToString(field);
    return userProfile[field] ? `Edit ${formattedField}` : `Add ${formattedField}`;
  };

  return (
    <div className="account-settings">
      <div className="subscription col-xs-12 col-lg-6 mb-5">
        {profileSettingsRows.map((field) => {
          const middleCol = (() => {
            if (field === 'name') return `${userProfile['firstName']} ${userProfile['lastName']}`;
            if (field === 'phone' || field === 'fax') return formatPhoneNumber(userProfile[field]);
            return userProfile[field];
          })();

          return (
            <ProfileRow
              key={field}
              leftCol={modalHeaders[field] ? modalHeaders[field] : formatCamelCaseToString(field)}
              middleCol={middleCol}
              linkLabel={displayEditOrAdd(field)}
              onClick={() => {
                setCurrentModalField(field);
                if (field === 'phone' || field === 'fax') return toggleDisplayAddPhoneModal();
                if (field === 'emailAddress') return toggleDisplayEditEmailAddressModal();
                if (field === 'name') return toggleDisplayAddNameModal();
                toggleDisplayEditProfileModal();
              }}
            />
          );
        })}

        <EditProfileModal
          isVisible={displayEditProfileModal}
          currentModalField={currentModalField}
          toggleModalVisibility={toggleDisplayEditProfileModal}
          headerCopy={displayHeader(currentModalField)}
        />
        <EditEmailAddressModal
          isVisible={displayEditEmailAddressModal}
          toggleModalVisibility={toggleDisplayEditEmailAddressModal}
        />
        <ProfileAddNameModal
          isVisible={displayAddNameModal}
          toggleModalVisibility={toggleDisplayAddNameModal}
          headerCopy={`${userProfile.firstName ? 'Edit' : 'Add'} Name`}
        />
        <AddPhoneModal
          isVisible={displayPhoneModal}
          toggleModalVisibility={toggleDisplayAddPhoneModal}
          savedPhone={userProfile.phone || ''}
          headerCopy={`${userProfile.phone ? 'Edit' : 'Add'} your ${
            currentModalField === 'fax' ? 'fax' : 'mobile phone'
          }`}
          currentModalField={currentModalField}
        />
      </div>
    </div>
  );
};

export default ProfileSettings;
