import 'react-select-search/style.css';
import './index.scss';

import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fireErrorToast, fireSuccessToast } from '../utils/alert';
import { useLocation } from 'react-router-dom';
import { useModal } from '../hooks/useModal';
import { sendCloseCaseResponse, sendClawbackResponse, mergeAccount } from '../utils/api';
import { CasesContextConsumer } from '../contexts/casesContext';
import { inviteType } from '../utils/constants';
import { AuthContextConsumer } from '../contexts/authContext';
import { useQueryParams } from '../hooks/useQueryParams';

const InnerLayout = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [openModalId, toggleModalVisibility] = useModal('');
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const { inviteTypeParam, inviteId, responseId, status, pendingAccountRequestId, requestId } = useQueryParams();

  const { getUserProfile } = AuthContextConsumer();
  const { getPendingRequests, getCurrentCases, currentCases } = CasesContextConsumer();

  const approveAccountMerge = async () => {
    try {
      await mergeAccount({ inviteId: pendingAccountRequestId });
      await Promise.all([getUserProfile(), getPendingRequests()]);
      if (currentCases.length > 0) return navigate('/dashboard');
      navigate('/dashboard-welcome');
      fireSuccessToast('Your account has been merged successfully.');
    } catch (error) {
      fireErrorToast();
    }
  };

  const approveClawback = async () => {
    try {
      await sendClawbackResponse({ responseId, status });
      fireSuccessToast('The clawback request has been approved.');
    } catch (error) {
      fireErrorToast();
    }
  };

  const approveClosure = async () => {
    try {
      await sendCloseCaseResponse({
        responseId: searchParams.get('requestId'),
        status: searchParams.get('response'),
      });
      await Promise.all([getCurrentCases(), getPendingRequests()]);
      fireSuccessToast('The case closure request has been approved.');
    } catch (error) {
      if (error.response.status === 400) return fireErrorToast('The case is already closed.');
      fireErrorToast();
    }
  };

  const location = useLocation();

  const whiteBackgroundRoutes = ['/dashboard', '/approvals', '/invitations', '/document-history'];

  useEffect(() => {
    if (whiteBackgroundRoutes.includes(location.pathname)) {
      document.body.style.backgroundColor = 'white';
    } else {
      document.body.style.backgroundColor = '#ECEAE4';
    }
  }, [location.pathname]);

  useEffect(() => {
    if (pendingAccountRequestId && inviteTypeParam === inviteType.mergeAccount) {
      return approveAccountMerge();
    }
    if (responseId && inviteTypeParam === inviteType.clawback) {
      return approveClawback();
    }
    if (requestId && inviteTypeParam === inviteType.closure) {
      return approveClosure();
    }
  }, [inviteId, requestId, responseId, pendingAccountRequestId]);

  return (
    <div className="container-xl">
      <Header {...{ menuOpen, setMenuOpen, openModalId, toggleModalVisibility }} />

      <div id="inner-layout">{children}</div>
    </div>
  );
};

export default InnerLayout;
