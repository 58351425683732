import './index.scss';
import React, { useEffect, useState } from 'react';
import InvitationsTable from './invitationsTable';
import { fetchInvitations } from '../../utils/api';
import { fireErrorToast } from '../../utils/alert';

const Invitations = () => {
  const [invitations, setInvitations] = useState({ receivedInvites: [], sentInvites: [] });

  const getInvitations = async () => {
    try {
      const invitationResult = await fetchInvitations();
      setInvitations(invitationResult);
    } catch (err) {
      fireErrorToast('Error fetching invitations. Please try again.');
    }
  };

  useEffect(() => {
    getInvitations();
  }, []);

  return (
    <div className="invitations">
      <div className="litx-table">
        <div className="litx-table__title mb-3">Invitations</div>
        <InvitationsTable {...{ invitations, getInvitations }} />
      </div>
    </div>
  );
};

export default Invitations;
