import './index.scss';
import Logo from '../../logo_light.svg';
import React from 'react';
import { RegistrationForm } from './registrationForm';

const RegistrationView = () => {
  return (
    <div className="registration__registration-container">
      <div className="registration">
        <div className="logo">
          <a href="/">
            <img src={Logo} alt="" />
          </a>
          <h1 className="logoheadline">Legal exchange simplified.</h1>
        </div>
        <RegistrationForm title="Sign up for a free account." />
      </div>
    </div>
  );
};

export default RegistrationView;
