import React from 'react';
import { Upload, FileText, Folder } from 'react-bootstrap-icons';

export const CaseFilesButton = ({ onClick }) => (
  <div className="controls__button" onClick={onClick}>
    <FileText />
    <span>Case Files</span>
  </div>
);

export const AddFolderButton = ({ onClick }) => (
  <div className="controls__button" onClick={onClick}>
    <Folder />
    Add Folder
  </div>
);

export const UploadButton = ({ onClick }) => (
  <div className="controls__button" onClick={onClick}>
    <Upload />
    <span>Upload</span>
  </div>
);

export const ControlSeparator = () => <span className="controls__separator" />;
