import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Button } from '../../../button';
import { fireProcessingFileErrors } from '../../../../utils/alert';
import { cleanS3FileName } from '../../../../utils/s3';

const AddFolder = ({ toggleModalVisibility, onAddFolder }) => {
  const [folderName, setFolderName] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFolderNameChange = (event) => {
    const value = event.target.value || '';
    const safeValue = cleanS3FileName(value);

    setFolderName(safeValue);
  };

  const handleAddFolder = async () => {
    setLoading(true);

    try {
      await onAddFolder(folderName);
    } catch (err) {
      fireProcessingFileErrors(err, 'Something went wrong creating your folder. Try again or contact support');
    } finally {
      setLoading(false);
    }
  };

  const handleEnter = (e) => {
    if (e.key !== 'Enter' || !folderName) return;

    e.preventDefault();
    handleAddFolder();
  };

  return (
    <Row>
      <Col xs={12} lg={12}>
        <div className="add-folder-form standard-modal">
          <Form>
            <Row className="mb-5">
              <Form.Group as={Col} xs={12} md={12} controlId="formFolderName">
                <Form.Label className="required-input">Folder Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="folderName"
                  onChange={handleFolderNameChange}
                  onKeyDown={handleEnter}
                  value={folderName}
                />
              </Form.Group>
            </Row>

            <Row className="modal-buttons__container">
              <Button color="light" onClick={toggleModalVisibility} label="Cancel" />
              <Button
                color="primary"
                isDisabled={!folderName}
                onClick={handleAddFolder}
                label="Add folder"
                loading={loading}
              />
            </Row>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default AddFolder;
