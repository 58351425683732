import './styles.scss';
import React from 'react';
import { Search } from './search';
import { CaseFilesCount } from './caseFilesCount';
import { FilesConsumer } from '../../../contexts/filesContext';
import { CaseFilesButton, ControlSeparator, UploadButton, AddFolderButton } from './buttons';
import { SelectedFilesCount } from '../../selectedFilesCount';

export const Controls = ({
  showUploadArea,
  onFilesClick,
  onUploadClick,
  onAddFolderClick,
  searchTerm,
  onSearchChange,
}) => {
  const { uploadedFiles, selectedPathObjs, clearSelectedPathObjs } = FilesConsumer();

  const renderSelectedFilesMenu = () => {
    if (selectedPathObjs?.length === 0) return;

    return (
      <>
        <ControlSeparator />
        <SelectedFilesCount count={selectedPathObjs.length} className="controls__selected_files_count" />
      </>
    );
  };

  const renderFilesSectionControls = () => (
    <>
      <UploadButton onClick={onUploadClick} />
      <ControlSeparator />
      <AddFolderButton onClick={onAddFolderClick} />

      {renderSelectedFilesMenu()}
    </>
  );

  const routeControls = () => {
    if (showUploadArea) return <CaseFilesButton onClick={onFilesClick} />;
    return renderFilesSectionControls();
  };

  return (
    <div className="controls__container">
      <div className="controls__buttons-container" onClick={clearSelectedPathObjs}>
        <CaseFilesCount count={uploadedFiles?.filter((file) => file.type !== 'folder').length} />
        {routeControls()}
      </div>

      {!showUploadArea && <Search value={searchTerm} onChange={onSearchChange} />}
    </div>
  );
};
