import './index.scss';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { phoneDigits } from '../../utils/constants';

export const PhoneField = ({
  id,
  value,
  label,
  onChange,
  isDisabled = false,
  onKeyDown = () => {},
  classnames = { label: '', input: '' },
  isPassword = false,
  placeholder = '',
  prefix = '+1',
  ...containerProps
}) => {
  const handleChange = (event) => {
    const validChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    const newPhone = event.target.value || '';
    const parsedLength = newPhone.substr(0, phoneDigits);
    const parsedPhone = [...parsedLength].filter((char) => validChars.includes(char)).join('');

    onChange(parsedPhone);
  };

  return (
    <Form.Group as={Col} {...containerProps}>
      <div className="input-phone-container">
        <Form.Label className={classnames.label} htmlFor={id}>
          {label}
        </Form.Label>
        <Form.Control
          id={id}
          className={`input-phone ${classnames.input}`}
          disabled={isDisabled}
          type={isPassword ? 'password' : 'text'}
          value={value}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
        ></Form.Control>
        <span className="country-code-prefix"> {prefix} </span>
      </div>
    </Form.Group>
  );
};
