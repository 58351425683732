import React from 'react';
import Form from 'react-bootstrap/Form';
import { Search as SearchIcon } from 'react-bootstrap-icons';

export const Search = ({ value, onChange }) => (
  <div className="controls__search-container">
    <Form.Control type="search" placeholder="Search Files" onChange={(event) => onChange(event.target.value)} />
    {!value && <SearchIcon className="controls__search-icon" />}
  </div>
);
