import { useState } from 'react';
import { Tooltip } from '../tooltip';
import clawbackIcon from '../../assets/Clawback_icon.png';

export const ClawbackIcon = ({ clawbackResponses }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const toggleTooltip = () => setShowTooltip(!showTooltip);

  const adminNames = clawbackResponses
    .map((response) => {
      return response.userLogin.userProfile.firstName + ' ' + response.userLogin.userProfile.lastName;
    })
    .join(', ');

  const textContent = `This document is pending a Clawback deletion. The following case admins must approve this Clawback: ${adminNames}`;

  return (
    <>
      <img
        src={clawbackIcon}
        onMouseEnter={toggleTooltip}
        onMouseLeave={toggleTooltip}
        className="file-icon"
        alt="clawback icon"
      />
      <Tooltip showTooltip={showTooltip} textContent={textContent} />
    </>
  );
};
