import React from 'react';
import BaseModal from '../../base_modal';
import { EditEmailAddress } from './editEmailAddress';

export const EditEmailAddressModal = ({ isVisible, toggleModalVisibility }) => (
  <BaseModal
    isVisible={isVisible}
    toggleVisibility={toggleModalVisibility}
    modalContent={<EditEmailAddress toggleModalVisibility={toggleModalVisibility} />}
    headerCopy="Edit Email"
  />
);
