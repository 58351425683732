import React from 'react';
import DropMenu from '../../components/dropdownMenu';

export const CaseActionsDropdown = ({
  isAdmin,
  toggleAddNewParty,
  toggleEditCase,
  toggleCloseCase,
  toggleLeaveCase,
}) => {
  const generalAction = { key: 2, title: 'Leave Case', action: toggleLeaveCase };

  const adminActions = [
    {
      key: 0,
      title: 'Add a New Party',
      action: toggleAddNewParty,
    },
    { key: 1, title: 'Edit Case', action: toggleEditCase },
    generalAction,
    { key: 3, title: 'Close Case', action: toggleCloseCase },
  ];

  const actions = isAdmin ? adminActions : [generalAction];

  return <DropMenu listItems={actions} />;
};
