import './index.scss';
import React, { useEffect, useState } from 'react';
import { AddUserToAccountModal } from './addUserToAccountModal';
import { AuthContextConsumer } from '../../../contexts/authContext';
import { getSubscription } from '../../../utils/api';
import { fireErrorToast } from '../../../utils/alert';
import { SubscriptionInformation } from './subscriptionInformation';
import { CancelPlanModal } from './cancelPlan';
import { UpdatePaymentModal } from './updatePayment';
import { RemoveUserModal } from './removeUser';
import { LeaveAccountModal } from './leaveAccount';
import { AccountInfo } from './accountInfo';
import { UsersOnPlan } from './usersOnPlan';

const AccountSettings = () => {
  const [billingInfo, setBillingInfo] = useState('');
  const [selectedUser, setSelectedUser] = useState();

  const [showAddUserToAccountModal, setShowAddUserToAccountModal] = useState(false);
  const toggleAddUserToAccountModal = () => setShowAddUserToAccountModal(!showAddUserToAccountModal);

  const [showCancelPlanModal, setShowCancelPlanModal] = useState(false);
  const toggleCancelPlanModal = () => setShowCancelPlanModal(!showCancelPlanModal);

  const [showUpdatePaymentModal, setShowUpdatePaymentModal] = useState(false);
  const toggleUpdatePaymentModal = () => setShowUpdatePaymentModal(!showUpdatePaymentModal);

  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
  const toggleRemoveUserModal = () => setShowRemoveUserModal(!showRemoveUserModal);

  const [showLeaveAccountModal, setShowLeaveAccountModal] = useState(false);
  const toggleLeaveAccountModal = () => setShowLeaveAccountModal(!showLeaveAccountModal);

  const { userProfile } = AuthContextConsumer();

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        if (!userProfile.isAccountOwner) return;

        const subscriptionInfo = await getSubscription();
        console.log('\n\n', subscriptionInfo, '\n\n');
        setBillingInfo(subscriptionInfo);
      } catch (err) {
        if (err.status === 402) return;
        fireErrorToast('Failed to fetch subscription information');
      }
    };

    fetchSubscription();
  }, [userProfile]);

  const handleRemoveUser = (user) => {
    setSelectedUser(user);
    toggleRemoveUserModal();
  };

  const renderModals = () => {
    return (
      <>
        <AddUserToAccountModal
          isVisible={showAddUserToAccountModal}
          toggleModalVisibility={toggleAddUserToAccountModal}
        />

        <CancelPlanModal isVisible={showCancelPlanModal} toggleModalVisibility={toggleCancelPlanModal} />

        <UpdatePaymentModal
          toggleModalVisibility={toggleUpdatePaymentModal}
          isVisible={showUpdatePaymentModal}
          setPaymentMethod={setBillingInfo}
        />

        <RemoveUserModal
          toggleModalVisibility={toggleRemoveUserModal}
          isVisible={showRemoveUserModal}
          selectedUser={selectedUser}
        />

        <LeaveAccountModal toggleModalVisibility={toggleLeaveAccountModal} isVisible={showLeaveAccountModal} />
      </>
    );
  };

  return (
    <div className="account-settings">
      <SubscriptionInformation
        toggleCancelPlanModal={toggleCancelPlanModal}
        toggleUpdatePaymentModal={toggleUpdatePaymentModal}
        userProfile={userProfile}
        subscriptionAmounts={billingInfo.subscriptionAmounts}
      />

      <AccountInfo
        isAccountOwner={userProfile.isAccountOwner}
        managedBy={userProfile.managedBy}
        paymentMethodInfo={billingInfo.paymentMethodInfo}
        toggleUpdatePaymentModal={toggleUpdatePaymentModal}
        toggleLeaveAccountModal={toggleLeaveAccountModal}
        isSubscribed={userProfile.isSubscribed}
      />
      <UsersOnPlan
        userProfile={userProfile}
        toggleAddUserToAccountModal={toggleAddUserToAccountModal}
        handleRemoveUser={handleRemoveUser}
      />

      {renderModals()}
    </div>
  );
};

export default AccountSettings;
