export const componentViews = {
  INVITE_USER_TO_MY_PARTY: 'InviteUserToMyParty',
  INVITE_FROM_MY_ACCOUNT_TO_CASE: 'InviteFromMyAccountToCase',
  INVITE_OTHER_PARTY_TO_CASE: 'InviteOtherPartyToCase',
};

export const caseType = {
  plaintiff: 'plaintiff',
  defendant: 'defendant',
  neutral: 'neutral',
};

export const partyName = {
  plaintiff: 'Plantiff',
  defendant: 'Defendant',
  neutral: 'Neutral',
};

export const partyRepRoles = {
  ADMIN: 'admin',
  REP: 'rep',
};

export const embeddingStatuses = {
  PENDING: 'pending',
  DONE: 'done',
  FAILED: 'failed',
  NOT_SUPPORTED: 'not_supported',
  EMPTY_DOCUMENT: 'empty_document',
  NO_TEXT: 'no_text',
};
