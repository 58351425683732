import { Col, Form, Row } from 'react-bootstrap';
import { Partys } from './partys';

export const InvitablePartysSelect = ({ caseParties, myPartyId, onChange }) => (
  <Form>
    <Form.Group xs={12} md={12} className="" controlId="partySelect">
      <Form.Label className="required-input">Invitee represent the party</Form.Label>
      <Form.Select className="mb-5" name="party" required onChange={(event) => onChange(event)}>
        <option>Please Select</option>
        <Partys partyList={caseParties.defendant} myPartyId={myPartyId} />
        <Partys partyList={caseParties.plaintiff} myPartyId={myPartyId} />
      </Form.Select>
    </Form.Group>
  </Form>
);
