import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { AuthContextConsumer } from '../../../../contexts/authContext';
import { cancelSubscription } from '../../../../utils/api';
import { fireErrorToast, fireSuccessToast } from '../../../../utils/alert';
import { CancelOrAcceptCluster } from '../../../../components/cancelOrAcceptCluster';

export const CancelPlan = ({ toggleModalVisibility }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { getUserProfile, userProfile } = AuthContextConsumer();

  const submitCancelSubscription = async () => {
    setIsLoading(true);
    try {
      await cancelSubscription();
      await getUserProfile();
      fireSuccessToast('Subscription cancelled successfully.');
    } catch (error) {
      fireErrorToast('Error cancelling subscription. Please try again.');
    } finally {
      setIsLoading(false);
      toggleModalVisibility();
    }
  };

  const CancelInitial = () => (
    <Row className="mb-5">
      <p>
        Yourself and all users on your plan will be cancelled and no longer have access to your cases on LitX. Are you
        sure you want to cancel? Your membership and access will end immediately.
      </p>
      <br />
      <br />
      {userProfile.freeDaysLeft > 0 && (
        <p>
          You have {userProfile.freeDaysLeft + ' '}
          days left in your free trial. You will still have access to your account for the remainder of you free trial.
        </p>
      )}
    </Row>
  );

  // const CancelFinal = () => (
  //   <>
  //     <Row className="mb-2">
  //       <span>We’re sorry to see you go. Would you tell us why you’re leaving?</span>
  //     </Row>
  //     <Row className="mb-2">
  //       <Form.Check type="radio" aria-label="text" name="cancelReason">
  //         <Form.Check.Input
  //           type="radio"
  //           name="cancelReason"
  //           className="radio-select cancel-select"
  //           value="Professional change"
  //           onChange={(event) => handleReasonSelect(event.target.value)}
  //         />
  //         <Form.Check.Label className="cancel-text">Professional change</Form.Check.Label>
  //       </Form.Check>
  //     </Row>
  //     <Row className="mb-2">
  //       <Form.Check type="radio" aria-label="text" name="cancelReason">
  //         <Form.Check.Input
  //           type="radio"
  //           name="cancelReason"
  //           className="radio-select cancel-select"
  //           value="I wasn’t satisfied with the service"
  //           onChange={(event) => handleReasonSelect(event.target.value)}
  //         />
  //         <Form.Check.Label className="cancel-text">I wasn’t satisfied with the service</Form.Check.Label>
  //       </Form.Check>
  //     </Row>
  //     <Row className="mb-2">
  //       <Form.Check type="radio" aria-label="text" name="cancelReason">
  //         <Form.Check.Input
  //           type="radio"
  //           name="cancelReason"
  //           className="radio-select cancel-select"
  //           value="I found another product I liked better"
  //           onChange={(event) => handleReasonSelect(event.target.value)}
  //         />
  //         <Form.Check.Label className="cancel-text">I found another product I liked better</Form.Check.Label>
  //       </Form.Check>
  //     </Row>
  //     <Row className="mb-2">
  //       <Form.Check type="radio" aria-label="text" name="cancelReason">
  //         <Form.Check.Input
  //           type="radio"
  //           name="cancelReason"
  //           className="radio-select cancel-select"
  //           value="Other"
  //           onChange={(event) => handleReasonSelect(event.target.value)}
  //         />
  //         <Form.Check.Label className="cancel-text">Other</Form.Check.Label>
  //       </Form.Check>
  //     </Row>
  //   </>
  // );

  // const chooseButton = () => {
  //   if (initialScreen) {
  //     return (
  //       <Button variant="primary" onClick={() => setInitialcreen(false)}>
  //         Continue
  //       </Button>
  //     );
  //   }
  //   return (
  //     <Button variant="primary" onClick={submitCancelSubscription}>
  //       Cancel Plan
  //     </Button>
  //   );
  // };

  return (
    <Row>
      <Col xs={12}>
        <div className="invite-to-case-form standard-modal">
          <Form>
            <CancelInitial />
            <CancelOrAcceptCluster
              onAcceptClick={submitCancelSubscription}
              onCancelClick={toggleModalVisibility}
              isDisabled={isLoading}
              acceptLabel={isLoading ? 'Cancelling...' : 'Cancel Plan'}
            />
          </Form>
        </div>
      </Col>
    </Row>
  );
};
