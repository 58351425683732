import { Oval } from 'react-loader-spinner';
import Badge from 'react-bootstrap/Badge';
import './styles.scss';

export const Rep = ({ isLoading, photoUrl, firstName, lastName, avatarStyles, role, isPartyOwner, job, isPending }) => {
  if (isLoading)
    return (
      <Oval
        height={40}
        width={80}
        color="#DFE4EF"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#00AFAA"
        strokeWidth={4}
        strokeWidthSecondary={4}
      />
    );

  const renderAvatar = () => {
    if (photoUrl) return <img className="avatar" id="userAvatar" src={photoUrl} />;

    return (
      <div className="avatar" style={avatarStyles}>
        {firstName[0] + lastName[0]}
      </div>
    );
  };

  return (
    <>
      <div className="ms-3">
        <div className={`icons-wrapper ${isPending && 'party-panels__pending-rep'}`}>{renderAvatar()}</div>
      </div>
      <div className={`ms-3 ${isPending && 'party-panels__pending-rep'}`}>
        <div className="user__name mb-1">
          {`${firstName} ${lastName}`}
          {role === 'admin' && <Badge className="admin-badge">ADMIN</Badge>}
          {isPartyOwner && <Badge className="admin-badge">ACCOUNT</Badge>}
          {isPending && <Badge className="admin-badge">PENDING</Badge>}
        </div>
        <div className="user__title">{job}</div>
      </div>
    </>
  );
};
