import './index.scss';
import React, { useState } from 'react';
import AccountSettings from './account/index';
import ProfileSettings from './profile';
import TabContent from '../../components/tabs/tabContent';
import NotificationSettings from './notifications';
import SecuritySettings from './security/security';

const settingsTabs = [
  {
    label: 'Profile',
    key: 'profile',
    component: ProfileSettings,
  },
  {
    label: 'Security',
    key: 'security',
    component: SecuritySettings,
  },
  {
    label: 'Account',
    key: 'account',
    component: AccountSettings,
  },
  {
    label: 'Notifications',
    key: 'notifications',
    component: NotificationSettings,
  },
];

const SettingsLayout = () => {
  const [currentTab, setCurrentTab] = useState('profile');

  return (
    <div className="settings">
      <div className="settings__container">
        <div className="settings__title">Settings</div>
        <div className="settings__tab-row mb-3">
          {settingsTabs.map((tab) => (
            <div
              className={`settings__tab pointer ${currentTab === tab.key ? 'active' : ''}`}
              onClick={() => setCurrentTab(tab.key)}
              key={tab.key}
            >
              {tab.label}
            </div>
          ))}
        </div>
        {settingsTabs.map((tab) => {
          const Component = tab.component || null;

          return (
            <TabContent className="mt-5" id={tab.key} {...{ currentTab }} key={tab.key}>
              <Component />
            </TabContent>
          );
        })}
      </div>
    </div>
  );
};

export default SettingsLayout;
