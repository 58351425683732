import React from 'react';
import BaseModal from '../../base_modal';
import RemoveEmptyFolder from './removeEmptyFolder';

export const RemoveEmptyFolderModal = ({ isVisible, toggleModalVisibility }) => (
  <BaseModal
    isVisible={isVisible}
    toggleVisibility={toggleModalVisibility}
    modalContent={<RemoveEmptyFolder toggleModalVisibility={toggleModalVisibility} />}
    headerCopy="Remove empty folder"
    wide={false}
  />
);
