import React, { useEffect, useRef } from 'react';

export const TextArea = ({ value, onChange, className, placeholder, ...props }) => {
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) return;

    ref.current.style.height = `0`;
    ref.current.style.height = `${ref.current.scrollHeight + 2}px`;
  }, [value, onChange, ref.current]);

  return (
    <textarea
      className={`.textarea__ ${className}`}
      ref={ref}
      value={value}
      onChange={(event) => onChange(event.target.value)}
      placeholder={placeholder}
      {...props}
    />
  );
};
