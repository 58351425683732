import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import Logo from '../../../LitxLogo.png';
import { useNavigate } from 'react-router-dom';
import { AuthContextConsumer } from '../../../contexts/authContext';
import invitationsIcon from '../../../assets/invitationsIcon.png';
import settingsIcon from '../../../assets/settingsIcon.png';
import homeIcon from '../../../assets/homeIcon.png';
import helpIcon from '../../../assets/helpIcon.png';

const menuList = [
  {
    name: 'Cases',
    logo: homeIcon,
    link: '/dashboard',
  },
  {
    name: 'Invitations',
    logo: invitationsIcon,
    link: '/invitations',
  },
  {
    name: 'Settings',
    logo: settingsIcon,
    link: '/settings',
  },
  {
    name: 'Help',
    logo: helpIcon,
    link: 'http://help.litx.legal',
  },

  {
    name: 'Sign Out',
    logo: '',
    link: '',
  },
];

const NavigationModal = ({ isVisible, toggleModalVisibility }) => {
  const { logout, userProfile } = AuthContextConsumer();
  const navigate = useNavigate();

  const handleNavigation = ({ name, link }) => {
    toggleModalVisibility('');

    if (name === 'Sign Out') return logout();

    // Check if link is external or not
    if (link.startsWith('http://') || link.startsWith('https://')) {
      // External link - Open in new tab
      window.open(link, '_blank');
    } else {
      // Internal link - Use react-router's navigate
      navigate(link);
    }
  };

  const menuItems = menuList.flatMap((menu) => {
    if (menu.name === 'Approvals' && userProfile.activeAdminCases?.length > 1) {
      return [];
    }
    return menu;
  });

  const modal = (
    <>
      <div className="backdrop" onClick={() => toggleModalVisibility('')} />
      <div className="navigation-modal" aria-modal aria-label="Modal Details" role="dialog">
        <div className="navigation-modal__sidebar">
          <div>
            <img width={87} height={95} src={Logo} alt="" />
          </div>
          <span className="modal-close" aria-label="Close Modal Details" onClick={toggleModalVisibility}>
            &times;
          </span>
          <div className="nav-wrap mt-5">
            {menuItems.map((menuItem) => (
              <div className="nav-list-item" key={menuItem.name}>
                <span onClick={() => handleNavigation(menuItem)}>
                  {menuItem.logo && <img src={menuItem.logo} className="menu-icon" alt="Logo" />}
                  {menuItem.name}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );

  return isVisible ? ReactDOM.createPortal(modal, document.body) : null;
};

export default NavigationModal;
