import Form from 'react-bootstrap/Form';

export const CheckBox = ({ isDisplayed = true, isChecked, onChange, title, name, label, className = '' }) => {
  return (
    isDisplayed && (
      <Form.Group className={className}>
        <Form.Check
          id={title}
          type={'checkbox'}
          label={label}
          name={name}
          title={title}
          checked={isChecked}
          onChange={() => onChange(!isChecked)}
        />
      </Form.Group>
    )
  );
};
