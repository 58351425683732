import React from 'react';
import BaseModal from '../../base_modal';
import AddNewFolder from './addNewFolder';

export const AddNewFolderModal = ({ isVisible, toggleModalVisibility, onAddFolder }) => (
  <BaseModal
    isVisible={isVisible}
    toggleVisibility={toggleModalVisibility}
    modalContent={<AddNewFolder onAddFolder={onAddFolder} toggleModalVisibility={toggleModalVisibility} />}
    headerCopy="Add folder"
    wide={false}
  />
);
