import React from 'react';
import BaseModal from '../../base_modal';
import ClawBackRequest from './clawbackRequest';

export const ClawBackRequestModal = ({ isVisible, toggleModalVisibility, caseDetails, clawbackFile }) => (
  <BaseModal
    isVisible={isVisible}
    toggleVisibility={toggleModalVisibility}
    modalContent={
      <ClawBackRequest
        toggleModalVisibility={toggleModalVisibility}
        caseDetails={caseDetails}
        clawbackFile={clawbackFile}
      />
    }
  />
);
