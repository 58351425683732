import { useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Search } from 'react-bootstrap-icons';
import { submitSearchCases } from '../../utils/api';
import { CasesContextConsumer } from '../../contexts/casesContext';
import { Spinner } from 'react-bootstrap';
import { fireErrorToast } from '../../utils/alert';

export const CaseSearch = ({ selectCaseDisplay }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const { getCurrentCases, setCurrentCases } = CasesContextConsumer();

  const debouncedTimout = useRef(null);

  const handleInputSearch = (value) => {
    setSearchTerm(value);
    if (debouncedTimout.current) clearTimeout(debouncedTimout.current);
    debouncedTimout.current = setTimeout(async () => searchCases(value), 300);
  };

  const searchCases = async (searchTerm) => {
    if (!searchTerm) return getCurrentCases({ dependant: selectCaseDisplay });

    try {
      setIsLoading(true);
      const searchResult = await submitSearchCases(new URLSearchParams({ searchTerm, dependant: selectCaseDisplay }));
      setCurrentCases(searchResult);
    } catch (e) {
      fireErrorToast('Error searching cases please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="d-flex justify-content-end cases-search">
      <Spinner
        animation="border"
        variant="primary"
        className="search-spinner"
        style={{ display: isLoading ? 'block' : 'none', height: '20px', width: '20px' }}
      />
      <div style={{ position: 'relative' }}>
        <Form.Control
          type="search"
          placeholder="Search Cases"
          value={searchTerm}
          onChange={(event) => handleInputSearch(event.target.value)}
        />
        <Search className="search-svg" style={{ display: searchTerm ? 'none' : 'block' }} />
      </div>
    </div>
  );
};
