import './App.scss';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Loading from './routes/Loading';
import Registration from './routes/registration';
import { SubscribeView } from './routes/subscribe';
import SettingsLayout from './routes/settings';
import InvitedRegistration from './routes/invited_registration';
import DashboardWelcome from './routes/dashboard_welcome';
import CreateCase from './routes/create_case';
import CaseDetailLayout from './routes/case_detail';
import Invitations from './routes/invitations';
import ProtectedRoute from './routes/protectedRoute';
import DashboardMulticase from './routes/dashboard_multicase';
import AuthWrapper from './routes/auth/authWrapper';
import InnerLayout from './layouts/innerLayout';
import OnboardingLayout from './layouts/onboardingLayout';
import ResetPassword from './routes/reset_pass/resetPassword';
import ForgotPassword from './routes/reset_pass/forgotPassword';
import { PaywallCheck } from './components/paywallCheck';
import { AuthProvider } from './contexts/authContext';
import { CasesProvider } from './contexts/casesContext';
import { LoginPage } from './routes/login';
import { VerificationPage } from './routes/verification';

const App = () => (
  <Router>
    <Routes>
      <Route
        path="/verify"
        element={
          <AuthProvider>
            <VerificationPage />
          </AuthProvider>
        }
      />

      <Route
        path="/login"
        element={
          <AuthProvider>
            <LoginPage />
          </AuthProvider>
        }
      />

      <Route
        path="/"
        element={<Navigate replace to={window.localStorage.getItem('litx-auth') ? '/dashboard' : '/login'} />}
      />

      <Route path="/register" element={<Registration />} />

      <Route
        path="/forgot-password"
        element={
          <AuthWrapper>
            <ForgotPassword />
          </AuthWrapper>
        }
      />

      <Route
        path="/reset-password"
        element={
          <AuthWrapper>
            <ResetPassword />
          </AuthWrapper>
        }
      />

      <Route
        path="/invited-registration"
        element={
          <OnboardingLayout>
            <InvitedRegistration />
          </OnboardingLayout>
        }
      />

      {/* Protected Routes */}
      <Route element={<ProtectedRoute />}>
        <Route
          path="/case-detail"
          element={
            <AuthProvider>
              <CasesProvider>
                <InnerLayout>
                  <CaseDetailLayout />
                </InnerLayout>
              </CasesProvider>
            </AuthProvider>
          }
        />

        <Route
          path="/merge-accounts"
          element={
            <AuthProvider>
              <CasesProvider>
                <InnerLayout>
                  <DashboardWelcome />
                </InnerLayout>
              </CasesProvider>
            </AuthProvider>
          }
        />

        <Route
          path="/invitations"
          element={
            <AuthProvider>
              <CasesProvider>
                <InnerLayout>
                  <Invitations />
                </InnerLayout>
              </CasesProvider>
            </AuthProvider>
          }
        />

        <Route
          path="/create-case"
          element={
            <AuthProvider>
              <CasesProvider>
                <PaywallCheck>
                  <InnerLayout>
                    <CreateCase />
                  </InnerLayout>
                </PaywallCheck>
              </CasesProvider>
            </AuthProvider>
          }
        />

        <Route
          path="/dashboard-welcome"
          element={
            <AuthProvider>
              <CasesProvider>
                <InnerLayout>
                  <DashboardWelcome />
                </InnerLayout>
              </CasesProvider>
            </AuthProvider>
          }
        />

        <Route
          path="/settings"
          element={
            <AuthProvider>
              <CasesProvider>
                <InnerLayout>
                  <SettingsLayout />
                </InnerLayout>
              </CasesProvider>
            </AuthProvider>
          }
        />

        <Route
          path="/subscribe"
          element={
            <AuthProvider>
              <CasesProvider>
                <OnboardingLayout>
                  <SubscribeView />
                </OnboardingLayout>
              </CasesProvider>
            </AuthProvider>
          }
        />

        <Route
          path="/dashboard"
          element={
            <AuthProvider>
              <CasesProvider>
                <InnerLayout>
                  <DashboardMulticase />
                </InnerLayout>
              </CasesProvider>
            </AuthProvider>
          }
        />

        <Route
          path="/loading"
          element={
            <CasesProvider>
              <InnerLayout>
                <Loading />
              </InnerLayout>
            </CasesProvider>
          }
        />
      </Route>
    </Routes>
  </Router>
);

export default App;
