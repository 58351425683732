import { AuthContextConsumer } from '../../../contexts/authContext';

export const UsersOnPlan = ({ toggleAddUserToAccountModal, handleRemoveUser }) => {
  const { userProfile, accountUsers } = AuthContextConsumer();

  const renderUserRow = () =>
    accountUsers?.map((user) => (
      <div className="users__row" key={user.id}>
        <div className="left-col">{user.name}</div>
        <div className="middle-col">{user.permission}</div>
        <div className="right-col">
          {!user.isPending && user.id !== userProfile.id && (
            <a className="right-col pointer" onClick={() => handleRemoveUser(user)}>
              Remove user
            </a>
          )}
        </div>
      </div>
    ));

  return (
    userProfile.isAccountOwner && (
      <div className="users col-xs-12 col-lg-6 mb-5">
        <div className="users__row">
          <div className="title left-col">
            <p>Users on plan</p>
          </div>
          <div className="title middle-col">
            <p>Permissions</p>
          </div>
          <a className="title right-col pointer" onClick={toggleAddUserToAccountModal}>
            + Add user to account
          </a>
        </div>
        {renderUserRow()}
      </div>
    )
  );
};
