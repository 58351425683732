export class PathObject {
  constructor(object) {
    this._raw = object;
    this.id = object.id;
    this.createdAt = object.createdAt;
    this.name = object.fileName;
    this.type = object.fileType;
    this.url = object.fileURL;
    this.caseId = object.courtCaseId;
    this.uploadedBy = object.uploadedBy;
    this.embeddingStatus = object.embeddingStatus;
  }

  get isFile() {
    return !this._raw.folder;
  }

  get isFolder() {
    return !this.isFile;
  }

  get path() {
    // Returns the path object without the court case
    // in the format of folder/.../name
    if (!this.url.startsWith(this.caseId)) return null;

    return this.url.replace(this.caseId + '/', '');
  }

  get isPathInvalid() {
    return this.path === null;
  }

  get lastViewedBy() {
    return this._raw.fileHistories?.[0]?.userName;
  }

  isInCurrentPath(currentPath = '') {
    // current path uses the format folder/.../folder/
    // or "" for the root court case level
    if (this.isPathInvalid || !this.path.startsWith(currentPath)) return false;

    const restOfPath = this.path.replace(currentPath, '');
    return restOfPath === this.name;
  }

  get extension() {
    if (this.isFolder) return;

    return '.' + this.name.split('.').pop();
  }
}

export class PathManager {
  constructor(flatList, currentPath) {
    this._flatList = flatList;
    this.currentPath = currentPath;
  }

  list() {
    return this._flatList.filter((pathObject) => pathObject.isInCurrentPath(this.currentPath));
  }

  search(text) {
    return this._flatList.filter(
      (pathObject) => pathObject.isFile && !pathObject.isPathInvalid && pathObject.name.includes(text)
    );
  }

  isEmptyFolder(pathObj) {
    if (pathObj.isFile) return false;

    const currentPath = `${pathObj.path}/`;
    const nestedPathObjs = this._flatList.filter(
      (otherPathObj) => otherPathObj.id !== pathObj.id && otherPathObj.isInCurrentPath(currentPath)
    );

    return nestedPathObjs.length === 0;
  }
}
