import React from 'react';
import BaseModal from '../../base_modal';
import { CloseCase } from './closeCase';

export const CloseCaseModal = ({ isVisible, toggleModalVisibility, caseDetails, caseAdmins }) => (
  <BaseModal
    isVisible={isVisible}
    toggleVisibility={toggleModalVisibility}
    modalContent={
      <CloseCase caseDetails={caseDetails} toggleModalVisibility={toggleModalVisibility} caseAdmins={caseAdmins} />
    }
    headerCopy="Close case"
    wide={true}
  />
);
