import { Button } from '../../../components/button';
import { AccountSettingsRow } from './accountSettingsRow';

export const AccountInfo = ({
  isAccountOwner,
  managedBy,
  paymentMethodInfo,
  toggleUpdatePaymentModal,
  toggleLeaveAccountModal,
  isSubscribed,
}) => {
  const showPaymentMethod = () => {
    if (isAccountOwner) {
      const formatedPaymentMethod = paymentMethodInfo
        ? `    ${paymentMethodInfo?.brand} ending in ${paymentMethodInfo?.last4}, exp: ${paymentMethodInfo?.expDate}`
        : '';

      return (
        <AccountSettingsRow
          leftCol="Payment method:"
          middleCol={formatedPaymentMethod}
          rightCol="Update"
          onClick={toggleUpdatePaymentModal}
        />
      );
    }

    return (
      <>
        <AccountSettingsRow leftCol="Account managed by" middleCol={managedBy} />
        <div className="d-flex justify-content-end mt-3">
          <Button label="Leave Account" onClick={toggleLeaveAccountModal} color="primary" />
        </div>
      </>
    );
  };

  return (
    (!isAccountOwner || isSubscribed) && (
      <div className="subscription col-xs-12 col-lg-6 mb-5">
        <div className="subscription__row">
          <div className="title">Account Information</div>
        </div>
        {showPaymentMethod()}
      </div>
    )
  );
};
