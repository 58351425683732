import React from 'react';
import BaseModal from '../../base_modal';
import { ChangePassword } from './changePassword';

export const ChangePasswordModal = ({ isVisible, toggleModalVisibility, headerCopy = 'Change Password' }) => (
  <BaseModal
    isVisible={isVisible}
    toggleVisibility={toggleModalVisibility}
    modalContent={<ChangePassword toggleModalVisibility={toggleModalVisibility} />}
    headerCopy={headerCopy}
  />
);
