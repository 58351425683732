import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AuthContextConsumer } from '../../../../contexts/authContext';
import { fireErrorToast, fireSuccessToast } from '../../../../utils/alert';
import { addUserToAccount } from '../../../../utils/api';
import { AddUserToAccountForm } from './addUserToAccountForm';
import { MergeAccountModalView } from './mergeAccountModalView';

export const AddUserToAccount = ({ toggleModalVisibility }) => {
  const { userProfile } = AuthContextConsumer();

  const [inviteData, setInviteData] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    companyName: userProfile?.company || '',
    note: '',
    needsMerge: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const inviteUserToAccount = async () => {
    if (!userProfile.isAccountOwner) return fireErrorToast('You need to be an account owner to invite users');

    setIsLoading(true);
    try {
      await addUserToAccount(inviteData);
      fireSuccessToast('User has successfully been invited to your account');
      toggleModalVisibility('');
    } catch (error) {
      switch (error.status) {
        case 403:
          return fireErrorToast('User with this email address is already invited');
        case 409: {
          fireErrorToast(
            'User with this email address already has a LitX account. Please send an account merge invitation to add them to your account.'
          );
          return setInviteData((prevState) => ({ ...prevState, needsMerge: true }));
        }
        case 422:
          return fireErrorToast('User is already linked to an account');
        default:
          return fireErrorToast();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const renderModalView = () => {
    if (inviteData.needsMerge) {
      return (
        <MergeAccountModalView
          toggleModalVisibility={toggleModalVisibility}
          isLoading={isLoading}
          onSubmit={inviteUserToAccount}
        />
      );
    }

    return (
      <AddUserToAccountForm
        inviteData={inviteData}
        onChange={setInviteData}
        toggleModalVisibility={toggleModalVisibility}
        isLoading={isLoading}
        onSubmit={inviteUserToAccount}
      />
    );
  };

  return (
    <Row>
      <Col xs={12}>
        <div className="invite-user-form standard-modal">{renderModalView()}</div>
      </Col>
    </Row>
  );
};
