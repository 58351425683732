import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import dayjs from 'dayjs';
import { CaseActionsDropdown } from './caseActionsDropdown';

export const CaseDetailsInfo = ({
  caseDetails,
  handleInviteToCaseClick,
  isAdmin,
  toggleAddNewParty,
  toggleCloseCase,
  toggleEditCase,
  toggleLeaveCase,
}) => {
  const createdAt = dayjs(caseDetails?.createdAt || new Date()).format('MM/DD/YYYY');

  return (
    <Col lg={4}>
      <div className="case-title mb-3">{caseDetails.caseName}</div>
      <div className="case-number">Cause No: {caseDetails.causeNo}</div>
      <div className="case-location mb-3">{caseDetails.courtName}</div>
      <div className="case-created">LitX Case Created: {createdAt}</div>
      <div className="case-type mb-3">Type of Case: {caseDetails?.caseType}</div>
      <div className="cta-row">
        <div>
          <Button className="invite-case" onClick={handleInviteToCaseClick}>
            Invite to Case
          </Button>
        </div>
        <div className="case-action">
          <CaseActionsDropdown
            isAdmin={isAdmin}
            toggleAddNewParty={toggleAddNewParty}
            toggleCloseCase={toggleCloseCase}
            toggleEditCase={toggleEditCase}
            toggleLeaveCase={toggleLeaveCase}
          />
        </div>
      </div>
    </Col>
  );
};
