import './index.scss';
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import BaseModal from '../../../components/modals/base_modal';
import { StripeForm } from './form';
import { stripeKey } from '../../../utils/env';

const stripePromise = loadStripe(stripeKey);

export const StripeModal = ({ toggleModalVisibility, isVisible, trialUserInfo }) => {
  const modalContent = (
    <Elements stripe={stripePromise}>
      <StripeForm toggleModalVisibility={toggleModalVisibility} trialUserInfo={trialUserInfo} />
    </Elements>
  );

  return (
    <BaseModal
      isVisible={isVisible}
      toggleVisibility={toggleModalVisibility}
      modalContent={modalContent}
      headerCopy="Enter your payment information to start your subscription"
      wide={true}
    />
  );
};
