import BaseModal from '../../../../components/modals/base_modal';
import { LeaveAccount } from './leaveAccount';

export const LeaveAccountModal = ({ toggleModalVisibility, isVisible }) => {
  return (
    <BaseModal
      isVisible={isVisible}
      toggleVisibility={toggleModalVisibility}
      modalContent={<LeaveAccount toggleModalVisibility={toggleModalVisibility} />}
      headerCopy="Leave account"
      wide={false}
    />
  );
};
