import React from 'react';
import BaseModal from '../../base_modal';
import InviteUserToCase from './inviteUserToCase';

export const InviteUserToCaseModal = ({
  isVisible,
  toggleModalVisibility,
  caseDetails,
  caseParties,
  selectedParty,
  initialComponent,
  userProfile,
  accountUsers,
  getCaseDetails,
}) => (
  <BaseModal
    isVisible={isVisible}
    toggleVisibility={toggleModalVisibility}
    modalContent={
      <InviteUserToCase
        caseDetails={caseDetails}
        caseParties={caseParties}
        selectedParty={selectedParty}
        initialComponent={initialComponent}
        toggleModalVisibility={toggleModalVisibility}
        userProfile={userProfile}
        accountUsers={accountUsers}
        getCaseDetails={getCaseDetails}
      />
    }
    headerCopy="Invite to Case"
  />
);
