import dayjs from 'dayjs';
import { useState } from 'react';

export const RequestCard = ({
  createdAt,
  header,
  title,
  inviterFullName,
  icon,
  action,
  onApproveClick,
  onDeclineClick,
  requestType = 'Invited',
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (callBack) => {
    setIsLoading(true);
    try {
      await callBack();
    } finally {
      setIsLoading(false);
    }
  };

  const renderButtonRow = () =>
    !isLoading ? (
      <div className="case-request-action-row">
        <button onClick={() => handleClick(onApproveClick)} className={'pointer'}>
          {action}
        </button>
        <button onClick={() => handleClick(onDeclineClick)} className={'pointer'}>
          Decline
        </button>
      </div>
    ) : (
      <div className="case-request-action-row">
        <p>Loading...</p>
      </div>
    );

  return (
    <div className="requests-dropdown__card">
      <div className="request-header">{header}</div>
      <div className="request-case-title mb-2">
        {icon}
        &nbsp; {title}
      </div>
      <div className="case-info-list">
        <span>
          <strong>{`${requestType} by: ${inviterFullName}`}</strong>
        </span>
        <span>{`${requestType} on: ${dayjs(createdAt).format('MM/DD/YYYY')}`}</span>
      </div>
      {renderButtonRow()}
    </div>
  );
};
