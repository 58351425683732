import React, { useEffect, useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { subscribe } from '../../../utils/api';
import { useNavigate } from 'react-router-dom';
import { fireErrorToast, fireSuccessToast } from '../../../utils/alert';
import { AuthContextConsumer } from '../../../contexts/authContext';
import { CasesContextConsumer } from '../../../contexts/casesContext';
import { marketingPrices } from '../../../utils/constants';

export const StripeForm = ({ toggleModalVisibility, trialUserInfo }) => {
  const navigate = useNavigate();

  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [isCardFilled, setIsCardFilled] = useState(false);

  const { userProfile, getUserProfile } = AuthContextConsumer();
  const { currentCases, getCurrentCases } = CasesContextConsumer();

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    const cardElement = elements?.getElement(CardElement);
    if (!cardElement) return;

    cardElement.on('change', (event) => {
      if (!event.complete) setIsCardFilled(false);
      else setIsCardFilled(true);
    });
  }, [!!elements]);

  const handleStartSubscription = async () => {
    setPaymentLoading(true);

    try {
      const cardElement = elements.getElement(CardElement);
      const paymentMethodResponse = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (paymentMethodResponse.error) throw Error(paymentMethodResponse.error.message);

      await subscribe({
        ...trialUserInfo,
        paymentMethodId: paymentMethodResponse.paymentMethod.id,
        zip: parseInt(trialUserInfo.zip),
      });

      await Promise.all([getUserProfile(), getCurrentCases()]);

      fireSuccessToast('Started subscription');
      const navigateUrl = currentCases?.length ? '/dashboard' : '/dashboard-welcome';
      navigate(navigateUrl);
    } catch (error) {
      fireErrorToast('There was an error starting your subscription. Try again or contact support.');
    } finally {
      setPaymentLoading(false);
    }
  };

  const displayLoadingMessage = () => {
    if (isPaymentLoading)
      return (
        <>
          <h5>Payment has been submited, please wait while we process your information...</h5>
        </>
      );

    return (
      <>
        <ul>
          <li>Pay just {marketingPrices.SUBSCRIPTION_PRICE} per user per month, keep any created cases for life!</li>
          <li>You may cancel your subscription at any time.</li>
        </ul>
      </>
    );
  };

  return (
    <>
      <div className="mb-3">{displayLoadingMessage()}</div>
      <CardElement />
      <Row className="d-flex justify-content-center mb-5 mt-5">
        <Button variant="secondary mx-4" onClick={toggleModalVisibility} disabled={isPaymentLoading}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleStartSubscription} disabled={!isCardFilled || isPaymentLoading}>
          {isPaymentLoading ? 'Loading...' : 'Subscribe'}
        </Button>
      </Row>
    </>
  );
};
