import React, { createContext, useContext, useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePathManager } from '../hooks/usePathManager';
import { PathObject } from '../utils/files';
import { routes, sendGetRequest } from '../utils/api';
import { useQuery } from 'react-query';

export const FilesContext = createContext({});

export const FilesProvider = ({ children, caseDetails }) => {
  const [searchParams] = useSearchParams();
  const courtCaseId = searchParams.get('caseId');

  const [currentPath, setCurrentPath] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileNamesToUpload, setFileNamesToUpload] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const { results: filteredUploadedFiles, pathManager } = usePathManager(uploadedFiles, currentPath, searchTerm);
  const [clawbackFile, setClawbackFile] = useState(null);
  const [selectedPathObjs, setSelectedPathObjs] = useState([]);

  const refLastXDividerPosition = useRef(0);
  const leftPanelRef = useRef(null);
  const rightPanelRef = useRef(null);

  const { data, refetch: getUploadedFiles } = useQuery(
    ['caseFiles', courtCaseId],
    () => sendGetRequest(routes.files, { courtCaseId }),
    { refetchInterval: 10000 }
  );

  useEffect(() => {
    if (!data) return;

    const pathObjects = data.files?.map((file) => new PathObject(file));
    const withoutFilesAtRootBucket = pathObjects?.filter((pathObject) => !pathObject.isPathInvalid);
    // legacy files located outside the case folder
    setUploadedFiles(withoutFilesAtRootBucket);
  }, [data]);

  const clearSelectedPathObjs = useCallback(() => setSelectedPathObjs([]), []);
  const toggleSelectedFileId = useCallback((pathObj) => {
    setSelectedPathObjs((prevSelectedPathObjs) => {
      if (!prevSelectedPathObjs.find((selectedPathObj) => selectedPathObj.id === pathObj.id))
        return [...prevSelectedPathObjs, pathObj];

      return prevSelectedPathObjs.filter((selectedPathObj) => selectedPathObj.id !== pathObj.id);
    });
  }, []);
  const forceSingleSelectedFileId = useCallback((fileId) => setSelectedPathObjs([fileId]), []);

  const finalParams = useMemo(
    () => ({
      courtCaseId,
      currentPath,
      setCurrentPath,
      uploadedFiles,
      setUploadedFiles,
      fileNamesToUpload,
      setFileNamesToUpload,
      searchTerm,
      setSearchTerm,
      filteredUploadedFiles,
      clawbackFile,
      setClawbackFile,
      getUploadedFiles,
      selectedPathObjs,
      clearSelectedPathObjs,
      toggleSelectedFileId,
      forceSingleSelectedFileId,
      caseDetails,
      pathManager,
      refLastXDividerPosition,
      leftPanelRef,
      rightPanelRef,
    }),
    [
      clawbackFile,
      setClawbackFile,
      courtCaseId,
      currentPath,
      uploadedFiles,
      fileNamesToUpload,
      searchTerm,
      filteredUploadedFiles,
      getUploadedFiles,
      selectedPathObjs,
      clearSelectedPathObjs,
      toggleSelectedFileId,
      forceSingleSelectedFileId,
      caseDetails,
      pathManager,
      refLastXDividerPosition,
      leftPanelRef,
      rightPanelRef,
    ]
  );

  return <FilesContext.Provider value={finalParams}>{children}</FilesContext.Provider>;
};

export const FilesConsumer = () => useContext(FilesContext);
