export const clawbackStatus = {
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  CLOSED: 'CLOSED',
  PENDING: 'PENDING',
};

export const closeCaseStatus = {
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  CLOSED: 'CLOSED',
  PENDING: 'PENDING',
};

export const inviteType = {
  mergeAccount: 'mergeAccount',
  clawback: 'clawback',
  closure: 'closure',
};

export const twoFactorPreference = {
  email: 'email',
  sms: 'sms',
};

export const siteKey = '6Lew8gMnAAAAAKa6zgFLRfvixuW6psXtXRLpDzmZ';

export const marketingPrices = {
  SUBSCRIPTION_PRICE: '$20',
};

export const aiName = 'Alia';
export const casesPageSize = 10;
export const freeTrialDays = 180;

export const phoneDigits = 10;
