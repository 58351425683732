export const unsupportedS3Characters = [
  '&',
  '$',
  '@',
  '=',
  ';',
  '/',
  ':',
  '+',
  '?',
  '\\',
  '{',
  '^',
  '}',
  '%',
  '`',
  ']',
  '"',
  '>',
  '[',
  '~',
  '<',
  '#',
  '|',
];

export const cleanS3FileName = (name = '') => {
  return name
    .split('')
    .filter((char) => !unsupportedS3Characters.includes(char))
    .join('');
};
