import React from 'react';
import BaseModal from '../../base_modal';
import EditCase from './editCase';

export const EditCaseModal = ({ isVisible, toggleModalVisibility, caseDetails, getCaseDetails }) => (
  <BaseModal
    isVisible={isVisible}
    toggleVisibility={toggleModalVisibility}
    modalContent={
      <EditCase
        caseDetails={caseDetails}
        getCaseDetails={getCaseDetails}
        toggleModalVisibility={toggleModalVisibility}
      />
    }
    headerCopy="Edit case details"
    wide={true}
  />
);
