import BaseModal from '../../../../components/modals/base_modal';
import { RemoveUser } from './removeUser';

export const RemoveUserModal = ({ toggleModalVisibility, isVisible, selectedUser }) => {
  return (
    <BaseModal
      isVisible={isVisible}
      toggleVisibility={toggleModalVisibility}
      modalContent={<RemoveUser toggleModalVisibility={toggleModalVisibility} selectedUser={selectedUser} />}
      headerCopy="Remove from account"
      wide={false}
    />
  );
};
