import React from 'react';
import { FileText } from 'react-bootstrap-icons';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

const UploadRoadblock = ({ files, onUploadConfirm, onUploadCancel }) => (
  <div className="upload-roadblock standard-modal">
    <div className="mb-3">The following file(s) will be uploaded to the case:</div>
    {files?.map((file) => (
      <div className="mb-2" key={Math.random()}>
        <FileText />
        &nbsp;{file}
      </div>
    ))}

    <div className="mt-3">
      <strong>Verify before uploading</strong>
    </div>
    <div className="mb-4">
      Please ensure you’re uploading the correct documents. Once a document has been uploaded to a case it can only be
      removed through the clawback process.
    </div>

    <Row className="modal-buttons__container">
      <Button variant="primary" onClick={onUploadCancel}>
        Cancel
      </Button>

      <Button variant="primary" onClick={onUploadConfirm}>
        Upload
      </Button>
    </Row>
  </div>
);

export default UploadRoadblock;
