import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Panel from './Panel';

export const PartyPanels = ({
  userProfile,
  caseDetails,
  setInitialComponent,
  getCaseDetails,
  setSelectedParty,
  caseParties,
  toggleRemoveParty,
  toggleLeaveCase,
  toggleInviteUserToCase,
}) => {
  const renderPlantiffParties = () =>
    caseParties?.plaintiff.map((party) => (
      <Panel
        key={party.id}
        party={party}
        caseDetails={caseDetails}
        userProfile={userProfile}
        toggleInviteUserToCase={toggleInviteUserToCase}
        setInitialComponent={setInitialComponent}
        getCaseDetails={getCaseDetails}
        setSelectedParty={setSelectedParty}
        toggleRemoveParty={toggleRemoveParty}
        toggleLeaveCase={toggleLeaveCase}
      />
    ));

  const renderDefendantParties = () =>
    caseParties?.defendant.map((party) => (
      <Panel
        key={party.id}
        party={party}
        caseDetails={caseDetails}
        userProfile={userProfile}
        toggleInviteUserToCase={toggleInviteUserToCase}
        setInitialComponent={setInitialComponent}
        getCaseDetails={getCaseDetails}
        setSelectedParty={setSelectedParty}
        toggleRemoveParty={toggleRemoveParty}
        toggleLeaveCase={toggleLeaveCase}
      />
    ));

  const renderNeutralParty = () =>
    caseParties?.neutral && (
      <Row className="neutral-panel">
        <Col lg={6}>
          <div className="label mb-2">neutral</div>
          <Panel
            party={caseParties.neutral}
            caseDetails={caseDetails}
            userProfile={userProfile}
            toggleInviteUserToCase={toggleInviteUserToCase}
            setInitialComponent={setInitialComponent}
            getCaseDetails={getCaseDetails}
            setSelectedParty={setSelectedParty}
            toggleRemoveParty={toggleRemoveParty}
            toggleLeaveCase={toggleLeaveCase}
          />
        </Col>
      </Row>
    );

  return (
    <Col lg={8}>
      {renderNeutralParty()}

      <Row className="versus-panels">
        <Col lg={6}>
          <div className="label mb-2">plaintiff(s)/petitioners(s)</div>
          {renderPlantiffParties()}
        </Col>

        <Col lg={6}>
          <div className="label mb-2">defendant(s)/respondent(s)</div>
          {renderDefendantParties()}
        </Col>
      </Row>
    </Col>
  );
};
