import './index.scss';

import React from 'react';
import BaseModal from '../../base_modal';
import { Move } from './move';
import { FilesConsumer } from '../../../../contexts/filesContext';

export const MoveModal = ({ isVisible, toggleModalVisibility }) => {
  const { clearSelectedPathObjs } = FilesConsumer();

  const handleToggleModalVisibility = () => {
    clearSelectedPathObjs([]);
    toggleModalVisibility();
  };

  return (
    <BaseModal
      isVisible={isVisible}
      toggleVisibility={handleToggleModalVisibility}
      modalContent={<Move toggleModalVisibility={handleToggleModalVisibility} />}
      headerCopy="Move files"
    />
  );
};
