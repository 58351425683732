import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Button } from '../../../button';
import { FileEarmarkText } from 'react-bootstrap-icons';
import { inputHandler } from '../../../../utils/handlers';
import { filterCaseAdmins } from '../../../../utils/filterCaseAdmins';
import { requestClawback } from '../../../../utils/api';
import { fireErrorToast, fireSuccessToast } from '../../../../utils/alert';

const ClawBackRequest = ({ toggleModalVisibility, caseDetails, clawbackFile }) => {
  const [closeData, setCloseData] = useState({
    note: '',
    fileId: clawbackFile.id,
    requestingPartyId: caseDetails.myParty.partyId,
  });
  const [loading, setLoading] = useState(false);
  const caseAdmins = filterCaseAdmins(caseDetails.caseParties);

  const clawbackRequest = async () => {
    setLoading(true);
    try {
      const response = await requestClawback(closeData);
      fireSuccessToast(response.message);
      toggleModalVisibility();
    } catch (_) {
      fireErrorToast('Error sending clawback request');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <div className="invite-to-case-form standard-modal">
          <Form>
            <Row>
              <div className="close-case-name">
                <small>Document:</small>
                <br />
                <FileEarmarkText className="file-icon" /> {clawbackFile.name}
              </div>
              <div>
                A case admin for every party must agree to the clawback removal of this document. A clawback request
                will be sent to the following party admins:
                <span className="case-admins">
                  {caseAdmins.map(
                    (admin) => `${admin.userLogin.userProfile.firstName} ${admin.userLogin.userProfile.lastName}`
                  )}
                </span>
              </div>
            </Row>

            <Row className="mb-5">
              <Form.Group as={Col} controlId="note">
                <Form.Label>Personal note for the clawback email (Optional)</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  name="note"
                  onChange={(event) => inputHandler(event, setCloseData)}
                />
              </Form.Group>
            </Row>

            <Row className="modal-buttons__container">
              <Button onClick={toggleModalVisibility} color="light" label="Cancel" />
              <Button color="primary" loading={loading} onClick={clawbackRequest} label="Request Clawback" />
            </Row>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default ClawBackRequest;
