import './index.scss';
import GreenPartialLogo from '../../assets/litx_partial--green.svg';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { AuthContextConsumer } from '../../contexts/authContext';

const CreateCaseWelcome = ({ firstName }) => (
  <div className="create-case-welcome">
    <div className="create-case-welcome__title">Welcome {firstName}</div>
    <div className="create-case-welcome__subcopy mb-3">Begin by creating your first case</div>
    <Link to="/create-case">
      <Button variant="secondary">Create Case</Button>
    </Link>
  </div>
);

const DashboardWelcome = () => {
  const { userProfile } = AuthContextConsumer();

  return (
    <div className="dashboard-welcome">
      <CreateCaseWelcome firstName={userProfile.firstName} />
      <div className="dashboard-welcome__branding-image">
        <img src={GreenPartialLogo} alt="" />
      </div>
    </div>
  );
};

export default DashboardWelcome;
