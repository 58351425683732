import './index.scss';
import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { submitResetPassword } from '../../utils/api';
import { inputHandler } from '../../utils/handlers';
import { Button } from '../../components/button';
import { fireErrorToast, fireSuccessToast } from '../../utils/alert';
import { PasswordRequirements } from '../../components/passwordRequirements';

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const [resetData, setResetData] = useState({ password: '', token, confirmPassword: '' });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const isValidPasswords = () => {
    if (!resetData.password) {
      fireErrorToast('Please enter a password.');
      return false;
    }

    if (resetData.password !== resetData.confirmPassword) {
      fireErrorToast('Passwords do not match.');
      return false;
    }

    const passwordCheck = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
    if (!passwordCheck.test(resetData.password)) {
      fireErrorToast(
        'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
      );
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!isValidPasswords()) return;

    setLoading(true);
    try {
      await submitResetPassword(resetData);
      fireSuccessToast('Password updated successfully.');
      navigate('/login');
    } catch (err) {
      fireErrorToast('Error resetting password. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col xs={12} md={10}>
        <div className="login-form">
          <div className="login-form__title mb-3">Reset password</div>
          <Form className="mb-5" validated={false}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Enter a new password</Form.Label>
              <Form.Control type="password" name="password" onChange={(e) => inputHandler(e, setResetData)} />
            </Form.Group>
            <PasswordRequirements />

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Confirm your new password</Form.Label>
              <Form.Control type="password" name="confirmPassword" onChange={(e) => inputHandler(e, setResetData)} />
            </Form.Group>
            <div className="mt-4 mb-4">We'll email you a verification link, so you can create a new password.</div>
            <Button onClick={handleSubmit} className="w-100" color="primary" label="Reset password" loading={loading} />
          </Form>
          <div className="login-form__assistance-links">
            Back to&nbsp;&nbsp;
            <Link to="/login" className="password-reset-link">
              Sign in
            </Link>
          </div>
          {/* <div className="login-form__assistance-links mb-3">
            Have questions?&nbsp;&nbsp;
            <Link to="/" className="password-reset-link">
              Get help
            </Link>
          </div> */}
        </div>
      </Col>
    </Row>
  );
};

export default ResetPassword;
