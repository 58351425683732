import React from 'react';
import BaseModal from '../../base_modal';
import { FilesConsumer } from '../../../../contexts/filesContext';
import Rename from './rename';
import { sendPostRequest, routes } from '../../../../utils/api';

export const RenameModal = ({ isVisible, toggleModalVisibility }) => {
  const { selectedPathObjs, getUploadedFiles } = FilesConsumer();

  const pathObjToRename = selectedPathObjs[0];
  const type = pathObjToRename?.isFolder ? 'folder' : 'file';

  const handleRename = async (body) => {
    await sendPostRequest(routes.filesRename, body);
    await getUploadedFiles();
  };

  return (
    <BaseModal
      isVisible={isVisible}
      toggleVisibility={toggleModalVisibility}
      modalContent={
        <Rename
          toggleModalVisibility={toggleModalVisibility}
          pathObj={pathObjToRename}
          type={type}
          onRename={handleRename}
        />
      }
      headerCopy={`Rename ${type}`}
      wide={false}
    />
  );
};
