import React, { memo } from 'react';
import DropMenu from '../../../components/dropdownMenu';
import Swal from 'sweetalert2';
import { alertDefaults } from '../../../utils/alert';
import { useNavigate } from 'react-router-dom';
import { avatarBackgroundStyle } from '../../../utils/handlers';
import plusIcon from '../../../assets/plusIcon.png';
import { componentViews } from '../../../utils/enums';
import { removePartyFromCase } from '../../../utils/api';
import { Rep } from './Rep';

const Panel = ({
  party,
  caseDetails,
  userProfile,
  toggleInviteUserToCase,
  setInitialComponent,
  getCaseDetails,
  setSelectedParty,
  toggleRemoveParty,
  toggleLeaveCase,
}) => {
  const navigate = useNavigate();

  const myPartyReps = caseDetails.caseParties.find((party) => party.id === caseDetails?.myParty?.partyId);
  const pendingReps = (party?.pendingReps || []).map((rep) => ({ ...rep, job: rep.function, isPending: true }));
  const acceptedReps = (party?.partyReps || []).map((rep) => ({
    photoUrl: rep?.userLogin?.userProfile.photoUrl,
    userLoginId: rep.userLoginId,
    firstName: rep?.userLogin?.userProfile?.firstName,
    lastName: rep?.userLogin?.userProfile?.lastName,
    role: rep.role,
    isPartyOwner: rep.partyOwner,
    job: rep.function,
  }));
  const partyReps = [...acceptedReps, ...pendingReps];

  const handleRemoveParty = (party) => {
    Swal.fire({
      ...alertDefaults,
      title: 'Remove Party',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: 'Remove Party',
      cancelButtonColor: 'transparent',
      html: `<p>Are you sure you want to remove ${party.partyName}?</p>`,
    }).then(async (result) => {
      if (!result?.isConfirmed) return;

      await removePartyFromCase({ casePartyId: party.id });
      await getCaseDetails();
    });
  };

  const leaveCase = async () => {
    await leaveCase({
      partyRepId: caseDetails.myParty.partyRepId,
    });
    navigate('/');
  };

  const handleLeaveCase = () => {
    const myParty = caseDetails?.caseParties.filter((party) => party.id === caseDetails.myParty.partyId);
    const totalAdmins = myParty[0].partyReps.filter((rep) => rep.role === 'admin').length;

    leaveCase();
    if (totalAdmins <= 1) toggleLeaveCase();
  };

  const handleAddPartyToCase = (party) => {
    setSelectedParty({ partyName: party.partyName, partyId: party.id });
    if (caseDetails?.myParty?.partyId === party.id) {
      setInitialComponent(componentViews.INVITE_FROM_MY_ACCOUNT_TO_CASE);
    } else {
      setInitialComponent(componentViews.INVITE_OTHER_PARTY_TO_CASE);
    }

    toggleInviteUserToCase();
  };

  const handleRemovePartyRep = () => {
    if (myPartyReps?.partyReps.length > 1) {
      toggleRemoveParty();
    }
  };

  const renderAddRep = () => {
    if (party.partyReps.length > 0 && party.id !== caseDetails.myParty?.partyId) return null;

    return (
      <div className="action-link ms-3 mb-3 mt-3 pointer" onClick={() => handleAddPartyToCase(party)}>
        <img src={plusIcon} className="plus-icon" /> Add rep to party
      </div>
    );
  };

  return (
    <div className="panel mb-4" key={party.id}>
      <div className="panel__row pb-2">
        <div className="panel__title plaintiff mb-2 ms-3">{party.partyName}</div>
        <div className="text-end me-3 party-action">
          {(() => {
            if (caseDetails?.myParty?.partyId === party.id || party.partyReps.length === 0) {
              return (
                <DropMenu
                  parameter={party}
                  listItems={[
                    {
                      key: 0,
                      title: 'Add Rep to Party',
                      action: handleAddPartyToCase,
                    },
                    ...(caseDetails?.myParty?.partyId !== party.id && !party.partyReps.length
                      ? [
                          {
                            key: 1,
                            title: 'Remove Party',
                            action: handleRemoveParty,
                          },
                        ]
                      : []),
                    ...(caseDetails?.myParty?.partyId === party.id && myPartyReps?.partyReps.length > 1
                      ? [
                          {
                            key: 2,
                            title: 'Leave Case',
                            action: handleLeaveCase,
                          },
                        ]
                      : []),
                    ...(caseDetails?.myParty?.role === 'admin' &&
                    caseDetails?.myParty?.partyId === party.id &&
                    myPartyReps?.partyReps.length > 1
                      ? [
                          {
                            key: 3,
                            title: 'Remove Party Rep',
                            action: handleRemovePartyRep,
                          },
                        ]
                      : []),
                  ]}
                />
              );
            }
          })()}
        </div>
      </div>
      {partyReps.map((partyRep, idx) => (
        <div className="panel__row user d-flex justify-content-start align-items-center" key={partyRep.id}>
          <Rep
            key={partyRep.id}
            isLoading={!caseDetails}
            avatarStyles={avatarBackgroundStyle(idx, partyRep.userLoginId === userProfile.id)}
            firstName={partyRep.firstName}
            lastName={partyRep.lastName}
            isPartyOwner={partyRep.isPartyOwner}
            isPending={partyRep.isPending}
            job={partyRep.job}
            photoUrl={partyRep.photoUrl}
            role={partyRep.role}
          />
        </div>
      ))}

      {renderAddRep()}
    </div>
  );
};

export default memo(Panel);
