import { CancelOrAcceptCluster } from '../../../../components/cancelOrAcceptCluster';
import Row from 'react-bootstrap/Row';

export const MergeAccountModalView = ({ toggleModalVisibility, isLoading, onSubmit }) => (
  <div className="merge-account-message">
    <p>
      {'This user already has an account on LitX.'}
      <br />
      <br />
      {
        ' By continuing, you are inviting this person/orginization to join your account and you will take on all of their created cases and added account users.'
      }
      {
        ' This action will increase your monthly subscription cost by the amount of active court cases and users this person/orginization has.'
      }
      <br />
      <br />
      {" If you understand and wish to continue, please click 'Merge Accounts' below."}
    </p>
    <Row className="modal-buttons__container">
      <CancelOrAcceptCluster
        onCancelClick={toggleModalVisibility}
        isLoading={isLoading}
        onAcceptClick={onSubmit}
        acceptLabel={'Merge Accounts'}
      />
    </Row>
  </div>
);
