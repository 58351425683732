import './index.scss';

import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { inputHandler, parseCompany } from '../../utils/handlers';
import { useNavigate } from 'react-router-dom';
import { fireErrorToast, fireSuccessToast } from '../../utils/alert';
import { UserAgreementModal } from '../../components/modals/modal_content/userAgreement/modal';
import { isPasswordValid } from '../../utils/string';
import { registerUser, getCaseInvitation, getAccountInvitation } from '../../utils/api';
import { JoinLitxButton } from './joinLitxButton';
import { UserAgreementCheckBox } from './userAgreementCheckBox';
import { useQueryParams } from '../../hooks/useQueryParams';
import { EmailField } from '../../components/emailField';
import { PhoneField } from '../../components/phoneField';
import { phoneDigits } from '../../utils/constants';

const InvitedRegistrationForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAgreementSigned, setIsAgreementSigned] = useState(false);
  const { pendingCaseRequestId, pendingAccountRequestId } = useQueryParams();
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    companyName: '',
    password: '',
    phone: '',
  });
  const [showUserAgreementModal, setShowUserAgreementModal] = useState(false);

  const toggleUserAgreementModal = () => setShowUserAgreementModal(!showUserAgreementModal);

  const isJoinLitxButtonDisabled = isLoading || !userData.password || !isAgreementSigned;

  const validatePassword = () => {
    const isValid = isPasswordValid(userData.password);
    if (isValid) return;

    fireErrorToast(
      'Password does not meet minimum requirements: 1 lowercase letter, 1 upper case letter, 1 number, 1 special character, at least 8 characters long.'
    );

    throw Error('Invalid password');
  };

  const handleSubmit = async () => {
    if (userData.phone && userData.phone.length !== phoneDigits)
      return fireErrorToast('Please enter a valid phone number');

    validatePassword();
    setIsLoading(true);

    const finalUserData = parseCompany(userData);

    try {
      await registerUser(finalUserData);
      const verificationMethod = userData.phone ? 'mobile phone' : 'email';
      fireSuccessToast(`Please check your ${verificationMethod} for your verification code`);

      const verifyPage = '/verify';
      if (!pendingCaseRequestId) navigate(verifyPage);
      else navigate(`${verifyPage}?pendingCaseRequestId=${pendingCaseRequestId}`);
    } catch (err) {
      if (err.message.includes('This email address is already in use')) {
        fireErrorToast('Invitation already accepted');
      } else {
        fireErrorToast('There seems to be something wrong with the registration. Try again later or contact support');
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchInvite = async () => {
      if (!pendingCaseRequestId && !pendingAccountRequestId) navigate('/register');

      setIsLoading(true);
      try {
        const invitation = pendingCaseRequestId
          ? await getCaseInvitation({ pendingCaseRequestId })
          : await getAccountInvitation({ pendingAccountRequestId });
        setUserData({ ...userData, ...invitation });
      } catch (err) {
        fireErrorToast(`There was a problem fetching your invitation. Please try again or contact support`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInvite();
  }, [pendingCaseRequestId, navigate]);

  return (
    <Row>
      <Col xs={12} md={{ span: 5, offset: 3 }}>
        <div className="invited-registration-form">
          <div className="invited-registration-form__title">Join LitX</div>
          <div className="invited-registration-form__subtitle mb-3">Create a password to get started.</div>
          <Form>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formFirstName">
                <Form.Label className="required-input">First Name</Form.Label>
                <Form.Control disabled type="text" name="firstName" value={userData.firstName} />
              </Form.Group>

              <Form.Group as={Col} controlId="formLastName">
                <Form.Label className="required-input">Last Name</Form.Label>
                <Form.Control disabled type="text" name="lastName" value={userData.lastName} />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <EmailField value={userData.emailAddress} isDisabled label="Email Address" />

              <Form.Group as={Col} className="" controlId="formCompanyName">
                <Form.Label className="required-input">Company Name</Form.Label>
                <Form.Control disabled type="text" name="company" value={userData.companyName} />
              </Form.Group>
            </Row>

            <Row className="mb-4">
              <PhoneField
                value={userData.phone}
                label="SMS Verification Number (Optional)"
                onChange={(phone) => setUserData({ ...userData, phone })}
              />

              <Form.Group as={Col} xs={12} md={6} controlId="formCreatePassword">
                <Form.Label>Create password</Form.Label>
                <Form.Control type="password" name="password" onChange={(event) => inputHandler(event, setUserData)} />
              </Form.Group>
            </Row>

            <UserAgreementCheckBox
              onChange={() => setIsAgreementSigned(!isAgreementSigned)}
              toggleUserAgreement={toggleUserAgreementModal}
              checked={isAgreementSigned}
            />

            <Row className="centered-button-row">
              <JoinLitxButton onClick={handleSubmit} isDisabled={isJoinLitxButtonDisabled} />
            </Row>
          </Form>
        </div>
      </Col>

      <UserAgreementModal isVisible={showUserAgreementModal} toggleModalVisibility={toggleUserAgreementModal} />
    </Row>
  );
};

export default InvitedRegistrationForm;
