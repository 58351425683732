import './index.scss';
import React from 'react';
import welcomeImage from '../assets/welcome_image.png';
import { Logo } from '../components/logo';

export const EnterpriseLayout = ({ children }) => (
  <div className="enterprise-layout__container">
    <div className="enterprise-layout__left-panel">
      <div className="enterprise-layout__left-panel-container">
        <Logo />
        {children}
      </div>
    </div>
    <div className="enterprise-layout__right-panel">
      <h1 className="enterprise-layout__right-panel__header">The Modern Litigation Workspace.</h1>
      <img src={welcomeImage} alt="Welcome" className="enterprise-layout__img" />
      <div className="enterprise-layout__footnote">© 2023 Litigation Exchange, LLC </div>
    </div>
  </div>
);
