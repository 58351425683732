import 'react-dropzone-uploader/dist/styles.css';
import './index.scss';

import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import { useQueryParams } from '../../hooks/useQueryParams';
import NavigationModal from '../../components/modals/navigation_modal';
import { AuthContextConsumer } from '../../contexts/authContext';
import { FilesProvider } from '../../contexts/filesContext';
import FilesSection from '../../components/filesSection';
import { PartyPanels } from './partyPanels';
import { useFetchCaseDetails } from '../../hooks/useFetchCaseDetails';
import { CaseDetailsInfo } from './caseDetailsInfo';
import { CaseClosedBanner } from './caseClosedBanner';
import {
  InviteUserToCaseModal,
  CaseInviteSentModal,
  RemovePartyRepModal,
  AddNewPartyModal,
  EditCaseModal,
  CloseCaseModal,
} from '../../components/modals';
import Loading from '../Loading';
import { CasesContextConsumer } from '../../contexts/casesContext';
import { caseType, partyRepRoles } from '../../utils/enums';
import { LeaveCaseModal } from '../../components/modals/modal_content/leaveCase/modal';

const CaseDetailLayout = () => {
  const { courtCaseId } = useQueryParams();

  const { userProfile, accountUsers } = AuthContextConsumer();
  const { getPendingRequests } = CasesContextConsumer();

  const { data: caseDetails, refetch: getCaseDetails } = useFetchCaseDetails(courtCaseId);

  const isAdmin = caseDetails?.myParty?.role === partyRepRoles.ADMIN;

  const [initialComponent, setInitialComponent] = useState('InitialSelect');
  const [caseParties, setCaseParties] = useState({
    plaintiff: [],
    defendant: [],
    neutral: undefined,
  });
  const [selectedParty, setSelectedParty] = useState({ partyId: '', partyName: '' });
  const [caseAdmins, setCaseAdmins] = useState([]);
  const [partyRepCount, setPartyRepCount] = useState(0);

  const [displayInviteUserToCase, setDisplayInviteUserToCase] = useState(false);
  const toggleInviteUserToCase = () => setDisplayInviteUserToCase((prevValue) => !prevValue);

  const [displayCaseInviteSent, setCaseInviteSent] = useState(false);
  const toggleCaseInviteSent = () => setCaseInviteSent((prevValue) => !prevValue);

  const [displayAddNewParty, setDisplayAddNewParty] = useState(false);
  const toggleAddNewParty = () =>
    setDisplayAddNewParty((prevValue) => {
      if (isAdmin) return !prevValue;
    });

  const [displayRemovePartyRep, setDisplayRemovePartyRep] = useState(false);
  const toggleRemovePartyRep = () => setDisplayRemovePartyRep((prevValue) => !prevValue);

  const [displayLeaveCase, setDisplayLeaveCase] = useState(false);
  const toggleLeaveCase = () => setDisplayLeaveCase((prevValue) => !prevValue);

  const [displayEditCase, setDisplayEditCase] = useState(false);
  const toggleEditCase = () =>
    setDisplayEditCase((prevValue) => {
      if (isAdmin) return !prevValue;
    });

  const [displayCloseCase, setDisplayCloseCase] = useState(false);
  const toggleCloseCase = () => setDisplayCloseCase((prevValue) => !prevValue);

  const [displayNavigationSideBar, setDisplayNavigationSideBar] = useState(false);
  const toggleNavigationSideBar = () => setDisplayNavigationSideBar((prevValue) => !prevValue);

  useEffect(() => {
    if (!caseDetails) return;

    caseDetails?.caseParties.forEach((party) => {
      const reps = party.partyReps.filter(
        (rep) => rep.role === partyRepRoles.ADMIN && rep.userLoginId !== userProfile.id
      );
      if (!reps?.length) return;

      const admins = reps.map((rep) => `${rep.userLogin.userProfile.firstName} ${rep.userLogin.userProfile.lastName}`);
      setCaseAdmins(admins);
    });
  }, [caseDetails, userProfile.id]);

  useEffect(() => {
    if (!caseDetails) return;
    const parties = caseDetails.caseParties || [];
    const pendingReps = caseDetails.pendingReps || [];

    const plaintiffParties = [];
    const defendantParties = [];
    let neutralParty = undefined;

    for (const party of parties) {
      const partyWithPendingReps = { ...party };
      partyWithPendingReps.pendingReps = pendingReps.filter((rep) => rep.partyId === party.id);

      if (party.type === caseType.plaintiff) plaintiffParties.push(partyWithPendingReps);
      if (party.type === caseType.defendant) defendantParties.push(partyWithPendingReps);
      if (party.type === caseType.neutral) neutralParty = partyWithPendingReps;
    }

    const partyRepCount = parties.reduce((acc, party) => {
      return acc + (party?.partyReps?.length || 0);
    }, 0);

    setPartyRepCount(partyRepCount);
    setCaseParties({ plaintiff: plaintiffParties, defendant: defendantParties, neutral: neutralParty });
  }, [caseDetails]);

  useEffect(() => {
    (async () => getPendingRequests())();
  }, [caseDetails]);

  const handleInviteToCase = () => {
    setInitialComponent('InitialSelect');
    toggleInviteUserToCase();
  };

  const renderModals = () => {
    return (
      <>
        <InviteUserToCaseModal
          isVisible={displayInviteUserToCase}
          toggleModalVisibility={toggleInviteUserToCase}
          caseDetails={caseDetails}
          caseParties={caseParties}
          initialComponent={initialComponent}
          selectedParty={selectedParty}
          userProfile={userProfile}
          accountUsers={accountUsers}
          getCaseDetails={getCaseDetails}
        />
        <CaseInviteSentModal isVisible={displayCaseInviteSent} toggleModalVisibility={toggleCaseInviteSent} />
        <AddNewPartyModal
          isVisible={displayAddNewParty}
          toggleModalVisibility={toggleAddNewParty}
          caseDetails={caseDetails}
          getCaseDetails={getCaseDetails}
        />
        <RemovePartyRepModal
          isVisible={displayRemovePartyRep}
          toggleModalVisibility={toggleRemovePartyRep}
          caseDetails={caseDetails}
          getCaseDetails={getCaseDetails}
          userProfile={userProfile}
        />
        <LeaveCaseModal
          isVisible={displayLeaveCase}
          toggleModalVisibility={toggleLeaveCase}
          caseDetails={caseDetails}
        />
        <EditCaseModal
          isVisible={displayEditCase}
          toggleModalVisibility={toggleEditCase}
          caseDetails={caseDetails}
          getCaseDetails={getCaseDetails}
        />
        <CloseCaseModal
          isVisible={displayCloseCase}
          toggleModalVisibility={toggleCloseCase}
          caseAdmins={caseAdmins}
          caseDetails={caseDetails}
        />
        <NavigationModal isVisible={displayNavigationSideBar} toggleVisibility={toggleNavigationSideBar} />
      </>
    );
  };

  if (!caseDetails) return <Loading />;

  return (
    <>
      <CaseClosedBanner isActive={caseDetails.active} />

      <div className="case-detail pb-4">
        <Row className="case-detail__details pb-4">
          <CaseDetailsInfo
            caseDetails={caseDetails}
            isAdmin={isAdmin}
            toggleAddNewParty={toggleAddNewParty}
            toggleCloseCase={toggleCloseCase}
            toggleEditCase={toggleEditCase}
            toggleLeaveCase={partyRepCount === 1 && isAdmin ? toggleCloseCase : toggleLeaveCase}
            handleInviteToCaseClick={handleInviteToCase}
          />

          <PartyPanels
            caseParties={caseParties}
            caseDetails={caseDetails}
            getCaseDetails={getCaseDetails}
            setInitialComponent={setInitialComponent}
            setSelectedParty={setSelectedParty}
            toggleInviteUserToCase={toggleInviteUserToCase}
            toggleRemoveParty={toggleRemovePartyRep}
            toggleLeaveCase={toggleLeaveCase}
            userProfile={userProfile}
            accountUsers={accountUsers}
          />
        </Row>

        <FilesProvider caseDetails={caseDetails}>
          <FilesSection />
        </FilesProvider>

        {renderModals()}
      </div>
    </>
  );
};

export default CaseDetailLayout;
