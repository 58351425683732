import React from 'react';
import BaseModal from '../../../../components/modals/base_modal';
import { AddUserToAccount } from './addUserToAccount';

export const AddUserToAccountModal = ({ isVisible, toggleModalVisibility }) => {
  return (
    <BaseModal
      isVisible={isVisible}
      toggleVisibility={toggleModalVisibility}
      modalContent={<AddUserToAccount toggleModalVisibility={toggleModalVisibility} />}
      headerCopy="Add user to Account"
      wide={true}
    />
  );
};
