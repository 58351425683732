import Row from 'react-bootstrap/Row';
import { Button } from '../button';

export const CancelOrAcceptCluster = ({
  onCancelClick,
  isLoading,
  onAcceptClick,
  isDisabled,
  acceptLabel,
  cancelLabel = 'Cancel',
}) => (
  <Row className="modal-buttons__container mt-4">
    <Button color="light" onClick={onCancelClick} label={cancelLabel} />
    <Button color="primary" onClick={onAcceptClick} label={acceptLabel} loading={isLoading} isDisabled={isDisabled} />
  </Row>
);
