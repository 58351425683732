import React from 'react';
import BaseModal from '../../base_modal';
import { MergeAccounts } from './mergeAccounts';

export const MergeAccountsModal = ({ isVisible, toggleModalVisibility, inviteId }) => (
  <BaseModal
    isVisible={isVisible}
    toggleVisibility={toggleModalVisibility}
    modalContent={<MergeAccounts toggleModalVisibility={toggleModalVisibility} inviteId={inviteId} />}
    headerCopy="Merge Accounts"
    wide={true}
  />
);
