import React from 'react';
import BaseModal from '../../../../components/modals/base_modal';
import { CancelPlan } from './cancelPlan';

export const CancelPlanModal = ({ isVisible, toggleModalVisibility }) => {
  return (
    <BaseModal
      isVisible={isVisible}
      toggleVisibility={toggleModalVisibility}
      modalContent={<CancelPlan toggleModalVisibility={toggleModalVisibility} />}
      headerCopy="Cancel plan"
      wide={false}
    />
  );
};
