import './index.scss';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CancelOrAcceptCluster } from '../../../cancelOrAcceptCluster';
import { mergeAccount } from '../../../../utils/api';
import { CasesContextConsumer } from '../../../../contexts/casesContext';
import { fireErrorToast, fireSuccessToast } from '../../../../utils/alert';
import { AuthContextConsumer } from '../../../../contexts/authContext';

export const MergeAccounts = ({ toggleModalVisibility, inviteId }) => {
  const { getUserProfile } = AuthContextConsumer();
  const { getPendingRequests } = CasesContextConsumer();
  const [isLoading, setIsLoading] = React.useState(false);

  const submitMergeAccounts = async () => {
    setIsLoading(true);
    try {
      await mergeAccount({ inviteId });
      await Promise.all([getUserProfile(), getPendingRequests()]);
      fireSuccessToast('Accounts merged successfully');
      toggleModalVisibility();
    } catch (error) {
      fireErrorToast();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <div className="invite-to-case-form standard-modal black">
          <p>You are about to merge accounts, this action cannot be undone. Are you sure you want to merge accounts?</p>
          <br />
          <ul>
            <li>Any case or user subscriptions will be transfered to the new account user</li>
            <li>All of your court cases will stay with you</li>
            <li>The users on your account will be transfered to the new account</li>
          </ul>
          <br />
          <CancelOrAcceptCluster
            onCancelClick={toggleModalVisibility}
            isLoading={isLoading}
            onAcceptClick={submitMergeAccounts}
            acceptLabel={'Merge Accounts'}
          />
        </div>
      </Col>
    </Row>
  );
};
