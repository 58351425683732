import './index.scss';

export const Tooltip = ({ showTooltip, textContent }) => (
  <div className="tooltip-container">
    {showTooltip && (
      <>
        <div className="tooltip">{textContent}</div>
        <div className="triangle"></div>
        <div className="triangle-before"></div>
      </>
    )}
  </div>
);
