import { Elements } from '@stripe/react-stripe-js';
import { UpdatePayment } from './updatePayment';
import { stripeKey } from '../../../../utils/env';
import { loadStripe } from '@stripe/stripe-js';
import BaseModal from '../../../../components/modals/base_modal';

const stripePromise = loadStripe(stripeKey);

export const UpdatePaymentModal = ({ toggleModalVisibility, isVisible, setPaymentMethod: setBillingInfo }) => {
  return (
    <Elements stripe={stripePromise}>
      <BaseModal
        isVisible={isVisible}
        toggleVisibility={toggleModalVisibility}
        modalContent={<UpdatePayment toggleModalVisibility={toggleModalVisibility} setPaymentMethod={setBillingInfo} />}
        headerCopy="Update Payment"
        wide={true}
      />
    </Elements>
  );
};
