import { useSearchParams } from 'react-router-dom';

export const useQueryParams = () => {
  const [searchParams] = useSearchParams();

  const pendingCaseRequestId = searchParams.get('pendingCaseRequestId');
  const pendingAccountRequestId = searchParams.get('pendingAccountRequestId');
  const inviteTypeParam = searchParams.get('inviteType');
  const inviteId = searchParams.get('inviteId');
  const responseId = searchParams.get('responseId');
  const requestId = searchParams.get('requestId');
  const status = searchParams.get('status');
  const courtCaseId = searchParams.get('caseId');

  return {
    pendingCaseRequestId,
    pendingAccountRequestId,
    inviteTypeParam,
    inviteId,
    responseId,
    requestId,
    status,
    courtCaseId,
  };
};
