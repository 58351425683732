import React from 'react';
import BaseModal from '../../base_modal';
import { ProfileAddName } from './profileAddName';

export const ProfileAddNameModal = ({ isVisible, toggleModalVisibility, headerCopy }) => (
  <BaseModal
    isVisible={isVisible}
    toggleVisibility={toggleModalVisibility}
    modalContent={<ProfileAddName toggleModalVisibility={toggleModalVisibility} />}
    headerCopy={headerCopy}
    wide={false}
  />
);
