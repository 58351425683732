import React from 'react';
import BaseModal from '../../base_modal';
import LeaveCase from './leaveCase';

export const LeaveCaseModal = ({ isVisible, toggleModalVisibility, caseDetails }) => (
  <BaseModal
    isVisible={isVisible}
    toggleVisibility={toggleModalVisibility}
    modalContent={<LeaveCase toggleModalVisibility={toggleModalVisibility} caseDetails={caseDetails} />}
    headerCopy="Leave Case"
    wide={true}
  />
);
