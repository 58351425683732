import React, { useState } from 'react';
import { Button } from '../../../button';
import { PathBreadCrumbs } from '../../../breadCrumbs';
import { FilesConsumer } from '../../../../contexts/filesContext';
import { usePathManager } from '../../../../hooks/usePathManager';
import { Row } from './row';
import Loader from '../../../../assets/loader.svg';
import { fireProcessingFileErrors, fireSuccessToast } from '../../../../utils/alert';
import { sendPostRequest, routes } from '../../../../utils/api';
import { Col } from 'react-bootstrap';

export const Move = ({ toggleModalVisibility }) => {
  const {
    currentPath: startingPath,
    caseDetails,
    uploadedFiles,
    selectedPathObjs,
    getUploadedFiles: refreshFileSystem,
  } = FilesConsumer();

  const [currentPath, setCurrentPath] = useState(startingPath);
  const [isLoading, setIsLoading] = useState(false);

  const selectedFileIdsToMove = selectedPathObjs.map((pathObj) => pathObj.id);

  const allPathObjects = uploadedFiles.filter((pathObject) => !selectedFileIdsToMove.includes(pathObject.id));
  const { results: pathObjects } = usePathManager(allPathObjects, currentPath);

  const handleMoveClick = async () => {
    setIsLoading(true);

    try {
      const courtCaseId = caseDetails.id;
      const path = courtCaseId + '/' + currentPath;
      await sendPostRequest(routes.filesMove, {
        courtCaseId,
        ids: selectedFileIdsToMove,
        path,
      });

      await refreshFileSystem();
      toggleModalVisibility();
      fireSuccessToast('File(s) moved!');
    } catch (err) {
      fireProcessingFileErrors(err, 'Something went wrong moving your files. Try again or contact support');
    } finally {
      setIsLoading(false);
    }
  };

  const renderLoading = () => {
    if (!isLoading) return;

    return <img src={Loader} className="loader" alt="Loading" />;
  };

  const renderContent = () => (
    <div className={isLoading ? 'table-container-hidden' : undefined}>
      <PathBreadCrumbs
        currentPath={currentPath}
        courtCaseName={caseDetails.caseName}
        onCurrentPathChange={setCurrentPath}
      />

      <div className="table-container">
        {pathObjects.map((pathObject) => (
          <Row
            key={pathObject.id}
            pathObject={pathObject}
            onDoubleClick={(pathObj) => setCurrentPath(currentPath + pathObj.name + '/')}
          />
        ))}
      </div>

      <div className="buttons-container">
        <Button color="light" onClick={toggleModalVisibility} label="Cancel" />
        <Button color="primary" isDisabled={isLoading} onClick={handleMoveClick} label="Move" loading={isLoading} />
      </div>
    </div>
  );

  return (
    <Col className="modal-container">
      {renderLoading()}
      {renderContent()}
    </Col>
  );
};
