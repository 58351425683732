export const ProfileRow = ({ leftCol, middleCol, linkLabel, onClick }) => {
  return (
    <div className="subscription__row">
      <div className="left-col">{leftCol}</div>
      <div className="profile-middle-col">{middleCol}</div>
      <div className="right-col pointer" onClick={onClick}>
        {linkLabel}
      </div>
    </div>
  );
};
