import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { UserAgreementModal } from '../../components/modals/modal_content/userAgreement/modal';

export const UserAgreement = ({ isChecked, onChange }) => {
  const [showUserAgreementModal, setShowUserAgreementModal] = useState(false);
  const toggleUserAgreementModal = () => setShowUserAgreementModal(!showUserAgreementModal);

  return (
    <Row className="mb-3">
      <Form.Group className="mb-4" id="formUserAgreementCheckbox">
        <Form.Check type="checkbox">
          <Form.Check.Input
            type="checkbox"
            isValid
            checked={isChecked}
            name="userAgreement"
            onChange={() => onChange(!isChecked)}
          />
          <Form.Check.Label>
            <span className="agreement-text">
              I agree to the{' '}
              <span className="agreement-text-link" onClick={toggleUserAgreementModal}>
                user agreement
              </span>
            </span>
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>

      <UserAgreementModal isVisible={showUserAgreementModal} toggleModalVisibility={toggleUserAgreementModal} />
    </Row>
  );
};
